import { Stack, Link, Typography, styled } from "@mui/material";
import {
  FacebookIcon,
  InstagramIcon,
  LinkedinIcon,
  TwitterIcon,
} from "../iconsSvg/SvgIcons";
import theme from "../../theme/theme";

const LinkText = styled(Link)(({ theme }) => ({
  textDecoration: "none",
  color: theme.palette.secondary.dark,
  display: "inline-block",
  marginBottom: "0.15rem",
}));

const SocialNetworks = () => {
  return (
    <>
      <Stack
        justifyContent="center"
        alignItems="center"
        spacing={2}
        direction="row"
        sx={{ mt: "1.5rem", mb: "0.5rem" }}
      >
        <Link
          href="https://www.facebook.com/ParvalRD/"
          target="_blank"
          underline="none"
          color={theme.palette.secondary.dark}
        >
          <FacebookIcon />
        </Link>
        <Link
          href="https://www.instagram.com/parvalrd/"
          target="_blank"
          underline="none"
          color={theme.palette.secondary.dark}
        >
          <InstagramIcon />
        </Link>

        <Link
          href="https://www.linkedin.com/company/parval-puesto-de-bolsa/"
          target="_blank"
          underline="none"
          color={theme.palette.secondary.dark}
        >
          <LinkedinIcon />
        </Link>
        <Link
          href="https://twitter.com/parval_rd"
          target="_blank"
          color={theme.palette.secondary.dark}
        >
          <TwitterIcon />
        </Link>
      </Stack>
      <Stack justifyContent="center" alignItems="center">
        <Typography>
          <LinkText href="mailto:info@parval.com.do">
            info@parval.com.do
          </LinkText>
        </Typography>
        <Typography>
          <LinkText href="tel:8095600909" target="_blank">
            809-560-0909
          </LinkText>
          |
          <LinkText href="tel:8094727825" target="_blank">
            809-472-7825
          </LinkText>
        </Typography>
        <Typography color="secondary.dark" variant="body1">
          Copyright 2023. Todos los derechos reservados.
        </Typography>
      </Stack>
    </>
  );
};

export default SocialNetworks;
