import { useEffect } from "react";
import { CONSTANT } from "../utils/Constants";
import Swal from "sweetalert2";
import { properties } from "../utils/Properties_es";

interface MessageTypes {
  type: number;
  msg: string;
  details?: string;
  confirmButtonText?: string;
  cancelButtonText?: string;
  showCancelButton?: boolean;
  callback?: () => void;
}

const MessageManager = ({
  type,
  msg,
  details,
  confirmButtonText,
  showCancelButton,
  cancelButtonText,
  callback,
}: MessageTypes) => {
  useEffect(() => {
    showMessage();
    // eslint-disable-next-line
  }, []);

  const showMessage = () => {
    Swal.fire({
      title: `<span style="font-weight: 400">${msg}</span>`,
      text: details,
      icon:
        type === CONSTANT.ERROR_MSG
          ? "error"
          : type === CONSTANT.WARNING_MSG
          ? "info"
          : "success",
      confirmButtonText:
        confirmButtonText ?? properties.com_parval_label_button_ok_title,
      confirmButtonColor: "#f1b80e",
      showCancelButton: showCancelButton ?? false,
      focusConfirm: true,
      cancelButtonText: cancelButtonText,
    }).then(() => callback && callback());
  };

  return <></>;
};

export default MessageManager;
