import * as Yup from "yup";
import { phoneRegExp, urlRegExp, formSchemaOptions, formSchemaOptionsName, formSchemaCountry, numberRegExp } from "../../utils";

const yupStringAutocompleteRequerid = Yup.string().required("Campo es requerido").typeError("Campo es requerido")
const yupSingleStringRequired = Yup.string().required("Campo es requerido")
const yupObjectRequired = Yup.object().shape(formSchemaOptions).typeError("Campo es requerido")

const formSchemaLegal_representative = {
  name: yupSingleStringRequired,
  id_type: yupObjectRequired,
  document_number: yupSingleStringRequired,
  country_of_nationality: yupObjectRequired,
  country: yupObjectRequired,
  province: Yup.object().nullable()
  .when("country", {
    is: (country: any) => {
      if (country?.id === "RD") {
        return true;
      }
    },
    then: yupObjectRequired,
  }),
  sector: Yup.object().nullable()
  .when("country", {
    is: (country: any) => {
      if (country?.id === "RD") {
        return true;
      }
    },
    then: Yup.object()
      .shape(formSchemaCountry)
      .typeError("Campo es requerido"),
  }),
  address: yupSingleStringRequired,
  building_name: Yup.string(),
  floor_number: Yup.string(),
  postal_code: Yup.number().required("Campo es requerido").typeError("Campo es númerico"),
  email: Yup.string()
  .required("Campo es requerido")
  .email("Email es invalido"),
};

export const validationBussinessIndetification = Yup.object().shape({
  investment_promoter: yupObjectRequired,
  name_social_denomination: yupSingleStringRequired,
  company_email: yupSingleStringRequired.email("Email es invalido"),
  rnc_document_number: yupSingleStringRequired,
  business_registration_number: yupSingleStringRequired,
  relationship_with_parval: yupSingleStringRequired,
  incorporation_date: Yup.date().typeError("Campo es requerido"),
  country_of_incorporation: yupObjectRequired,
  company_type:yupObjectRequired,
  website: Yup.string().matches(urlRegExp, {message: "Url es invalida", excludeEmptyString:true}),
  channel_catchment_client: yupObjectRequired,
  personal_phone: yupSingleStringRequired.matches(phoneRegExp, {message: "Teléfono no es valido",excludeEmptyString:true}),
  main_clients_and_countries: yupObjectRequired,
  country: yupObjectRequired,
  province: Yup.object().nullable()
  .when("country", {
    is: (country: any) => {
      if (country?.id === "63") {
        return true;
      }
    },
    then: yupObjectRequired,
  }),
  sector: Yup.object().nullable()
  .when("country", {
    is: (country: any) => {
      if (country?.id === "63") {
        return true;
      }
    },
    then: Yup.object()
      .shape(formSchemaCountry)
      .typeError("Campo es requerido"),
  }),
  street_contact_person: yupSingleStringRequired,
  building: Yup.string(),
  legal_representative:Yup.array().of(Yup.object().shape(formSchemaLegal_representative)),
  sector_type: yupStringAutocompleteRequerid,
  business_activity: yupSingleStringRequired,
  quantity_of_employees: yupSingleStringRequired.matches(numberRegExp, "Valor no es valido, solo se permiten números"),
  number_of_shareholders: yupSingleStringRequired.matches(numberRegExp, "Valor no es valido, solo se permiten números"),
  annual_sales_volume: yupSingleStringRequired,
  products_or_services: yupSingleStringRequired,
});

const formSchemaMain_clients_entity = {
  social_name: yupSingleStringRequired,
  country_of_origin: yupObjectRequired,
};

const formSchemaInformation_shareholders = {
  full_name: yupSingleStringRequired,
  identification_number: yupSingleStringRequired
};

const formSchemaAffiliated_companies = {
  name_or_social_name: yupSingleStringRequired,
  rnc_or_equivalent: yupSingleStringRequired
};
const formSchemaCommercial_references = {
  commercial_phone: yupSingleStringRequired.matches(phoneRegExp, {message: "Teléfono no es valido",excludeEmptyString:true}),
  commercial_name: yupSingleStringRequired
};



const formSchemapolitically_exposed_person_members = {
  name: yupSingleStringRequired,
  identification_number: yupSingleStringRequired,
  institution_name: yupSingleStringRequired,
  position: yupSingleStringRequired,
  appointment_date: Yup.date().typeError("Campo es requerido"),
  removal_date: Yup.date().nullable().typeError("Campo es requerido"),
};

const formSchemaBank_accounts_register = {
  account_holder: yupSingleStringRequired,
  institution: Yup.object().shape(formSchemaOptionsName).typeError("Campo es requerido"),
  account_type: yupStringAutocompleteRequerid,
  account_number: yupSingleStringRequired,
  currency_type: yupObjectRequired,
};


export const validationEconomicActivityCompany = Yup.object().shape({
  annual_income: yupObjectRequired,
  level_experience_and_knowledge_as_investor: yupObjectRequired,
  total_assets: yupObjectRequired,
  total_heritage: yupObjectRequired,
  accounts_payable: yupObjectRequired,
  investment_horizon: yupObjectRequired,
  annual_expenses: yupObjectRequired,
  emergency_fund_for_expenses: yupObjectRequired,
  destination_income: yupObjectRequired,
  ability_absorb_losses: Yup.object()
    .shape({
      id: Yup.number().required("Required"),
      description: yupSingleStringRequired,
    })
    .typeError("Campo es requerido"),
  risk_tolerance: yupObjectRequired,
  investment_goals: yupObjectRequired,
  desired_profitability: yupObjectRequired,
  main_sources_funds: Yup.array(),
  /* main_sources_funds: Yup.string()
    .required("Campo es requerido").typeError("Campo es requerido"), */
  other_main_sources_funds: Yup.string().when("main_sources_funds", {
      is: "Otro",
      then: yupSingleStringRequired,
    }),
  operations_values_finance: Yup.array(),
  average_annual_amount: yupObjectRequired,
  financialactivities: yupObjectRequired,
  relationship_duration: yupObjectRequired,
  operation_time: yupObjectRequired,
  bank_accounts_register: Yup.array().of(Yup.object().shape(formSchemaBank_accounts_register)),
})








export const validationAdditionalLegalInformation = Yup.object().shape({
  main_suppliers_entity: Yup.array().of(Yup.object().shape(formSchemaMain_clients_entity)),
  information_shareholders: Yup.array().of(Yup.object().shape(formSchemaInformation_shareholders)),
  affiliated_companies: Yup.array().of(Yup.object().shape(formSchemaAffiliated_companies)),
  commercial_references: Yup.array().of(Yup.object().shape(formSchemaCommercial_references)),

})




const formSchemaFinanceInstruments = {
  financialInstruments: yupObjectRequired,
  experienceTime: yupObjectRequired,
  numTransactionsYear: yupObjectRequired,
  levelknowInstRisk: yupObjectRequired,
};

const formSchemaAuthorized_signatures= {
  full_name: yupSingleStringRequired,
  document_number: yupSingleStringRequired
};

const formSchemaLiquidAssetsDistribution = {
  liquid_assets_distribution_id: yupObjectRequired,
  liquid_assets_percentage: Yup.number()
    .required("Campo es requerido").min(1, 'Valor no puede ser menor a 1').typeError("Campo es requerido"),
};

const formSchema_direct_and_indirect_beneficiaries = {
  full_name: yupSingleStringRequired,
  document_type: yupObjectRequired,
  identification_number: yupSingleStringRequired,
  occupation: yupSingleStringRequired,
  phone: yupSingleStringRequired,
  address: yupSingleStringRequired
};


export const validationBeneficiariesAndMarketKnowledge = Yup.object().shape({
  company_have_a_TIN_or_EIN: yupSingleStringRequired,
  tin_or_ein: Yup.string().when("company_have_a_TIN_or_EIN", {
    is: "Sí",
    then: yupSingleStringRequired,
  }),
  company_have_a_GIN: yupSingleStringRequired,
  company_have_a_GIN_data:  Yup.string().when("company_have_a_GIN", {
    is: "Sí",
    then: yupSingleStringRequired}),
  direct_and_indirect_beneficiaries: Yup.array().of(Yup.object()
    .shape(formSchema_direct_and_indirect_beneficiaries)),
  authorized_signatures: Yup.array().of(Yup.object().shape(formSchemaAuthorized_signatures)),
  mode_of_signatures_and_reception_of_instructions: yupStringAutocompleteRequerid,
  liquid_assets_distribution_form: Yup.array().of(
    Yup.object().shape(formSchemaLiquidAssetsDistribution)
  ),
  information_media: yupObjectRequired,
  financialInstrumentsYear: Yup.array().of(
      Yup.object().shape(formSchemaFinanceInstruments)
    ),
  operations_in_national_or_foreign_stock_market: yupStringAutocompleteRequerid,
  traded_volume_greater_than_3000: Yup.string().when("operations_in_national_or_foreign_stock_market", {
    is: "Sí",
    then: yupStringAutocompleteRequerid}),
  average_transaction_frequency: yupStringAutocompleteRequerid,
  has_cash_or_investment_assets: yupStringAutocompleteRequerid,
  positions_in_management_of_financial_institution: yupStringAutocompleteRequerid,
  member_of_the_management_board: yupStringAutocompleteRequerid,
  stock_broker_certification: yupStringAutocompleteRequerid,
})


