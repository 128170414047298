import { publicFetch } from "../utils/CustomFetch";
import { URL } from "../utils/UrlConstant";
import { CONSTANT } from "../utils/Constants";
import { Buffer } from "buffer";

export const SVAPPloginRequest = async () => {
  let responseData = {};
  //console.log("entra SVAPPloginRequest");

  //auth header data
  const appClientId = process.env.REACT_APP_SECURITY_APPCLIENT_ID;
  const appClientPass = process.env.REACT_APP_SECURITY_APPCLIENT_PASS;
  let encodedAuth = Buffer.from(
    `${appClientId}:${appClientPass}`,
    "binary"
  ).toString("base64");
  //auth body data
  const requestBody = {
    username: process.env.REACT_APP_SECURITY_APPUSER_ID,
    password: process.env.REACT_APP_SECURITY_APPUSER_PASS,
    grant_type: "password",
  };

  await publicFetch
    .post(URL.APPLOGIN_REQUEST, /* qs.stringify(requestBody) */ "", {
      headers: {
        ...CONSTANT.HEADER_TYPE,
        Authorization: "Basic " + encodedAuth,
      },
    })
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch((error) => {
      throw new Error(error);
    });

  return responseData;
};
