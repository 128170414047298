import { CONSTANT } from "./Constants";

export const URL = {
  APPLOGIN_REQUEST:
    CONSTANT.DISPATCHER_SECURITY_PREFIX +
    "/oauth/token?grant_type=client_credentials",
  investmentProfile: {
    CRUD: CONSTANT.DISPATCHER_INVESTMENT_PROFILE + CONSTANT.profile,
    update:
      CONSTANT.DISPATCHER_INVESTMENT_PROFILE + CONSTANT.profile + `update`,
    calculate:
      CONSTANT.DISPATCHER_INVESTMENT_PROFILE + CONSTANT.profile + `calculate/`,
    kyc:
      CONSTANT.DISPATCHER_INVESTMENT_PROFILE +
      CONSTANT.documents +
      `formulario/`,
    perfil:
      CONSTANT.DISPATCHER_INVESTMENT_PROFILE + CONSTANT.documents + `perfil/`,
    contract:
      CONSTANT.DISPATCHER_INVESTMENT_PROFILE + CONSTANT.documents + `contract/`,
    anexoi:
      CONSTANT.DISPATCHER_INVESTMENT_PROFILE + CONSTANT.documents + `anexo1/`,
    anexoIDynamic:
      CONSTANT.DISPATCHER_INVESTMENT_PROFILE +
      CONSTANT.documents +
      `brokerage-contract/`,

    anexoii:
      CONSTANT.DISPATCHER_INVESTMENT_PROFILE + CONSTANT.documents + `anexo-2/`,
    notification: CONSTANT.DISPATCHER_INVESTMENT_PROFILE + `notification/`,
  },
  maintenance: {
    allData: CONSTANT.DISPATCHER_MAINTENANCE + `allData/`,
    provinces: CONSTANT.DISPATCHER_MAINTENANCE + `provinces/?size=100`,
    sector: CONSTANT.DISPATCHER_MAINTENANCE + `sectors/?size=600`,
    branch:
      CONSTANT.DISPATCHER_MAINTENANCE + `branchOffice/?page=0&size=40&type=J`,
    investmentPromoter:
      CONSTANT.DISPATCHER_MAINTENANCE +
      `investmentPromoter/?page=0&size=40&type=J`,
    bankEntity: CONSTANT.DISPATCHER_MAINTENANCE + `bankEntity/search/`,
  },
};
