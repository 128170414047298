import { Suspense } from "react";
import { Await, useLoaderData, useOutlet } from "react-router-dom";
import { AppProvider } from "../../context/AppContext";
import { ThemeProvider } from "@mui/material/styles";
import { ProfileProvider } from "../../context/ProfileProvider";
import { LegalFormProvider } from "../../context/LegalFormProvider";
import { properties } from "../../utils/Properties_es";
import Loading from "../Loading";
import theme from "../../theme/theme";

export const AuthLayout = () => {
  const outlet = useOutlet();

  const { authPromise }: any = useLoaderData();

  return (
    <Suspense fallback={<Loading />}>
      <Await
        resolve={authPromise}
        children={(authObject) => (
          <ThemeProvider theme={theme}>
            <AppProvider authData={authObject}>
              <ProfileProvider>
                <LegalFormProvider>{outlet}</LegalFormProvider>
              </ProfileProvider>
            </AppProvider>
          </ThemeProvider>
        )}
      />
    </Suspense>
  );
};
