import useLegalForm from "../../hooks/useLegalForm";
import useProfile from "../../hooks/useProfile";
import {
  Container,
  Stack,
  Stepper,
  Step,
  StepLabel,
  StepConnector,
  stepConnectorClasses,
  StepIconProps,
  MobileStepper,
  Box,
  Button,
  styled,
  useTheme,
  useMediaQuery,
  Tooltip,
} from "@mui/material";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import CreditScoreIcon from "@mui/icons-material/CreditScore";
import FeedIcon from "@mui/icons-material/Feed";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import SensorOccupiedIcon from "@mui/icons-material/SensorOccupied";
import Headroom from "react-headroom";

import BussinessIdentification from "./BussinessIdentification";
import EconomicActivity from "./EconomicActivity";
import AdditionalLegalInformation from "./AdditionalLegalInformation";
import BeneficiariesAndMarketKnowledge from "./BeneficiariesAndMarketKnowledge";
import InvestorTypeResults from "./InvestorTypeResults";
import { StepsType } from "../../types/types";
import MessageManager from "../../components/MessageManager";
import { CONSTANT } from "../../utils/Constants";
import { useApp } from "../../hooks/useApp";

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: `${theme.palette.secondary.dark}`,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: `${theme.palette.secondary.dark}`,
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled("div")<{
  ownerState: { completed?: boolean; active?: boolean };
}>(({ theme, ownerState }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? theme.palette.grey[700] : "#ccc",
  zIndex: 1,
  color: "#fff",
  width: 50,
  height: 50,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  cursor: "inherit",
  ...(ownerState.active && {
    backgroundColor: `${theme.palette.secondary.dark}`,
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.35)",
  }),
  ...(ownerState.completed && {
    backgroundColor: `${theme.palette.secondary.dark}`,
  }),
}));

const WrappMobileStepper = styled(MobileStepper)(({ theme }) => ({
  backgroundColor: `${theme.palette.secondary.dark}`,
  color: "#fff",
  fontFamily: "Mulish",
}));

function ColorlibStepIcon(props: StepIconProps) {
  const { active, completed, className } = props;

  const icons: { [index: string]: React.ReactElement } = {
    1: <AssignmentIndIcon sx={{ fontSize: 30 }} />,
    2: <CreditScoreIcon sx={{ fontSize: 30 }} />,
    3: <FeedIcon sx={{ fontSize: 30 }} />,
    4: <PeopleAltIcon sx={{ fontSize: 30 }} />,
    5: <SensorOccupiedIcon sx={{ fontSize: 30 }} />,
  };

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
    >
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

const steps: StepsType[] = [
  {
    label: "Conozca a su Cliente",
    component: <BussinessIdentification />,
  },
  {
    label: "Información Adicional",
    component: <AdditionalLegalInformation />,
  },
  {
    label: "Perfil del Inversionista",
    component: <EconomicActivity />,
  },
  {
    label: "Beneficiarios y Conocimiento del Mercado",
    component: <BeneficiariesAndMarketKnowledge />,
  },

  {
    label: "Resultados del Inversionista",
    component: <InvestorTypeResults />,
  },
];

const LegalForm = () => {
  const { activeStep, setActiveStep } = useLegalForm();
  const { steps_completed } = useProfile();
  const theme = useTheme();
  const maxSteps = steps.length;
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const {
    setLoading,
    setErrorMsg,
    setSuccessMsg,
    successMsg,
    isLoading,
    resetErrorMsg,
    resetSuccessMsg,
    errorMsg,
  } = useApp();

  const handleBack = () => {
    setActiveStep && setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleNext = () => {
    setActiveStep && setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleStep = (step: number) => () => {
    setActiveStep && setActiveStep(step);
  };

  return (
    <Container maxWidth="xl" sx={{ pt: 5, pb: 8 }}>
      {errorMsg && (
        <MessageManager
          type={CONSTANT.ERROR_MSG}
          msg={errorMsg}
          details=""
          callback={resetErrorMsg}
        />
      )}
      {successMsg && (
        <MessageManager
          type={CONSTANT.SUCCESS_MSG}
          msg={successMsg}
          details=""
          callback={resetSuccessMsg}
        />
      )}
      <Stack sx={{ width: "100%" }} spacing={4}>
        {!isMobile ? (
          <Stepper
            alternativeLabel
            activeStep={activeStep}
            connector={<ColorlibConnector />}
          >
            {steps.map((step, i) => (
              <Step key={i}>
                {steps_completed &&
                steps_completed[i] !== true &&
                activeStep !== steps.length - 2 ? (
                  <Tooltip
                    disableFocusListener
                    disableTouchListener
                    title="Debes completar todos los pasos"
                    placement="top"
                  >
                    <StepLabel
                      StepIconComponent={ColorlibStepIcon}
                      sx={{ cursor: "default" }}
                    >
                      {step.label}
                    </StepLabel>
                  </Tooltip>
                ) : (
                  <StepLabel
                    StepIconComponent={ColorlibStepIcon}
                    onClick={handleStep(i)}
                    sx={{ cursor: "pointer" }}
                  >
                    {step.label}
                  </StepLabel>
                )}
              </Step>
            ))}
          </Stepper>
        ) : (
          <Headroom downTolerance={10} pinStart={80}>
            <WrappMobileStepper
              variant="text"
              steps={maxSteps}
              position="static"
              activeStep={activeStep}
              nextButton={
                <Button
                  size="small"
                  onClick={handleNext}
                  disabled={
                    activeStep >= maxSteps - 1 ||
                    (steps_completed &&
                      steps_completed[activeStep + 1] !== true &&
                      activeStep !== steps.length - 2)
                  }
                  sx={{ color: "#fff" }}
                >
                  Continuar
                  {theme.direction === "rtl" ? (
                    <KeyboardArrowLeft />
                  ) : (
                    <KeyboardArrowRight />
                  )}
                </Button>
              }
              backButton={
                <Button
                  size="small"
                  onClick={handleBack}
                  disabled={activeStep === 0}
                  sx={{ color: "#fff" }}
                >
                  {theme.direction === "rtl" ? (
                    <KeyboardArrowRight />
                  ) : (
                    <KeyboardArrowLeft />
                  )}
                  Regresar
                </Button>
              }
            />
          </Headroom>
        )}

        <>
          {steps[activeStep].component}

          {!isMobile && (
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
              <Button
                color="inherit"
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={{ mr: 1 }}
              >
                Regresar
              </Button>
              <Box sx={{ flex: "1 1 auto" }} />
              <Button
                onClick={handleNext}
                disabled={activeStep === steps.length - 1}
              >
                {activeStep === steps.length - 1 ? "Finalizar" : "Continuar"}
              </Button>
            </Box>
          )}
        </>
      </Stack>
    </Container>
  );
};

export default LegalForm;
