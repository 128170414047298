import { FC, useEffect, useState } from "react";
import useLegalForm from "../../hooks/useLegalForm";
import useProfile from "../../hooks/useProfile";
import {
  Grid,
  TextField,
  Autocomplete,
  CircularProgress,
  Stack,
  Divider,
  IconButton,
  Button,
  Tooltip,
  FormControl,
  Select,
  MenuItem,
  Box,
  Checkbox,
  Chip,
  ListItemText,
  InputLabel,
  styled,
  useTheme,
} from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";

import TitleForm from "../../components/TitleForm";
import ButtonGradient from "../../components/ButtonGradient";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { validationEconomicActivityCompany } from "./validation";
import { EconomicActivityCompanyType } from "../../types/types";
import { data as datos } from "../../data/data";
import { updateProfile } from "../../services/Services";

const LabelForm = styled(InputLabel)(() => ({
  left: "-14px",
}));

const EconomicActivity: FC = () => {
  const theme = useTheme();
  const [loadingSendData, setLoadingSendData] = useState(false);
  const { dataForm, completeData, dataJ } = useProfile();
  let localData3 = localStorage.getItem("stepThree");
  const {
    setActiveStep,
    step3,
    setStep3,
    handleBack,
    handleNext,
    step3Answered,
  } = useLegalForm();

  let findData: any;
  if (dataForm !== null) {
    findData = dataForm;
  } else if (typeof localData3 === "string") {
    findData = JSON.parse(localData3);
  } else {
    findData = step3;
  }

  const defaultValues: EconomicActivityCompanyType = {
    annual_income: findData.annual_income
      ? dataJ.pa_ta_annual_income.find(
          (f: any) => f.id === Number(findData.annual_income)
        )
      : [],
    level_experience_and_knowledge_as_investor:
      findData.level_experience_and_knowledge_as_investor
        ? dataJ.pa_ta_knowledge_level.find(
            (f: any) =>
              f.id ===
              Number(findData.level_experience_and_knowledge_as_investor)
          )
        : [],
    total_assets: findData.total_assets
      ? dataJ.pa_ta_total_liquid_assets.find(
          (f: any) => f.id === Number(findData.total_assets)
        )
      : [],
    total_heritage: findData.total_heritage
      ? dataJ.pa_ta_total_heritage.find(
          (f: any) => f.id === Number(findData.total_heritage)
        )
      : [],
    accounts_payable: findData.accounts_payable
      ? dataJ.pa_ta_accounts_payable.find(
          (f: any) => f.id === Number(findData.accounts_payable)
        )
      : [],
    investment_horizon: findData.investment_horizon
      ? dataJ.pa_ta_investment_time_horizon.find(
          (f: any) => f.id === Number(findData.investment_horizon)
        )
      : [],
    annual_expenses: findData.annual_expenses
      ? dataJ.pa_ta_annual_expenses.find(
          (f: any) => f.id === Number(findData.annual_expenses)
        )
      : [],
    emergency_fund_for_expenses: findData.emergency_fund_for_expenses
      ? dataJ?.pa_ta_emergency_fund?.find(
          (f: any) => f.id === Number(findData.emergency_fund_for_expenses)
        )
      : [],
    destination_income: findData.destination_income
      ? dataJ.pa_ta_destination_income.find(
          (f: any) => f.id === Number(findData.destination_income)
        )
      : [],
    ability_absorb_losses: findData.ability_absorb_losses
      ? dataJ &&
        dataJ.pa_ta_ability_absorb_losses.find(
          (f: any) => f.id === Number(findData.ability_absorb_losses)
        )
      : [],
    risk_tolerance: findData.risk_tolerance
      ? dataJ &&
        dataJ.pa_ta_risk_tolerance.find(
          (f: any) => f.id === Number(findData.risk_tolerance)
        )
      : [],
    investment_goals: findData.investment_goals
      ? dataJ &&
        dataJ.pa_ta_investment_goals.find(
          (f: any) => f.id === Number(findData.investment_goals)
        )
      : [],
    desired_profitability: findData.desired_profitability
      ? dataJ &&
        dataJ.pa_ta_desired_profitability.find(
          (f: any) => f.id === Number(findData.desired_profitability)
        )
      : [],
    main_sources_funds: findData.main_sources_funds
      ? JSON.parse(findData.main_sources_funds)
      : [],
    /*  main_sources_funds: findData.main_sources_funds
      ? findData.main_sources_funds
      : "", */
    other_main_sources_funds: findData.other_main_sources_funds
      ? findData.other_main_sources_funds
      : "",
    operations_values_finance: findData.operations_values_finance
      ? JSON.parse(findData.operations_values_finance)
      : [],
    average_annual_amount: findData.average_annual_amount
      ? dataJ.pa_ta_initial_investment_amount.find(
          (f: any) => f.id === Number(findData.average_annual_amount)
        )
      : [],
    financialactivities: findData.financialactivities
      ? dataJ.pa_ta_economic_activity.find(
          (f: any) => f.id === Number(findData.financialactivities)
        )
      : [],
    relationship_duration: findData.relationship_duration
      ? dataJ?.pa_ta_relationship_duration?.find(
          (f: any) => f.id === Number(findData.relationship_duration)
        )
      : [],
    operation_time: findData.operation_time
      ? dataJ?.pa_ta_operation_time?.find(
          (f: any) => f.id === Number(findData.operation_time)
        )
      : [],
    bank_accounts_register: (findData.bank_accounts_register &&
      JSON.parse(findData.bank_accounts_register)) ?? [
      {
        account_holder: "",
        institution: [],
        account_type: "",
        account_number: "",
        currency_type: [],
      },
    ],
  };

  const {
    register,
    handleSubmit,
    reset,
    resetField,
    formState,
    control,
    watch,
    getValues,
  } = useForm<EconomicActivityCompanyType>({
    defaultValues,
    resolver: yupResolver(validationEconomicActivityCompany),
  });
  const { errors, isDirty, dirtyFields } = formState;
  const watch_main_sources_funds: any = watch("main_sources_funds");
  const miarray: any = [];

  const {
    fields: bank_accounts_registerFields,
    append: bank_accounts_registerAppend,
    remove: bank_accounts_registerRemove,
  } = useFieldArray({
    control,
    name: "bank_accounts_register",
    rules: {
      minLength: 1,
    },
  });

  useEffect(() => {
    reset(defaultValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataForm]);

  const onSubmit = async (data: any) => {
    let localForm: any = localStorage.getItem("formData");
    setLoadingSendData(true);
    let key: string | any;
    let value: { id: string; description: string }[] | any = [];

    for ([key, value] of Object.entries(data)) {
      miarray.push({ [key]: value });
      if (!value) {
        delete data[key];
      } else if (typeof value == "object") {
        data[key] =
          value !== null && value.id ? value.id : JSON.stringify(value);
      }
      data.step_three_complete = "true";
    }

    let update = {};
    for ([key, value] of Object.entries(dirtyFields)) {
      value = getValues(key);
      if (typeof value == "object") {
        Object.assign(update, {
          [key]:
            value !== null && value.id
              ? typeof value.id !== "string"
                ? JSON.stringify(value.id)
                : value.id
              : JSON.stringify(value),
        });
      } else {
        Object.assign(update, { [key]: value });
      }
    }

    setStep3 && setStep3(data);
    localStorage.setItem("stepThree", JSON.stringify(data));
    setLoadingSendData(false);
    setActiveStep && setActiveStep((prevActiveStep) => prevActiveStep + 1);

    const handleResponse = () => {
      setStep3 && setStep3(data);
      setLoadingSendData(false);
      setActiveStep && setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    /* if (findData.step_two_complete !== "true") {
      let fullData = {
        fields: {
          ...data,
        },
        formType: "J",
        userId: localStorage.getItem("userId"),
        stepForm: "Perfil del inversionista",
      };
      localStorage.setItem("stepForm", "Perfil del inversionista");

      const saveData = await updateProfile(fullData);
      if (saveData.status === 200) {
        handleResponse();
      }
    } else {
      if (isDirty) {
        let fullUpData = {
          fields: {
            ...data,
          },
          formType: "J",
          userId: localStorage.getItem("userId"),
          stepForm: localStorage.getItem("stepForm"),
        };
        const updateData = await updateProfile(fullUpData);
        if (updateData.status === 200) {
          handleResponse();
        }
      } else {
        setLoadingSendData(false);
        setActiveStep && setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    } */
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <TitleForm>Información financiera</TitleForm>
      <Grid container spacing={4} sx={{ mb: 4 }}>
        <Grid item sm={6} xs={12}>
          <Controller
            control={control}
            name="annual_income"
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                onChange={(event, newValue) => {
                  onChange(newValue);
                }}
                options={dataJ && dataJ.pa_ta_annual_income}
                getOptionLabel={(option: any) => option.description || ""}
                isOptionEqualToValue={(option: any, value: any) =>
                  option.id === value.id
                }
                value={value}
                disabled={completeData}
                renderInput={(params) => {
                  const inputProps = params.inputProps;
                  inputProps.autoComplete = "new-password3";
                  return (
                    <TextField
                      {...params}
                      fullWidth
                      label="Ingresos anuales"
                      variant="standard"
                      error={!!errors.annual_income}
                      autoComplete="off"
                      helperText={
                        errors.annual_income && errors.annual_income.message
                      }
                    />
                  );
                }}
              />
            )}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <Controller
            control={control}
            name="level_experience_and_knowledge_as_investor"
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                onChange={(event, newValue) => {
                  onChange(newValue);
                }}
                options={dataJ.pa_ta_knowledge_level}
                getOptionLabel={(option: any) => option.description || ""}
                isOptionEqualToValue={(option: any, value: any) =>
                  option.id === value.id
                }
                value={value}
                disabled={completeData}
                renderInput={(params) => {
                  const inputProps = params.inputProps;
                  inputProps.autoComplete = "new-password3";
                  return (
                    <TextField
                      {...params}
                      fullWidth
                      label="Nivel de experiencia y conocimiento como inversionista"
                      variant="standard"
                      error={
                        !!errors.level_experience_and_knowledge_as_investor
                      }
                      helperText={
                        errors.level_experience_and_knowledge_as_investor &&
                        errors.level_experience_and_knowledge_as_investor
                          .message
                      }
                    />
                  );
                }}
              />
            )}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <Controller
            control={control}
            name="total_assets"
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                onChange={(event, newValue) => {
                  onChange(newValue);
                }}
                options={dataJ && dataJ.pa_ta_total_liquid_assets}
                getOptionLabel={(option: any) => option.description || ""}
                isOptionEqualToValue={(option: any, value: any) =>
                  option.id === value.id
                }
                value={value}
                disabled={completeData}
                renderInput={(params) => {
                  const inputProps = params.inputProps;
                  inputProps.autoComplete = "new-password3";
                  return (
                    <TextField
                      {...params}
                      fullWidth
                      label="Total activos líquidos"
                      variant="standard"
                      autoComplete="off"
                      error={!!errors.total_assets}
                      helperText={
                        errors.total_assets && errors.total_assets.message
                      }
                    />
                  );
                }}
              />
            )}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <Controller
            control={control}
            name="total_heritage"
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                onChange={(event, newValue: any) => {
                  if (newValue === null) {
                    newValue = "";
                  }
                  onChange(newValue);
                }}
                options={dataJ && dataJ.pa_ta_total_heritage}
                getOptionLabel={(option: any) => option.description || ""}
                isOptionEqualToValue={(option: any, value: any) =>
                  option.id === value.id
                }
                value={value}
                disabled={completeData}
                renderInput={(params) => {
                  const inputProps = params.inputProps;
                  inputProps.autoComplete = "new-password";
                  return (
                    <TextField
                      {...params}
                      fullWidth
                      label="Patrimonio total"
                      variant="standard"
                      error={!!errors.total_heritage}
                      helperText={
                        errors.total_heritage && errors.total_heritage.message
                      }
                    />
                  );
                }}
              />
            )}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <Controller
            control={control}
            name="accounts_payable"
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                onChange={(event, newValue) => {
                  onChange(newValue);
                }}
                options={dataJ && dataJ.pa_ta_accounts_payable}
                getOptionLabel={(option: any) => option.description || ""}
                isOptionEqualToValue={(option: any, value: any) =>
                  option.id === value.id
                }
                value={value}
                disabled={completeData}
                renderInput={(params) => {
                  const inputProps = params.inputProps;
                  inputProps.autoComplete = "new-password3";
                  return (
                    <TextField
                      {...params}
                      fullWidth
                      label="Cuentas por pagar"
                      variant="standard"
                      autoComplete="off"
                      error={!!errors.accounts_payable}
                      helperText={
                        errors.accounts_payable &&
                        errors.accounts_payable.message
                      }
                    />
                  );
                }}
              />
            )}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <Controller
            control={control}
            name="investment_horizon"
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                onChange={(event, newValue) => {
                  onChange(newValue);
                }}
                options={dataJ.pa_ta_investment_time_horizon}
                getOptionLabel={(option: any) => option.description || ""}
                isOptionEqualToValue={(option: any, value: any) =>
                  option.id === value.id
                }
                value={value}
                disabled={completeData}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    label="Horizonte de Inversión"
                    variant="standard"
                    error={!!errors.investment_horizon}
                    helperText={
                      errors.investment_horizon &&
                      errors.investment_horizon.message
                    }
                  />
                )}
              />
            )}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <Controller
            control={control}
            name="annual_expenses"
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                onChange={(event, newValue) => {
                  onChange(newValue);
                }}
                options={dataJ && dataJ.pa_ta_annual_expenses}
                getOptionLabel={(option: any) => option.description || ""}
                isOptionEqualToValue={(option: any, value: any) =>
                  option.id === value.id
                }
                value={value}
                disabled={completeData}
                renderInput={(params) => {
                  const inputProps = params.inputProps;
                  inputProps.autoComplete = "new-password3";
                  return (
                    <TextField
                      {...params}
                      fullWidth
                      label="Gastos anuales"
                      variant="standard"
                      autoComplete="off"
                      error={!!errors.annual_expenses}
                      helperText={
                        errors.annual_expenses && errors.annual_expenses.message
                      }
                    />
                  );
                }}
              />
            )}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <Controller
            control={control}
            name="emergency_fund_for_expenses"
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                onChange={(event, newValue) => {
                  onChange(newValue);
                }}
                options={dataJ?.pa_ta_emergency_fund ?? []}
                getOptionLabel={(option: any) => option?.description || ""}
                isOptionEqualToValue={(option: any, value: any) =>
                  option?.id === value?.id
                }
                value={value}
                disabled={completeData}
                renderInput={(params) => {
                  const inputProps = params.inputProps;
                  inputProps.autoComplete = "new-password3";
                  return (
                    <TextField
                      {...params}
                      fullWidth
                      label="¿Mantiene un fondo de emergencia para cubrir gastos imprevistos?"
                      variant="standard"
                      error={!!errors.emergency_fund_for_expenses}
                      helperText={
                        errors.emergency_fund_for_expenses &&
                        errors.emergency_fund_for_expenses.message
                      }
                    />
                  );
                }}
              />
            )}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <Controller
            control={control}
            name="destination_income"
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                onChange={(event, newValue: any) => {
                  onChange(newValue);
                }}
                options={dataJ && dataJ.pa_ta_destination_income}
                getOptionLabel={(option: any) => option.description || ""}
                isOptionEqualToValue={(option: any, value: any) =>
                  option.id === value.id
                }
                //@ts-ignore
                value={value}
                disabled={completeData}
                renderInput={(params) => {
                  const inputProps = params.inputProps;
                  inputProps.autoComplete = "destination_income";
                  return (
                    <TextField
                      {...params}
                      fullWidth
                      label="Destino de los intereses"
                      variant="standard"
                      error={!!errors.destination_income}
                      helperText={
                        errors.destination_income &&
                        errors.destination_income.message
                      }
                    />
                  );
                }}
              />
            )}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <Controller
            control={control}
            name="ability_absorb_losses"
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                onChange={(event, newValue) => {
                  onChange(newValue);
                }}
                options={dataJ?.pa_ta_ability_absorb_losses}
                getOptionLabel={(option: any) => option.description || ""}
                isOptionEqualToValue={(option: any, value: any) =>
                  option.id === value.id
                }
                value={value}
                disabled={completeData}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    label="Capacidad de absorber pérdidas de capital"
                    variant="standard"
                    error={!!errors.ability_absorb_losses}
                    helperText={
                      errors.ability_absorb_losses &&
                      errors.ability_absorb_losses.message
                    }
                  />
                )}
              />
            )}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <Controller
            control={control}
            name="investment_goals"
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                onChange={(event, newValue) => {
                  onChange(newValue);
                }}
                options={dataJ && dataJ.pa_ta_investment_goals}
                getOptionLabel={(option: any) => option.description || ""}
                isOptionEqualToValue={(option: any, value: any) =>
                  option.id === value.id
                }
                value={value}
                disabled={completeData}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    label="Objetivos/Tolerancia al riesgo ¿Cuál de estos criterios se ajusta más a sus ideas de rentabilidad/riesgo?"
                    variant="standard"
                    error={!!errors.investment_goals}
                    helperText={
                      errors.investment_goals && errors.investment_goals.message
                    }
                  />
                )}
              />
            )}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <Controller
            control={control}
            name="risk_tolerance"
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                onChange={(event, newValue) => {
                  onChange(newValue);
                }}
                options={dataJ.pa_ta_risk_tolerance}
                getOptionLabel={(option: any) => option.description || ""}
                isOptionEqualToValue={(option: any, value: any) =>
                  option.id === value.id
                }
                value={value}
                disabled={completeData}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    label="Tolerancia al riesgo"
                    variant="standard"
                    error={!!errors.risk_tolerance}
                    helperText={
                      errors.risk_tolerance && errors.risk_tolerance.message
                    }
                  />
                )}
              />
            )}
          />
        </Grid>

        <Grid item sm={6} xs={12}>
          <Controller
            control={control}
            name="desired_profitability"
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                onChange={(event, newValue: any) => {
                  onChange(newValue);
                }}
                options={dataJ && dataJ.pa_ta_desired_profitability}
                getOptionLabel={(option: any) => option.description || ""}
                isOptionEqualToValue={(option: any, value: any) =>
                  option.id === value.id
                }
                //@ts-ignore
                value={value}
                disabled={completeData}
                renderInput={(params) => {
                  const inputProps = params.inputProps;
                  inputProps.autoComplete = "desired_profitability3";
                  return (
                    <TextField
                      {...params}
                      fullWidth
                      label="Rentabilidad deseada"
                      variant="standard"
                      error={!!errors.desired_profitability}
                      helperText={
                        errors.desired_profitability &&
                        errors.desired_profitability.message
                      }
                    />
                  );
                }}
              />
            )}
          />
        </Grid>
        {/* <Grid item sm={6} xs={12}>
          <Controller
            control={control}
            name="main_sources_funds"
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                onChange={(event, newValue: any) => {
                  onChange(newValue);
                }}
                options={datos.pa_ta_main_sources_funds}
                getOptionLabel={(option: any) => option || ""}
                isOptionEqualToValue={(option: any, value: any) =>
                  option === value
                }
                //@ts-ignore
                value={value}
                disabled={completeData}
                renderInput={(params) => {
                  const inputProps = params.inputProps;
                  inputProps.autoComplete = "main_sources_funds3";
                  return (
                    <TextField
                      {...params}
                      fullWidth
                      label="Procedencia de fondos"
                      variant="standard"
                      error={!!errors.main_sources_funds}
                      helperText={
                        errors.main_sources_funds &&
                        errors.main_sources_funds.message
                      }
                    />
                  );
                }}
              />
            )}
          />
        </Grid> */}
        <Grid item sm={6} xs={12}>
          <Controller
            control={control}
            name="main_sources_funds"
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <FormControl fullWidth disabled={completeData}>
                <LabelForm>Procedencia de fondos</LabelForm>
                <Select
                  variant="standard"
                  value={value}
                  multiple
                  {...register("main_sources_funds")}
                  renderValue={(selected: any) => (
                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                      {selected.map((value: any) => (
                        <Chip
                          key={value}
                          label={
                            datos.pa_ta_main_sources_funds2.find(
                              (item: any) => item.id === value
                            ).description
                          }
                          color="primary"
                          size="small"
                          sx={{ mb: "2px" }}
                        />
                      ))}
                    </Box>
                  )}
                >
                  {datos.pa_ta_main_sources_funds2.map((item: any, i: any) => (
                    <MenuItem key={item.id} value={item.id}>
                      <Checkbox checked={value.indexOf(item.id) > -1} />
                      <ListItemText primary={item.description} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          />
        </Grid>
        {watch_main_sources_funds.some((item: any) => item === 8) && (
          <Grid item sm={6} xs={12}>
            <Controller
              name={"other_main_sources_funds"}
              control={control}
              render={() => (
                <TextField
                  fullWidth
                  size="small"
                  label="Otra procedencia de fondos"
                  type="text"
                  variant="standard"
                  disabled={completeData}
                  autoComplete="other_main_sources_funds"
                  {...register("other_main_sources_funds")}
                  error={
                    errors.other_main_sources_funds &&
                    Boolean(errors.other_main_sources_funds)
                  }
                  helperText={
                    errors.other_main_sources_funds &&
                    errors.other_main_sources_funds.message
                  }
                />
              )}
            />
          </Grid>
        )}
        <Grid item sm={6} xs={12}>
          <Controller
            control={control}
            name="operations_values_finance"
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <FormControl fullWidth disabled={completeData}>
                <LabelForm>
                  Operaciones de títulos valores que comprende
                </LabelForm>
                <Select
                  variant="standard"
                  value={value}
                  multiple
                  {...register("operations_values_finance")}
                  renderValue={(selected: any) => (
                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                      {selected.map((value: any) => (
                        <Chip
                          key={value}
                          label={
                            dataJ &&
                            dataJ.pa_ta_operations_values_finance.find(
                              (item: any) => item.id === value
                            ).description
                          }
                          color="primary"
                          size="small"
                          sx={{ mb: "2px" }}
                        />
                      ))}
                    </Box>
                  )}
                >
                  {dataJ &&
                    dataJ.pa_ta_operations_values_finance.map(
                      (item: any, i: any) => (
                        <MenuItem key={item.id} value={item.id}>
                          <Checkbox checked={value.indexOf(item.id) > -1} />
                          <ListItemText primary={item.description} />
                        </MenuItem>
                      )
                    )}
                </Select>
              </FormControl>
            )}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <Controller
            control={control}
            name="average_annual_amount"
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                onChange={(event, newValue) => {
                  onChange(newValue);
                }}
                options={dataJ && dataJ.pa_ta_initial_investment_amount}
                getOptionLabel={(option: any) => option.description || ""}
                isOptionEqualToValue={(option: any, value: any) =>
                  option.id === value.id
                }
                value={value}
                disabled={completeData}
                renderInput={(params) => {
                  const inputProps = params.inputProps;
                  inputProps.autoComplete = "new-password";
                  return (
                    <TextField
                      {...params}
                      fullWidth
                      label="Monto inicial de inversión"
                      variant="standard"
                      error={!!errors.average_annual_amount}
                      helperText={
                        errors.average_annual_amount &&
                        errors.average_annual_amount.message
                      }
                    />
                  );
                }}
              />
            )}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <Controller
            control={control}
            name="financialactivities"
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                onChange={(event, newValue) => {
                  onChange(newValue);
                }}
                options={dataJ && dataJ.pa_ta_economic_activity}
                getOptionLabel={(option: any) => option.description || ""}
                isOptionEqualToValue={(option: any, value: any) =>
                  option.id === value.id
                }
                value={value}
                disabled={completeData}
                renderInput={(params) => {
                  const inputProps = params.inputProps;
                  inputProps.autoComplete = "new-password";
                  return (
                    <TextField
                      {...params}
                      fullWidth
                      label="Actividades financieras"
                      variant="standard"
                      error={!!errors.financialactivities}
                      helperText={
                        errors.financialactivities &&
                        errors.financialactivities.message
                      }
                    />
                  );
                }}
              />
            )}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <Controller
            control={control}
            name="relationship_duration"
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                onChange={(event, newValue) => {
                  onChange(newValue);
                }}
                options={dataJ?.pa_ta_relationship_duration ?? []}
                getOptionLabel={(option: any) => option?.description || ""}
                isOptionEqualToValue={(option: any, value: any) =>
                  option?.id === value?.id
                }
                value={value}
                disabled={completeData}
                renderInput={(params) => {
                  const inputProps = params.inputProps;
                  inputProps.autoComplete = "new-password";
                  return (
                    <TextField
                      {...params}
                      fullWidth
                      label="Duración de la relación"
                      variant="standard"
                      error={!!errors.relationship_duration}
                      helperText={
                        errors.relationship_duration &&
                        errors.relationship_duration.message
                      }
                    />
                  );
                }}
              />
            )}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <Controller
            control={control}
            name="operation_time"
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                onChange={(event, newValue) => {
                  onChange(newValue);
                }}
                options={dataJ?.pa_ta_operation_time ?? []}
                getOptionLabel={(option: any) => option?.description || ""}
                isOptionEqualToValue={(option: any, value: any) =>
                  option?.id === value?.id
                }
                value={value}
                disabled={completeData}
                renderInput={(params) => {
                  const inputProps = params.inputProps;
                  inputProps.autoComplete = "new-password";
                  return (
                    <TextField
                      {...params}
                      fullWidth
                      label="Tiempo de operación"
                      variant="standard"
                      error={!!errors.operation_time}
                      helperText={
                        errors.operation_time && errors.operation_time.message
                      }
                    />
                  );
                }}
              />
            )}
          />
        </Grid>
      </Grid>
      <TitleForm sx={{ mt: 8, mb: 4 }}>
        Designación de cuenta bancaria
      </TitleForm>
      {bank_accounts_registerFields.map((field, index) => {
        return (
          <div key={index}>
            {index !== 0 && (
              <Divider
                sx={{
                  pt: 3,
                  mb: 3,
                  borderColor: "#efe5df",
                  width: "50%",
                  ml: "auto",
                  mr: "auto",
                }}
              />
            )}
            <Grid container spacing={4} key={field.id} sx={{ mb: 2 }}>
              <Grid item sm={6} xs={12}>
                <Controller
                  name={`bank_accounts_register.${index}.account_holder`}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      fullWidth
                      size="small"
                      label="Titular de la cuenta"
                      type="text"
                      variant="standard"
                      disabled={completeData}
                      {...register(
                        `bank_accounts_register.${index}.account_holder` as const
                      )}
                      error={
                        errors?.bank_accounts_register?.[index]
                          ?.account_holder && true
                      }
                      helperText={
                        errors?.bank_accounts_register?.[index]?.account_holder
                          ?.message
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <Controller
                  control={control}
                  name={`bank_accounts_register.${index}.institution`}
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      fullWidth
                      onChange={(event, newValue) => {
                        onChange(newValue);
                      }}
                      options={dataJ.pa_ta_bank_entity}
                      getOptionLabel={(option: any) => option.name || ""}
                      isOptionEqualToValue={(option: any, value: any) =>
                        option.id === value.id
                      }
                      value={value}
                      disabled={completeData}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          label="Institución"
                          variant="standard"
                          error={
                            errors?.bank_accounts_register?.[index]
                              ?.institution && true
                          }
                          helperText={
                            errors?.bank_accounts_register?.[index]?.institution
                              ?.message
                          }
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item md={4} sm={6} xs={12}>
                <Controller
                  control={control}
                  name={`bank_accounts_register.${index}.account_type`}
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      fullWidth
                      onChange={(event, newValue) => {
                        onChange(newValue);
                      }}
                      options={datos.pa_ta_account_type}
                      getOptionLabel={(option: any) => option || ""}
                      isOptionEqualToValue={(option: any, value: any) =>
                        option === value
                      }
                      value={value}
                      disabled={completeData}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          label="Tipo de cuenta"
                          variant="standard"
                          error={
                            errors?.bank_accounts_register?.[index]
                              ?.account_type && true
                          }
                          helperText={
                            errors?.bank_accounts_register?.[index]
                              ?.account_type?.message
                          }
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item md={4} sm={6} xs={12}>
                <Controller
                  name={`bank_accounts_register.${index}.account_number`}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      fullWidth
                      size="small"
                      label="Número de cuenta"
                      type="text"
                      variant="standard"
                      disabled={completeData}
                      {...register(
                        `bank_accounts_register.${index}.account_number` as const
                      )}
                      error={
                        errors?.bank_accounts_register?.[index]
                          ?.account_number && true
                      }
                      helperText={
                        errors?.bank_accounts_register?.[index]?.account_number
                          ?.message
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item md={4} sm={6} xs={12} sx={{ display: "flex" }}>
                <Controller
                  control={control}
                  name={`bank_accounts_register.${index}.currency_type`}
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      fullWidth
                      onChange={(event, newValue) => {
                        onChange(newValue);
                      }}
                      options={dataJ.pa_ta_currency_preference}
                      getOptionLabel={(option: any) => option.description || ""}
                      isOptionEqualToValue={(option: any, value: any) =>
                        option.id === value.id
                      }
                      value={value}
                      disabled={completeData}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          label="Moneda"
                          variant="standard"
                          error={
                            errors?.bank_accounts_register?.[index]
                              ?.currency_type && true
                          }
                          helperText={
                            errors?.bank_accounts_register?.[index]
                              ?.currency_type?.message
                          }
                        />
                      )}
                    />
                  )}
                />
                {index !== 0 && (
                  <IconButton
                    aria-label="delete"
                    color="primary"
                    onClick={() => {
                      bank_accounts_registerRemove(index);
                    }}
                  >
                    <RemoveCircleIcon fontSize="medium" />
                  </IconButton>
                )}
              </Grid>
            </Grid>
          </div>
        );
      })}
      {bank_accounts_registerFields.length < 2 && !completeData && (
        <Grid
          container
          justifyContent="flex-end"
          alignItems="center"
          sx={{ mb: 2 }}
        >
          <Button
            type="button"
            variant="outlined"
            onClick={() => {
              bank_accounts_registerAppend({
                account_holder: "",
                institution: [],
                account_type: "",
                account_number: "",
                currency_type: [],
              });
            }}
          >
            <AddCircleIcon fontSize="small" sx={{ mr: 1 }} /> Agregar
          </Button>
        </Grid>
      )}
      {loadingSendData && (
        <Stack spacing={2} direction="row" sx={{ mt: 1 }}>
          <CircularProgress
            size={24}
            sx={{ margin: "5px auto", color: `${theme.palette.primary.main}` }}
          />
        </Stack>
      )}

      <Stack
        justifyContent="flex-end"
        alignItems="flex-end"
        direction="row"
        spacing={2}
        sx={{ mt: 2 }}
      >
        {completeData ? (
          <>
            <Button
              type="button"
              variant="outlined"
              onClick={handleBack}
              sx={{ width: { xs: "100%", sm: "auto" } }}
            >
              Regresar
            </Button>
            <ButtonGradient
              type="button"
              onClick={handleNext}
              sx={{ width: { xs: "100%", sm: "auto" } }}
            >
              Continuar
            </ButtonGradient>
          </>
        ) : (
          <>
            <Tooltip
              disableFocusListener
              disableTouchListener
              title={
                step3Answered === false
                  ? "Perderas la información no guardada"
                  : ""
              }
              placement="top"
            >
              <Button
                type="button"
                variant="outlined"
                onClick={handleBack}
                sx={{ width: { xs: "100%", sm: "auto" } }}
              >
                Regresar
              </Button>
            </Tooltip>
            <ButtonGradient
              type="submit"
              sx={{ width: { xs: "100%", sm: "auto" } }}
            >
              Continuar
            </ButtonGradient>
          </>
        )}
      </Stack>
    </form>
  );
};

export default EconomicActivity;
