import {
  createBrowserRouter,
  createRoutesFromElements,
  BrowserRouter,
  Routes,
  Route,
  defer,
} from "react-router-dom";
import GeneralFormLayout from "./layouts/GeneralFormLayout";
import LegalForm from "./views/legalForm/LegalForm";
import { LegalFormProvider } from "./context/LegalFormProvider";
import Branchs from "./views/Branchs";
import { getAuthData } from "./utils/LocalStorageManager";
import { AuthLayout } from "./components/security/AuthLayout";

/* const AppRoutes = () => {
  return (
    <BrowserRouter>
      <LegalFormProvider>
        <Routes>
          <Route path="/" element={<Branchs />} />
          <Route path="legalForm" element={<GeneralFormLayout />}>
            <Route index element={<LegalForm />} />
          </Route>
        </Routes>
      </LegalFormProvider>
    </BrowserRouter>
  );
}; */

const AppRoutes = createBrowserRouter(
  createRoutesFromElements(
    <Route
      element={<AuthLayout />}
      loader={async () => defer({ authPromise: getAuthData() })}
    >
      <Route path="/" element={<Branchs />} />
      <Route path="legalForm" element={<GeneralFormLayout />}>
        <Route index element={<LegalForm />} />
      </Route>
    </Route>
  )
);

export default AppRoutes;
