import { useState, ReactElement } from "react";
import {
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  useTheme,
} from "@mui/material";

interface DialogProps {
  textTitle?: string;
  textChildren?: any;
  actionButton?: any;
}

const FancyDialog = ({
  textTitle,
  textChildren,
  actionButton,
}: DialogProps) => {
  const theme = useTheme();
  return (
    <>
      <DialogTitle
        id="alert-dialog-title"
        sx={{ pt: 4, px: 4, textAlign: "center", fontWeight: "bold" }}
      >
        {textTitle}
      </DialogTitle>
      <DialogContent sx={{ px: 8, pb: 0 }}>
        {/* <DialogContentText
          id="alert-dialog-description"
          sx={{ color: `${theme.palette.text.primary}` }}
        > */}
        {textChildren!}
        {/* </DialogContentText> */}
      </DialogContent>
      <DialogActions sx={{ pb: 4, px: 4 }}>{actionButton}</DialogActions>
    </>
  );
};

export default FancyDialog;
