export const CONSTANT = {
  /** DISPATCHER PREFIX */
  DISPATCHER_SECURITY_PREFIX:"/security",
  DISPATCHER_INVESTMENT_PROFILE: "investment-profile/",
  DISPATCHER_MAINTENANCE: "perfil-maintenance/",
  profile: "profile/",
  documents: "documents/pdf/",

  /** TYPE MESSAGE */
  SUCCESS_MSG: 1,
  ERROR_MSG: 2,
  WARNING_MSG: 3,

  /** DEFAULT YES AND NOT */
  DEFAULT_YES: "S",
  DEFAULT_NOT: "N",

    /** HEADER TYPE */
  HEADER_TYPE: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
  DATE_FORMAT: "dd/MM/yyyy",

};
