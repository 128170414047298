import { createTheme  } from '@mui/material/styles'

let theme = createTheme()

interface customGrey {
  greyDue: {
    main: string
    secondary?: string
    light?: string
    dark?: string
  }
}

declare module '@mui/material/styles' {
  interface Palette extends customGrey {}
  interface PaletteOptions extends customGrey {}
}

theme = createTheme({
  palette: {
    primary: {
      main: '#f1b80e',
      light: '#FFFFFF',
      dark: '#f1b80e',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#BCBEC0',
      light: '#FFFFFF',
      dark: '#5C5D60',
      contrastText: '#ffffff',
    },
    info: {
      main: '#f1b80e',
      light: '#FFFFFF',
      dark: '#3e627c',
      contrastText: '#ffffff',
    },
    greyDue: {
      main: '#A2A2A2',
      dark: '#212529',
      light: '#ABABAB',
    },
    success: {
      main: '#d32f2f',
    },
    text: {
      primary: '#5d5e60',
      secondary: '#e4e8ed',
    },
    background: {
      default: '#fff',
    },
    common: {
      white: '#fff'
    }

  },
  typography: {
    fontFamily: 'D-din',
    h1: {
      fontSize: '3.5125rem',
      fontWeight: 700,
      fontFamily: 'D-din',
    },
    h2: {
      fontSize: '2.7125rem',
      fontWeight: 700,
      fontFamily: 'D-dinCondesed',
    },
    h3: {
      fontSize: '1.5rem'
    },
    h4: {
      fontSize: '1.3125rem',
      fontWeight: 700,
      fontFamily: 'D-dinCondesed',
    },
    h5: {
      fontSize: '1.3125rem',
    },
    button: {
      textTransform: 'none',
      fontSize: '1rem',
    },
    body1: {
      fontSize: '1rem',
    },
    body2: {
      fontSize: '.8125rem',
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1325,
      xl: 1440,
    },
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiInput-underline:before': {
              borderBottom: "2px solid #CACACA",
          },
          '& label': {
            color: '#666666',
          }
        },
      },
    },
    MuiInput:{
      styleOverrides: {
        root: {
          '& .MuiInput-underline:before': {
            borderBottom: "2px solid #CACACA",
          },
        },
        underline: {
          '&:before': {
            borderBottom: "2px solid #CACACA",
          },
        }
      }
    },
    MuiInputBase:{
      styleOverrides: {
        root: {
          '& .MuiInput-underline:before': {
            borderBottom: "2px solid #CACACA",
          },
        },
        input: {
          padding:' 4px 0 2px',
          '& .MuiInput-underline:before': {
            borderBottom: "2px solid #CACACA",
          },
        },
      }
    },
    MuiInputLabel: {

    },
    MuiPaper: {
      styleOverrides: {
        root: {
          boxShadow: '0px 3px 5px -1px rgb(0 0 0 / 5%), 0px 6px 10px 0px rgb(0 0 0 / 10%), 0px 1px 18px 0px rgb(0 0 0 / 5%)'
        }
      }
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          marginLeft: '0px',
          color: '#d32f2f'
        }
      }
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          padding:' 4px 0 2px',
          '& .MuiInput-underline:before': {
            borderBottom: "2px solid #CACACA",
          },
          '&.Mui-disabled': {
            WebkitTextFillColor: 'rgba(255, 255, 255, 0.8)',
          }
        }
      }
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            color: 'inherit!important',
            opacity: '0.5'
          }
        },
      }
    },
    MuiButton: {
      styleOverrides: {
        outlinedPrimary: {
          borderRadius: theme.spacing(4),
          padding: `${theme.spacing(0.5)} ${theme.spacing(5)}`,
          background: '#BCBEC0',
          color: '#ffffff',
          borderColor: '#BCBEC0',
          '&:hover': {
            background: '#5C5D60',
            borderColor: '#5C5D60',
          }
        }
      }
    },
    MuiStepLabel: {
      styleOverrides: {
        labelContainer: {
          color: '#ABABAB'
        }
      }
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: '#666666'
        }
      }
    },
    MuiAutocomplete: {
      styleOverrides: {
        input: {
          padding: '0px 4px 5px 0px!important'
        },
        root: {
          '& label:not(.MuiInputLabel-shrink)': {
            WebkitTransform: 'translate(0, 17px) scale(1)',
            MozTransform: 'translate(0, 17px) scale(1)',
            MsTransform: 'translate(0, 17px) scale(1)',
            transform: 'translate(0, 17px) scale(1)'
          }
        }
      }
    }
  },
});

export default theme

