import { FC, useEffect, useState } from "react";
import useLegalForm from "../../hooks/useLegalForm";
import useProfile from "../../hooks/useProfile";
import { properties } from "../../utils/Properties_es";
import {
  Grid,
  TextField,
  Autocomplete,
  Stack,
  CircularProgress,
  IconButton,
  Button,
  Divider,
  useTheme,
} from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import DateFnsUtils from "@date-io/date-fns";
import esLocale from "date-fns/locale/es";
import TitleForm from "../../components/TitleForm";
import ButtonGradient from "../../components/ButtonGradient";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { validationBussinessIndetification } from "./validation";
import { BussinesIdenticationType } from "../../types/types";
import { data as datos } from "../../data/data";
import { saveProfile, updateProfile } from "../../services/Services";
import { useApp } from "../../hooks/useApp";

const BussinessIdentification: FC = () => {
  const theme = useTheme();
  const { setActiveStep, step1, setStep1, handleNext, branch, promoter } =
    useLegalForm();
  const { dataForm, completeData, data, dataJ, loaderAllData, address } =
    useProfile();
  const [loadingSendData, setLoadingSendData] = useState(false);
  const { setLoading, setErrorMsg, setSuccessMsg, errorMsg } = useApp();
  let localData = localStorage.getItem("stepOne");

  let findData: any;
  findData = step1;

  if (dataForm !== null) {
    findData = dataForm;
  } else if (typeof localData === "string") {
    findData = JSON.parse(localData);
  } else {
    findData = step1;
  }

  const countryASC =
    data && //@ts-ignore
    data?.pa_ta_countries?.sort((a: any, b: any) => {
      if (a.description < b.description) {
        return -1;
      }
    });

  const defaultValues: BussinesIdenticationType = {
    investment_promoter: findData.investment_promoter
      ? dataJ && //@ts-ignore
        dataJ.pa_ta_investment_promoter.find(
          (f: any) => f.id === Number(findData.investment_promoter)
        )
      : [],
    name_social_denomination: findData.name_social_denomination
      ? findData.name_social_denomination
      : "",
    company_email: findData.company_email ? findData.company_email : "",
    rnc_document_number: findData.rnc_document_number
      ? findData.rnc_document_number
      : "",
    business_registration_number: findData.business_registration_number
      ? findData.business_registration_number
      : "",
    relationship_with_parval: findData.relationship_with_parval
      ? findData.relationship_with_parval
      : "",
    company_type: findData.company_type //@ts-ignore
      ? dataJ && //@ts-ignore
        dataJ.pa_ta_company_type.find(
          (f: any) => f.id === Number(findData.company_type)
        )
      : [],
    country_of_incorporation: findData.country_of_incorporation
      ? dataJ && //@ts-ignore
        dataJ.pa_ta_countries.find(
          (f: any) => f.id === Number(findData.country_of_incorporation)
        )
      : [],
    incorporation_date: findData.incorporation_date
      ? new Date(JSON.parse(findData.incorporation_date))
      : null,
    website: findData.website ? findData.website : "",
    channel_catchment_client: findData.channel_catchment_client //@ts-ignore
      ? dataJ &&
        dataJ.pa_ta_channel_catchment_client.find(
          (f: any) => f.id === Number(findData.channel_catchment_client)
        )
      : [],
    personal_phone: findData.personal_phone ? findData.personal_phone : "",
    main_clients_and_countries: findData.main_clients_and_countries
      ? dataJ && //@ts-ignore
        dataJ.pa_ta_countries.find(
          (f: any) => f.id === Number(findData.main_clients_and_countries)
        )
      : [],
    country: findData.country //@ts-ignore
      ? data.pa_ta_countries.find((f: any) => f.id === Number(findData.country))
      : [],
    province: findData.province
      ? address &&
        address.provinces.find((f: any) => f.id === Number(findData.province))
      : [],
    sector: findData.sector
      ? address &&
        address.sector.find((f: any) => f.id === Number(findData.sector))
      : [],
    street_contact_person: findData.street_contact_person
      ? findData.street_contact_person
      : "",
    building: findData.building ? findData.building : "",

    legal_representative: (findData.legal_representative &&
      JSON.parse(findData.legal_representative)) ?? [
      {
        name: "",
        id_type: null,
        document_number: "",
        marital_status: "",
        date_of_birth: null,
        country_of_nationality: null,
        country: null,
        province: null,
        sector: null,
        address: "",
        building_name: "",
        floor_number: "",
        postal_code: "",
        email: "",
      },
    ],
    sector_type: findData.sector_type ? findData.sector_type : "",
    business_activity: findData.business_activity
      ? findData.business_activity
      : "",
    quantity_of_employees: findData.quantity_of_employees
      ? findData.quantity_of_employees
      : "",
    number_of_shareholders: findData.number_of_shareholders
      ? findData.number_of_shareholders
      : "",
    annual_sales_volume: findData.annual_sales_volume
      ? findData.annual_sales_volume
      : "",
    products_or_services: findData.products_or_services
      ? findData.products_or_services
      : "",
  };

  const {
    register,
    handleSubmit,
    resetField,
    reset,
    formState,
    control,
    watch,
    getValues,
  } = useForm<BussinesIdenticationType>({
    defaultValues,
    resolver: yupResolver(validationBussinessIndetification),
  });
  const {
    fields: legal_representativeFields,
    append: legal_representativeAppend,
    remove: legal_representativeRemove,
  } = useFieldArray({
    control,
    name: "legal_representative",
    rules: {
      minLength: 1,
    },
  });
  const { errors, isDirty, dirtyFields } = formState;
  const watch_country: any = watch("country");
  const watch_province: any = watch("province");

  const miarray: any = [];

  const watch_country_0: any = watch(`legal_representative.${0}.country`);
  const watch_country_1: any = watch(`legal_representative.${1}.country`);
  const watch_province0: any = watch(`legal_representative.${0}.province`);
  const watch_province1: any = watch(`legal_representative.${1}.province`);
  const watch_legal_representative = watch("legal_representative");

  useEffect(() => {
    reset(defaultValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataForm]);

  useEffect(() => {
    if (dataForm !== undefined && dirtyFields.country) {
      resetField("province", { defaultValue: [] });
      resetField("sector", { defaultValue: [] });
    } else if (watch_country?.id !== 63) {
      resetField("province");
      resetField("sector");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch_country]);

  useEffect(() => {
    if (dataForm !== undefined && dirtyFields.province) {
      resetField("sector", { defaultValue: [] });
    } else {
      resetField("sector");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch_province]);

  useEffect(() => {
    if (findData !== null && dirtyFields?.legal_representative?.[0]?.country) {
      resetField(`legal_representative.${0}.province`, { defaultValue: [] });
      resetField(`legal_representative.${0}.sector`, { defaultValue: [] });
    } else if (watch_country_0?.id !== 6) {
      resetField(`legal_representative.${0}.province`);
      resetField(`legal_representative.${0}.sector`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch_country_0]);

  useEffect(() => {
    if (findData !== null && dirtyFields?.legal_representative?.[1]?.country) {
      resetField(`legal_representative.${1}.province`, { defaultValue: [] });
      resetField(`legal_representative.${1}.sector`, { defaultValue: [] });
    } else if (watch_country_1?.id !== 6) {
      resetField(`legal_representative.${1}.province`);
      resetField(`legal_representative.${1}.sector`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch_country_1]);

  useEffect(() => {
    if (completeData === false) {
      if (
        findData !== null &&
        dirtyFields?.legal_representative?.[0].province
      ) {
        resetField(`legal_representative.${0}.sector`, { defaultValue: [] });
      } else {
        resetField(`legal_representative.${0}.sector`);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch_province0]);

  useEffect(() => {
    if (findData !== null && dirtyFields?.legal_representative?.[1]?.province) {
      resetField(`legal_representative.${1}.sector`, { defaultValue: [] });
    } else {
      resetField(`legal_representative.${1}.sector`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch_province1]);

  const onSubmit = async (data: any) => {
    setLoadingSendData(true);

    try {
      let key: string | any;
      let value: { id: string; description: string }[] | any = [];

      for ([key, value] of Object.entries(data)) {
        miarray.push({ [key]: value });
        if (!value) {
          delete data[key];
        } else if (typeof value == "object") {
          data[key] =
            value !== null && value.id
              ? typeof value.id !== "string"
                ? JSON.stringify(value.id)
                : value.id
              : JSON.stringify(value);
        }
      }
      data.step_one_complete = "true";
      data.branchOffice = JSON.stringify(branch);

      let update = {};
      for ([key, value] of Object.entries(dirtyFields)) {
        value = getValues(key);
        if (typeof value == "object") {
          Object.assign(update, {
            [key]:
              value !== null && value.id
                ? typeof value.id !== "string"
                  ? JSON.stringify(value.id)
                  : value.id
                : JSON.stringify(value),
          });
        } else {
          Object.assign(update, { [key]: value });
        }
        if (key === "country") {
          if (value !== 63) {
            Object.assign(update, { province: "" });
            Object.assign(update, { sector: "" });
          }
        }
      }

      setStep1 && setStep1(data);
      localStorage.setItem("stepOne", JSON.stringify(data));

      const handleResponse = () => {
        setStep1 && setStep1(data);
        setLoadingSendData(false);
        setActiveStep && setActiveStep((prevActiveStep) => prevActiveStep + 1);
      };

      /* let fullData = {
        fields: {
          ...data,
          branchOffice: JSON.stringify(branch),
        },
        formType: "J",
        userId: 0,
        stepForm: "Conozca a su Cliente",
      };

      localStorage.setItem("stepForm", "Conozca a su Cliente");
      localStorage.setItem("formData", JSON.stringify(fullData)); */

      setLoadingSendData(false);
      setActiveStep && setActiveStep((prevActiveStep) => prevActiveStep + 1);

      /* if (findData.step_one_complete !== "true") {
        let fullData = {
          fields: {
            ...data,
            branchOffice: JSON.stringify(branch),
          },
          formType: "J",
          userId: 0,
          stepForm: localStorage.getItem("stepForm") ?? "Conozca a su Cliente",
        };

        localStorage.setItem("stepForm", "Conozca a su Cliente");
        localStorage.setItem("formData", JSON.stringify(fullData));
        const saveData = await saveProfile(fullData);

        if (saveData.status === 200) {
          localStorage.setItem("userId", JSON.stringify(saveData.userId));

          handleResponse();
        } else {
          // Handle error when saveData.status is not 200
          //throw new Error(properties.com_parval_label_client_save_error)
          setErrorMsg &&
            setErrorMsg(properties.com_parval_label_client_save_error);
          setLoadingSendData(false);
        }
      } else {
        if (isDirty) {
          let fullUpData = {
            fields: {
              ...update,
              branchOffice: JSON.stringify(branch),
            },
            formType: "J",
            userId: localStorage.getItem("userId"),
            stepForm: localStorage.getItem("stepForm"),
          };

          const updateData = await updateProfile(fullUpData);

          if (updateData.status === 200) {
            handleResponse();
          } else {
            // Handle error when updateData.status is not 200
            //throw new Error(properties.com_parval_label_client_update_error)
            setErrorMsg &&
              setErrorMsg(properties.com_parval_label_client_update_error);
            setLoadingSendData(false);
          }
        } else {
          setLoadingSendData(false);
          setActiveStep &&
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
      } */
    } catch (error: any) {
      //throw new Error(properties.com_parval_label_client_unexpected_error)
      setErrorMsg &&
        setErrorMsg(properties.com_parval_label_client_update_error);
      setLoadingSendData(false);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <TitleForm>Información Personal</TitleForm>
      {!loaderAllData ? (
        <>
          <Stack spacing={2} direction="row" justifyContent="center">
            <CircularProgress color="primary" />
          </Stack>
        </>
      ) : (
        <>
          <>
            <Grid container spacing={4}>
              <Grid item md={4} sm={6} xs={12}>
                <Controller
                  name={"company_email"}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      fullWidth
                      size="small"
                      label="Correo electrónico"
                      type="text"
                      variant="standard"
                      value={value}
                      disabled={completeData}
                      autoComplete="company_email"
                      {...register("company_email")}
                      error={
                        errors.company_email && Boolean(errors.company_email)
                      }
                      helperText={
                        errors.company_email && errors.company_email.message
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item md={4} sm={6} xs={12}>
                <Controller
                  control={control}
                  name="investment_promoter"
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      onChange={(event, newValue: any) => {
                        onChange(newValue);
                      }}
                      options={
                        promoter &&
                        promoter.filter(
                          (item: any) => item.branchOffice === branch[0].id
                        )
                      }
                      getOptionLabel={(option: any) => option.description || ""}
                      isOptionEqualToValue={(option: any, value: any) =>
                        option.id === value.id
                      }
                      value={value}
                      disabled={completeData}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          label="Representante de cuentas"
                          variant="standard"
                          error={!!errors.investment_promoter}
                          helperText={
                            errors.investment_promoter &&
                            errors.investment_promoter.message
                          }
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item md={4} sm={6} xs={12}>
                <Controller
                  name={"name_social_denomination"}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      fullWidth
                      size="small"
                      label="Nombre / Razón social"
                      type="text"
                      variant="standard"
                      value={value}
                      disabled={completeData}
                      autoComplete="name_social_denomination"
                      {...register("name_social_denomination")}
                      error={
                        errors.name_social_denomination &&
                        Boolean(errors.name_social_denomination)
                      }
                      helperText={
                        errors.name_social_denomination &&
                        errors.name_social_denomination.message
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item md={4} sm={6} xs={12}>
                <Controller
                  name={"rnc_document_number"}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      fullWidth
                      size="small"
                      label="RNC o equivalente"
                      type="text"
                      variant="standard"
                      value={value}
                      disabled={completeData}
                      autoComplete="rnc_document_number"
                      {...register("rnc_document_number")}
                      error={
                        errors.rnc_document_number &&
                        Boolean(errors.rnc_document_number)
                      }
                      helperText={
                        errors.rnc_document_number &&
                        errors.rnc_document_number.message
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item md={4} sm={6} xs={12}>
                <Controller
                  name={"business_registration_number"}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      fullWidth
                      size="small"
                      label="Número de registro mercantil"
                      type="text"
                      variant="standard"
                      value={value}
                      disabled={completeData}
                      autoComplete="password8"
                      {...register("business_registration_number")}
                      error={
                        errors.business_registration_number &&
                        Boolean(errors.business_registration_number)
                      }
                      helperText={
                        errors.business_registration_number &&
                        errors.business_registration_number.message
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item md={4} sm={6} xs={12}>
                <Controller
                  control={control}
                  name="relationship_with_parval"
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      onChange={(event, newValue: any) => {
                        onChange(newValue);
                      }}
                      options={datos.pa_ta_relationship_with_parval}
                      getOptionLabel={(option: any) => option || ""}
                      isOptionEqualToValue={(option: any, value: any) =>
                        option === value
                      }
                      value={value}
                      disabled={completeData}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          label="Relación con parval"
                          variant="standard"
                          error={!!errors.relationship_with_parval}
                          helperText={
                            errors.relationship_with_parval &&
                            errors.relationship_with_parval.message
                          }
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item md={4} sm={6} xs={12}>
                <Controller
                  control={control}
                  name="company_type"
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      onChange={(event, newValue: any) => {
                        onChange(newValue);
                      }}
                      options={dataJ && dataJ.pa_ta_company_type}
                      getOptionLabel={(option: any) => option.description || ""}
                      isOptionEqualToValue={(option: any, value: any) =>
                        option.id === value.id
                      }
                      //@ts-ignore
                      value={value}
                      disabled={completeData}
                      renderInput={(params) => {
                        const inputProps = params.inputProps;
                        inputProps.autoComplete = "company_type";
                        return (
                          <TextField
                            {...params}
                            fullWidth
                            label="Tipo de empresa"
                            variant="standard"
                            error={!!errors.company_type}
                            helperText={
                              errors.company_type && errors.company_type.message
                            }
                          />
                        );
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item md={4} sm={6} xs={12}>
                <Controller
                  control={control}
                  name="country_of_incorporation"
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      onChange={(event, newValue) => {
                        onChange(newValue);
                      }}
                      options={data && countryASC}
                      getOptionLabel={(option: any) => option.description || ""}
                      isOptionEqualToValue={(option: any, value: any) =>
                        option.id === value.id
                      }
                      value={value}
                      disabled={completeData}
                      renderInput={(params) => {
                        const inputProps = params.inputProps;
                        inputProps.autoComplete = "new-password";
                        return (
                          <TextField
                            {...params}
                            fullWidth
                            label="Lugar de constitución"
                            variant="standard"
                            error={!!errors.country_of_incorporation}
                            helperText={
                              errors.country_of_incorporation &&
                              errors.country_of_incorporation.message
                            }
                          />
                        );
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item md={4} sm={6} xs={12}>
                <Controller
                  name={"incorporation_date"}
                  control={control}
                  defaultValue={null}
                  render={({ field, ...props }) => (
                    <LocalizationProvider
                      dateAdapter={DateFnsUtils}
                      adapterLocale={esLocale}
                    >
                      <DatePicker
                        value={field.value}
                        disabled={completeData}
                        onChange={(date) => {
                          field.onChange(date);
                        }}
                        label="Fecha de constitución"
                        className={errors.incorporation_date && "Mui-error"}
                        maxDate={new Date()}
                        renderInput={(params) => (
                          <TextField
                            fullWidth
                            variant="standard"
                            helperText={
                              errors.incorporation_date &&
                              errors.incorporation_date.message
                            }
                            {...params}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  )}
                />
              </Grid>
              <Grid item md={4} sm={6} xs={12}>
                <Controller
                  name={"website"}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      fullWidth
                      size="small"
                      label="Portal web de la empresa"
                      type="text"
                      variant="standard"
                      value={value}
                      disabled={completeData}
                      autoComplete="website"
                      {...register("website")}
                      error={errors.website && Boolean(errors.website)}
                      helperText={errors.website && errors.website.message}
                    />
                  )}
                />
              </Grid>
              <Grid item md={4} sm={6} xs={12}>
                <Controller
                  control={control}
                  name="channel_catchment_client"
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      onChange={(event, newValue) => {
                        onChange(newValue);
                      }}
                      options={dataJ.pa_ta_channel_catchment_client}
                      getOptionLabel={(option: any) => option.description || ""}
                      isOptionEqualToValue={(option: any, value: any) =>
                        option.id === value.id
                      }
                      value={value}
                      disabled={completeData}
                      renderInput={(params) => {
                        const inputProps = params.inputProps;
                        inputProps.autoComplete = "new-password";
                        return (
                          <TextField
                            {...params}
                            fullWidth
                            label="Canal de captación del cliente"
                            variant="standard"
                            error={!!errors.channel_catchment_client}
                            helperText={
                              errors.channel_catchment_client &&
                              errors.channel_catchment_client.message
                            }
                          />
                        );
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item md={4} sm={6} xs={12}>
                <Controller
                  name={"personal_phone"}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      fullWidth
                      size="small"
                      label="Teléfono"
                      type="text"
                      variant="standard"
                      value={value}
                      disabled={completeData}
                      autoComplete="personal_phone"
                      {...register("personal_phone")}
                      error={
                        errors.personal_phone && Boolean(errors.personal_phone)
                      }
                      helperText={
                        errors.personal_phone && errors.personal_phone.message
                      }
                    />
                  )}
                />
              </Grid>
            </Grid>
            <TitleForm variant="h6" sx={{ mt: 5, mb: 4 }}>
              Dirección oficina principal
            </TitleForm>
            <Grid container spacing={4}>
              <Grid item md={4} sm={6} xs={12}>
                <Controller
                  control={control}
                  name="country"
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      onChange={(event, newValue) => {
                        onChange(newValue);
                      }}
                      options={data && countryASC}
                      getOptionLabel={(option: any) => option.description || ""}
                      isOptionEqualToValue={(option: any, value: any) =>
                        option.id === value.id
                      }
                      value={value}
                      disabled={completeData}
                      renderInput={(params) => {
                        const inputProps = params.inputProps;
                        inputProps.autoComplete = "new-password";
                        return (
                          <TextField
                            {...params}
                            fullWidth
                            label="País"
                            variant="standard"
                            error={!!errors.country}
                            helperText={
                              errors.country && errors.country.message
                            }
                          />
                        );
                      }}
                    />
                  )}
                />
              </Grid>

              {watch_country?.id === 63 && (
                <>
                  <Grid item md={4} sm={6} xs={12}>
                    <Controller
                      control={control}
                      name="province"
                      rules={{ required: true }}
                      render={({ field: { onChange, value } }) => (
                        <Autocomplete
                          onChange={(event, newValue) => {
                            onChange(newValue);
                          }}
                          options={address.provinces}
                          getOptionLabel={(option: any) =>
                            option.description || ""
                          }
                          isOptionEqualToValue={(option: any, value: any) =>
                            option.provinceCode === value.provinceCode
                          }
                          value={value}
                          disabled={completeData}
                          renderInput={(params) => {
                            const inputProps = params.inputProps;
                            inputProps.autoComplete = "new-password";
                            return (
                              <TextField
                                {...params}
                                fullWidth
                                label="Provincia"
                                variant="standard"
                                error={!!errors.province}
                                helperText={
                                  !!errors.province && "Campo es requerido"
                                }
                              />
                            );
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item md={4} sm={6} xs={12}>
                    <Controller
                      control={control}
                      name="sector"
                      rules={{ required: true }}
                      render={({ field: { onChange, value } }) => (
                        <Autocomplete
                          onChange={(event, newValue) => {
                            onChange(newValue);
                          }}
                          options={address.sector.filter(
                            (m: any) =>
                              m.provinceCode === watch_province?.provinceCode
                          )}
                          getOptionLabel={(option: any) =>
                            option.description || ""
                          }
                          isOptionEqualToValue={(option: any, value: any) =>
                            option.id === value.id
                          }
                          value={value}
                          disabled={completeData}
                          renderInput={(params) => {
                            const inputProps = params.inputProps;
                            inputProps.autoComplete = "new-password";
                            return (
                              <TextField
                                {...params}
                                fullWidth
                                label="Sector"
                                variant="standard"
                                error={!!errors.sector}
                                helperText={
                                  !!errors.sector && "Campo es requerido"
                                }
                              />
                            );
                          }}
                        />
                      )}
                    />
                  </Grid>
                </>
              )}
              <Grid item md={4} sm={6} xs={12}>
                <Controller
                  name={"street_contact_person"}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      fullWidth
                      size="small"
                      label="Calle / Número"
                      type="text"
                      variant="standard"
                      value={value}
                      disabled={completeData}
                      autoComplete="street_contact_person"
                      {...register("street_contact_person")}
                      error={
                        errors.street_contact_person &&
                        Boolean(errors.street_contact_person)
                      }
                      helperText={
                        errors.street_contact_person &&
                        errors.street_contact_person.message
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item md={4} sm={6} xs={12}>
                <Controller
                  name={"building"}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      fullWidth
                      size="small"
                      label="Edificio / Torre"
                      type="text"
                      variant="standard"
                      value={value}
                      disabled={completeData}
                      autoComplete="building"
                      {...register("building")}
                      error={errors.building && Boolean(errors.building)}
                      helperText={errors.building && errors.building.message}
                    />
                  )}
                />
              </Grid>
            </Grid>
            <TitleForm variant="h6" sx={{ mt: 7, mb: 4 }}>
              Información representante legal o apoderados
            </TitleForm>
            {legal_representativeFields.map((field, index) => {
              return (
                <div key={index}>
                  {index !== 0 && (
                    <Divider
                      sx={{
                        pt: 3,
                        mb: 3,
                        borderColor: "#efe5df",
                        width: "50%",
                        ml: "auto",
                        mr: "auto",
                      }}
                    />
                  )}
                  <Grid container spacing={4} key={field.id} sx={{ mb: 2 }}>
                    <Grid item md={4} sm={6} xs={12}>
                      <Controller
                        name={`legal_representative.${index}.name`}
                        control={control}
                        render={() => (
                          <TextField
                            fullWidth
                            size="small"
                            label="Nombres y Apellidos"
                            type="text"
                            variant="standard"
                            disabled={completeData}
                            autoComplete="name"
                            {...register(`legal_representative.${index}.name`)}
                            error={
                              errors?.legal_representative?.[index]?.name &&
                              true
                            }
                            helperText={
                              errors?.legal_representative?.[index]?.name
                                ?.message
                            }
                          />
                        )}
                      />
                    </Grid>
                    <Grid item md={4} sm={6} xs={12}>
                      <Controller
                        control={control}
                        name={`legal_representative.${index}.id_type`}
                        rules={{ required: true }}
                        render={({ field: { onChange, value } }) => (
                          <Autocomplete
                            onChange={(event, newValue: any) => {
                              onChange(newValue);
                            }}
                            options={datos.pa_ta_id_type}
                            getOptionLabel={(option: any) =>
                              option.description || ""
                            }
                            isOptionEqualToValue={(option: any, value: any) =>
                              option.id === value.id
                            }
                            value={value}
                            disabled={completeData}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                fullWidth
                                label="Tipo de Identificación"
                                variant="standard"
                                error={
                                  errors?.legal_representative?.[index]
                                    ?.id_type && true
                                }
                                helperText={
                                  errors?.legal_representative?.[index]?.id_type
                                    ?.message
                                }
                              />
                            )}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item md={4} sm={6} xs={12}>
                      <Controller
                        name={`legal_representative.${index}.document_number`}
                        control={control}
                        render={() => (
                          <TextField
                            fullWidth
                            size="small"
                            label="Número Documento"
                            type="text"
                            variant="standard"
                            disabled={completeData}
                            autoComplete="document_number"
                            {...register(
                              `legal_representative.${index}.document_number`
                            )}
                            error={
                              errors?.legal_representative?.[index]
                                ?.document_number && true
                            }
                            helperText={
                              errors?.legal_representative?.[index]
                                ?.document_number?.message
                            }
                          />
                        )}
                      />
                    </Grid>
                    <Grid item md={4} sm={6} xs={12}>
                      <Controller
                        control={control}
                        name={`legal_representative.${index}.marital_status`}
                        rules={{ required: true }}
                        render={({ field: { onChange, value } }) => (
                          <Autocomplete
                            onChange={(event, newValue: any) => {
                              onChange(newValue);
                            }}
                            options={datos.pa_ta_marital_status}
                            getOptionLabel={(option: any) => option || ""}
                            isOptionEqualToValue={(option: any, value: any) =>
                              option === value
                            }
                            value={value}
                            disabled={completeData}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                fullWidth
                                label="Estado civil"
                                variant="standard"
                                error={
                                  errors?.legal_representative?.[index]
                                    ?.marital_status && true
                                }
                                helperText={
                                  errors?.legal_representative?.[index]
                                    ?.marital_status?.message
                                }
                              />
                            )}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item md={4} sm={6} xs={12}>
                      <Controller
                        control={control}
                        name={`legal_representative.${index}.country_of_nationality`}
                        rules={{ required: true }}
                        render={({ field: { onChange, value } }) => (
                          <Autocomplete
                            onChange={(event, newValue: any) => {
                              onChange(newValue);
                            }}
                            options={data && countryASC}
                            getOptionLabel={(option: any) =>
                              option.description || ""
                            }
                            isOptionEqualToValue={(option: any, value: any) =>
                              option.id === value.id
                            }
                            value={value}
                            disabled={completeData}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                fullWidth
                                label="País de nacionalidad"
                                variant="standard"
                                error={
                                  errors?.legal_representative?.[index]
                                    ?.country_of_nationality && true
                                }
                                helperText={
                                  errors?.legal_representative?.[index]
                                    ?.country_of_nationality?.message
                                }
                              />
                            )}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item md={4} sm={6} xs={12}>
                      <Controller
                        control={control}
                        name={`legal_representative.${index}.country`}
                        rules={{ required: true }}
                        render={({ field: { onChange, value } }) => (
                          <Autocomplete
                            onChange={(event, newValue: any) => {
                              onChange(newValue);
                            }}
                            options={data && countryASC}
                            getOptionLabel={(option: any) =>
                              option.description || ""
                            }
                            isOptionEqualToValue={(option: any, value: any) =>
                              option.id === value.id
                            }
                            value={value}
                            disabled={completeData}
                            renderInput={(params) => {
                              const inputProps = params.inputProps;
                              inputProps.autoComplete = "new-password";
                              return (
                                <TextField
                                  {...params}
                                  fullWidth
                                  label="País de Residencia"
                                  variant="standard"
                                  error={
                                    !!errors?.legal_representative?.[index]
                                      ?.country
                                  }
                                  helperText={
                                    errors?.legal_representative?.[index]
                                      ?.country?.message
                                  }
                                />
                              );
                            }}
                          />
                        )}
                      />
                    </Grid>

                    {
                      //@ts-ignore
                      Number(watch_legal_representative[index].country?.id) ===
                        63 && (
                        <>
                          <Grid item md={4} sm={6} xs={12}>
                            <Controller
                              control={control}
                              name={`legal_representative.${index}.province`}
                              rules={{ required: true }}
                              render={({ field: { onChange, value } }) => (
                                <Autocomplete
                                  onChange={(event, newValue: any) => {
                                    onChange(newValue);
                                  }}
                                  options={address.provinces}
                                  getOptionLabel={(option: any) =>
                                    option.description || ""
                                  }
                                  isOptionEqualToValue={(
                                    option: any,
                                    value: any
                                  ) =>
                                    option.provinceCode === value.provinceCode
                                  }
                                  value={value}
                                  disabled={completeData}
                                  renderInput={(params) => {
                                    const inputProps = params.inputProps;
                                    inputProps.autoComplete = "new-password";
                                    return (
                                      <TextField
                                        {...params}
                                        fullWidth
                                        label="Provincia"
                                        variant="standard"
                                        error={
                                          !!errors?.legal_representative?.[
                                            index
                                          ]?.province
                                        }
                                        helperText={
                                          errors?.legal_representative?.[index]
                                            ?.province && "Campo es requerido"
                                        }
                                      />
                                    );
                                  }}
                                />
                              )}
                            />
                          </Grid>
                          <Grid item md={4} sm={6} xs={12}>
                            <Controller
                              control={control}
                              name={`legal_representative.${index}.sector`}
                              rules={{ required: true }}
                              render={({ field: { onChange, value } }) => (
                                <Autocomplete
                                  onChange={(event, newValue: any) => {
                                    onChange(newValue);
                                  }}
                                  options={address.sector.filter(
                                    (m: any) =>
                                      m.provinceCode ===
                                      watch_legal_representative[index]
                                        ?.province?.provinceCode
                                  )}
                                  getOptionLabel={(option: any) =>
                                    option.description || ""
                                  }
                                  isOptionEqualToValue={(
                                    option: any,
                                    value: any
                                  ) => option.id === value.id}
                                  value={value}
                                  disabled={completeData}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      fullWidth
                                      label="Sector"
                                      variant="standard"
                                      error={
                                        !!errors?.legal_representative?.[index]
                                          ?.sector
                                      }
                                      helperText={
                                        !!errors?.legal_representative?.[index]
                                          ?.sector && "Campo es requerido"
                                      }
                                    />
                                  )}
                                />
                              )}
                            />
                          </Grid>
                        </>
                      )
                    }
                    <Grid item md={4} sm={6} xs={12}>
                      <Controller
                        name={`legal_representative.${index}.address`}
                        control={control}
                        render={() => (
                          <TextField
                            fullWidth
                            size="small"
                            label="Dirección (Calle /  Número)"
                            type="text"
                            variant="standard"
                            disabled={completeData}
                            autoComplete="address"
                            {...register(
                              `legal_representative.${index}.address`
                            )}
                            error={
                              !!errors?.legal_representative?.[index]?.address
                            }
                            helperText={
                              errors?.legal_representative?.[index]?.address
                                ?.message
                            }
                          />
                        )}
                      />
                    </Grid>
                    <Grid item md={4} sm={6} xs={12}>
                      <Controller
                        name={`legal_representative.${index}.building_name`}
                        control={control}
                        render={() => (
                          <TextField
                            fullWidth
                            size="small"
                            label="Nombre Edificio / Torre"
                            type="text"
                            variant="standard"
                            disabled={completeData}
                            autoComplete="building_name"
                            {...register(
                              `legal_representative.${index}.building_name`
                            )}
                            error={
                              !!errors?.legal_representative?.[index]
                                ?.building_name
                            }
                            helperText={
                              errors?.legal_representative?.[index]
                                ?.building_name?.message
                            }
                          />
                        )}
                      />
                    </Grid>
                    <Grid item md={4} sm={6} xs={12}>
                      <Controller
                        name={`legal_representative.${index}.floor_number`}
                        control={control}
                        render={() => (
                          <TextField
                            fullWidth
                            size="small"
                            label="N° Piso / Suite"
                            type="text"
                            variant="standard"
                            disabled={completeData}
                            autoComplete="floor_number"
                            {...register(
                              `legal_representative.${index}.floor_number`
                            )}
                            error={
                              !!errors?.legal_representative?.[index]
                                ?.floor_number
                            }
                            helperText={
                              errors?.legal_representative?.[index]
                                ?.floor_number?.message
                            }
                          />
                        )}
                      />
                    </Grid>
                    <Grid item md={4} sm={6} xs={12}>
                      <Controller
                        name={`legal_representative.${index}.postal_code`}
                        control={control}
                        render={() => (
                          <TextField
                            fullWidth
                            size="small"
                            label="Código postal"
                            type="text"
                            variant="standard"
                            disabled={completeData}
                            autoComplete="postal_code"
                            {...register(
                              `legal_representative.${index}.postal_code`
                            )}
                            error={
                              !!errors?.legal_representative?.[index]
                                ?.postal_code
                            }
                            helperText={
                              errors?.legal_representative?.[index]?.postal_code
                                ?.message
                            }
                          />
                        )}
                      />
                    </Grid>
                    <Grid item md={4} sm={6} xs={12} sx={{ display: "flex" }}>
                      <Controller
                        name={`legal_representative.${index}.email`}
                        control={control}
                        render={() => (
                          <TextField
                            fullWidth
                            size="small"
                            label="Correo electrónico"
                            type="email"
                            variant="standard"
                            disabled={completeData}
                            autoComplete="email"
                            {...register(`legal_representative.${index}.email`)}
                            error={
                              !!errors?.legal_representative?.[index]?.email
                            }
                            helperText={
                              errors?.legal_representative?.[index]?.email
                                ?.message
                            }
                          />
                        )}
                      />
                      {index !== 0 && !completeData && (
                        <IconButton
                          aria-label="delete"
                          color="primary"
                          onClick={() => {
                            legal_representativeRemove(index);
                          }}
                        >
                          <RemoveCircleIcon fontSize="medium" />
                        </IconButton>
                      )}
                    </Grid>
                  </Grid>
                </div>
              );
            })}
            {legal_representativeFields.length < 2 && !completeData && (
              <Grid
                container
                justifyContent="flex-end"
                alignItems="center"
                sx={{ mb: 2 }}
              >
                <Button
                  type="button"
                  variant="outlined"
                  onClick={() => {
                    legal_representativeAppend({
                      name: "",
                      id_type: null,
                      document_number: "",
                      marital_status: "",
                      country_of_nationality: null,
                      country: null,
                      province: null,
                      sector: null,
                      address: "",
                      building_name: "",
                      floor_number: "",
                      postal_code: "",
                      email: "",
                    });
                  }}
                >
                  <AddCircleIcon fontSize="small" sx={{ mr: 1 }} /> Agregar
                </Button>
              </Grid>
            )}
            <TitleForm variant="h6" sx={{ mt: 7, mb: 4 }}>
              Descripción del negocio
            </TitleForm>
            <Grid container spacing={4} sx={{ mb: 2 }}>
              <Grid item md={4} sm={6} xs={12}>
                <Controller
                  control={control}
                  name="sector_type"
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      onChange={(event, newValue) => {
                        onChange(newValue);
                      }}
                      options={datos.pa_ta_sector_type}
                      getOptionLabel={(option: any) => option || ""}
                      isOptionEqualToValue={(option: any, value: any) =>
                        option === value
                      }
                      value={value}
                      disabled={completeData}
                      renderInput={(params) => {
                        const inputProps = params.inputProps;
                        inputProps.autoComplete = "new-password3";
                        return (
                          <TextField
                            {...params}
                            fullWidth
                            label="Sector"
                            variant="standard"
                            error={!!errors.sector_type}
                            helperText={
                              errors.sector_type && errors.sector_type.message
                            }
                          />
                        );
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item md={4} sm={6} xs={12}>
                <Controller
                  control={control}
                  name="business_activity"
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      onChange={(event, newValue) => {
                        onChange(newValue);
                      }}
                      options={datos.pa_ta_business_activity}
                      getOptionLabel={(option: any) => option || ""}
                      isOptionEqualToValue={(option: any, value: any) =>
                        option === value
                      }
                      value={value}
                      disabled={completeData}
                      renderInput={(params) => {
                        const inputProps = params.inputProps;
                        inputProps.autoComplete = "new-password3";
                        return (
                          <TextField
                            {...params}
                            fullWidth
                            label="Actividad del negocio"
                            variant="standard"
                            error={!!errors.business_activity}
                            helperText={
                              errors.business_activity &&
                              errors.business_activity.message
                            }
                          />
                        );
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item md={4} sm={6} xs={12}>
                <Controller
                  name={"quantity_of_employees"}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      fullWidth
                      size="small"
                      label="Cantidad de empleados"
                      type="text"
                      variant="standard"
                      value={value}
                      disabled={completeData}
                      autoComplete="quantity_of_employees"
                      {...register("quantity_of_employees")}
                      error={
                        errors.quantity_of_employees &&
                        Boolean(errors.quantity_of_employees)
                      }
                      helperText={
                        errors.quantity_of_employees &&
                        errors.quantity_of_employees.message
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item md={4} sm={6} xs={12}>
                <Controller
                  name={"number_of_shareholders"}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      fullWidth
                      size="small"
                      label="Cantidad de accionistas"
                      type="text"
                      variant="standard"
                      value={value}
                      disabled={completeData}
                      autoComplete="number_of_shareholders"
                      {...register("number_of_shareholders")}
                      error={
                        errors.number_of_shareholders &&
                        Boolean(errors.number_of_shareholders)
                      }
                      helperText={
                        errors.number_of_shareholders &&
                        errors.number_of_shareholders.message
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item md={4} sm={6} xs={12}>
                <Controller
                  control={control}
                  name="annual_sales_volume"
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      onChange={(event, newValue) => {
                        onChange(newValue);
                      }}
                      options={datos.pa_ta_annual_sales_volume}
                      getOptionLabel={(option: any) => option || ""}
                      isOptionEqualToValue={(option: any, value: any) =>
                        option === value
                      }
                      value={value}
                      disabled={completeData}
                      renderInput={(params) => {
                        const inputProps = params.inputProps;
                        inputProps.autoComplete = "new-password3";
                        return (
                          <TextField
                            {...params}
                            fullWidth
                            label="Volumen de ventas anuales"
                            variant="standard"
                            error={!!errors.annual_sales_volume}
                            helperText={
                              errors.annual_sales_volume &&
                              errors.annual_sales_volume.message
                            }
                          />
                        );
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item md={4} sm={6} xs={12}>
                <Controller
                  name={"products_or_services"}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      fullWidth
                      size="small"
                      label="	Productos y/o servicios"
                      type="text"
                      variant="standard"
                      value={value}
                      disabled={completeData}
                      autoComplete="products_or_services"
                      {...register("products_or_services")}
                      error={
                        errors.products_or_services &&
                        Boolean(errors.products_or_services)
                      }
                      helperText={
                        errors.products_or_services &&
                        errors.products_or_services.message
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item md={4} sm={6} xs={12}>
                <Controller
                  control={control}
                  name="main_clients_and_countries"
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      onChange={(event, newValue) => {
                        onChange(newValue);
                      }}
                      options={data && countryASC}
                      getOptionLabel={(option: any) => option.description || ""}
                      isOptionEqualToValue={(option: any, value: any) =>
                        option.id === value.id
                      }
                      value={value}
                      disabled={completeData}
                      renderInput={(params) => {
                        const inputProps = params.inputProps;
                        inputProps.autoComplete = "new-password";
                        return (
                          <TextField
                            {...params}
                            fullWidth
                            label="Principales clientes y países"
                            variant="standard"
                            error={!!errors.main_clients_and_countries}
                            helperText={
                              errors.main_clients_and_countries &&
                              errors.main_clients_and_countries.message
                            }
                          />
                        );
                      }}
                    />
                  )}
                />
              </Grid>
            </Grid>
            {loadingSendData && (
              <Stack spacing={2} direction="row" sx={{ mt: 1 }}>
                <CircularProgress
                  size={24}
                  sx={{
                    margin: "5px auto",
                    color: `${theme.palette.primary.main}`,
                  }}
                />
              </Stack>
            )}
            <Stack
              justifyContent="flex-end"
              alignItems="flex-end"
              sx={{ mt: 1 }}
            >
              {completeData ? (
                <ButtonGradient
                  type="button"
                  onClick={handleNext}
                  sx={{ width: { xs: "100%", sm: "auto" } }}
                >
                  Continuar
                </ButtonGradient>
              ) : (
                <ButtonGradient
                  type="submit"
                  sx={{ width: { xs: "100%", sm: "auto" } }}
                >
                  Continuar
                </ButtonGradient>
              )}
            </Stack>
          </>
        </>
      )}
    </form>
  );
};

export default BussinessIdentification;
