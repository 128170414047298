import { FC, useState, useEffect } from "react";
import useLegalForm from "../../hooks/useLegalForm";
import useProfile from "../../hooks/useProfile";
import {
  Grid,
  TextField,
  Autocomplete,
  Stack,
  Divider,
  IconButton,
  Button,
  Tooltip,
  Typography,
  FormHelperText,
  CircularProgress,
  useTheme,
} from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import TitleForm from "../../components/TitleForm";
import ButtonGradient from "../../components/ButtonGradient";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import DateFnsUtils from "@date-io/date-fns";
import esLocale from "date-fns/locale/es";
import { validationAdditionalLegalInformation } from "./validation";
import { AdditionalLegalInformationType } from "../../types/types";
import { data as datos } from "../../data/data";
import { updateProfile } from "../../services/Services";

const AdditionalLegalInformation: FC = () => {
  const theme = useTheme();
  const [loadingSendData, setLoadingSendData] = useState(false);
  const { dataForm, completeData, data, dataJ, address } = useProfile();
  let localData2 = localStorage.getItem("stepTwo");
  const {
    setActiveStep,
    step2,
    setStep2,
    step2Answered,
    handleBack,
    handleNext,
  } = useLegalForm();

  let findData: any = null;
  if (dataForm !== null) {
    findData = dataForm;
  } else if (typeof localData2 === "string") {
    findData = JSON.parse(localData2);
  } else {
    findData = step2;
  }

  /* if (dataForm !== null) {
    findData = dataForm;
  } else if (localData2 && localData2 !== "") {
    findData = JSON.parse(localData2);
  }  else {
    findData = step2;
  } */

  const countryASC =
    data && //@ts-ignore
    data.pa_ta_countries.sort((a: any, b: any) => {
      if (a.description < b.description) {
        return -1;
      }
    });

  const defaultValues: AdditionalLegalInformationType = {
    main_suppliers_entity: (findData.main_suppliers_entity &&
      JSON.parse(findData.main_suppliers_entity)) ?? [
      {
        social_name: "",
        country_of_origin: null,
      },
    ],
    information_shareholders: (findData.information_shareholders &&
      JSON.parse(findData.information_shareholders)) ?? [
      {
        full_name: "",
        identification_number: "",
      },
    ],
    affiliated_companies: (findData.affiliated_companies &&
      JSON.parse(findData.affiliated_companies)) ?? [
      {
        name_or_social_name: "",
        rnc_or_equivalent: "",
      },
    ],
    commercial_references: (findData.commercial_references &&
      JSON.parse(findData.commercial_references)) ?? [
      {
        commercial_phone: "",
        commercial_name: "",
      },
    ],
  };

  const {
    register,
    handleSubmit,
    resetField,
    formState,
    control,
    getValues,
    watch,
    reset,
  } = useForm<AdditionalLegalInformationType>({
    defaultValues,
    resolver: yupResolver(validationAdditionalLegalInformation),
  });
  const { errors, isDirty, dirtyFields } = formState;

  const watch_main_suppliers_entity: any = watch("main_suppliers_entity");

  const {
    fields: main_suppliers_entityFields,
    append: main_suppliers_entityAppend,
    remove: main_suppliers_entityRemove,
  } = useFieldArray({
    control,
    name: "main_suppliers_entity",
    rules: {
      minLength: 3,
    },
  });
  const {
    fields: information_shareholdersFields,
    append: information_shareholdersAppend,
    remove: information_shareholdersRemove,
  } = useFieldArray({
    control,
    name: "information_shareholders",
    rules: {
      minLength: 3,
    },
  });
  const {
    fields: affiliated_companiesFields,
    append: affiliated_companiesAppend,
    remove: affiliated_companiesRemove,
  } = useFieldArray({
    control,
    name: "affiliated_companies",
    rules: {
      minLength: 3,
    },
  });
  const {
    fields: commercial_referencesFields,
    append: commercial_referencesAppend,
    remove: commercial_referencesRemove,
  } = useFieldArray({
    control,
    name: "commercial_references",
    rules: {
      minLength: 3,
    },
  });

  const miarray: any = [];

  useEffect(() => {
    reset(defaultValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataForm]);

  const onSubmit = async (data: any) => {
    let localForm: any = localStorage.getItem("formData");
    setLoadingSendData(true);
    let key: string | any;
    let value: { id: string; description: string }[] | any = [];

    for ([key, value] of Object.entries(data)) {
      miarray.push({ [key]: value });
      if (!value) {
        delete data[key];
      } else if (typeof value == "object") {
        data[key] =
          value !== null && value.id ? value.id : JSON.stringify(value);
      }
    }
    data.step_two_complete = "true";

    let update = {};
    for ([key, value] of Object.entries(dirtyFields)) {
      value = getValues(key);
      if (typeof value == "object") {
        Object.assign(update, {
          [key]: JSON.stringify(value !== null && value.id ? value.id : value),
        });
      } else {
        Object.assign(update, { [key]: value });
      }
    }

    setStep2 && setStep2(data);
    localStorage.setItem("stepTwo", JSON.stringify(data));
    setLoadingSendData(false);
    setActiveStep && setActiveStep((prevActiveStep) => prevActiveStep + 1);

    const handleResponse = () => {
      setStep2 && setStep2(data);
      setLoadingSendData(false);
      setActiveStep && setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    /* if (findData.step_three_complete !== "true") {
      let fullData = {
        fields: {
          ...data,
        },
        formType: "J",
        userId: localStorage.getItem("userId"),
        stepForm: "Información Adicional",
      };
      localStorage.setItem("stepForm", "Información Adicional");

      const saveData = await updateProfile(fullData);
      if (saveData.status === 200) {
        handleResponse();
      }
    } else {
      if (isDirty) {
        let fullUpData = {
          fields: {
            ...update,
          },
          formType: "J",
          userId: localStorage.getItem("userId"),
          stepForm: localStorage.getItem("stepForm"),
        };
        const updateData = await updateProfile(fullUpData);
        if (updateData.status === 200) {
          handleResponse();
        }
      } else {
        setLoadingSendData(false);
        setActiveStep && setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    } */
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <TitleForm>Datos de Accionistas, Proveedores y Socios </TitleForm>
      <TitleForm variant="h6" sx={{ mt: 5, mb: 4 }}>
        Principales proveedores
      </TitleForm>
      {main_suppliers_entityFields.map((field, index) => {
        return (
          <div key={index}>
            {index !== 0 && (
              <Divider
                sx={{
                  pt: 3,
                  mb: 3,
                  borderColor: "#efe5df",
                  width: "50%",
                  ml: "auto",
                  mr: "auto",
                }}
              />
            )}
            <Grid container spacing={4} key={field.id} sx={{ mb: 2 }}>
              <Grid item sm={6} xs={12}>
                <Controller
                  name={`main_suppliers_entity.${index}.social_name`}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      fullWidth
                      size="small"
                      label="Nombres / Denominación social"
                      type="text"
                      variant="standard"
                      disabled={completeData}
                      {...register(
                        `main_suppliers_entity.${index}.social_name` as const
                      )}
                      error={
                        errors?.main_suppliers_entity?.[index]?.social_name &&
                        true
                      }
                      helperText={
                        errors?.main_suppliers_entity?.[index]?.social_name
                          ?.message
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item sm={6} xs={12} sx={{ display: "flex" }}>
                <Controller
                  control={control}
                  name={`main_suppliers_entity.${index}.country_of_origin`}
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      fullWidth
                      onChange={(event, newValue) => {
                        onChange(newValue);
                      }}
                      options={data && countryASC}
                      getOptionLabel={(option: any) => option.description || ""}
                      isOptionEqualToValue={(option: any, value: any) =>
                        option.id === value.id
                      }
                      value={value}
                      disabled={completeData}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          label="País de procedencia"
                          variant="standard"
                          error={
                            errors?.main_suppliers_entity?.[index]
                              ?.country_of_origin && true
                          }
                          helperText={
                            errors?.main_suppliers_entity?.[index]
                              ?.country_of_origin?.message
                          }
                        />
                      )}
                    />
                  )}
                />
                {index !== 0 && !completeData && (
                  <IconButton
                    aria-label="delete"
                    color="primary"
                    onClick={() => {
                      main_suppliers_entityRemove(index);
                    }}
                  >
                    <RemoveCircleIcon fontSize="medium" />
                  </IconButton>
                )}
              </Grid>
            </Grid>
          </div>
        );
      })}
      {main_suppliers_entityFields.length < 4 && !completeData && (
        <Grid
          container
          justifyContent="flex-end"
          alignItems="center"
          sx={{ mb: 2 }}
        >
          <Button
            type="button"
            variant="outlined"
            onClick={() => {
              main_suppliers_entityAppend({
                social_name: "",
                country_of_origin: null,
              });
            }}
          >
            <AddCircleIcon fontSize="small" sx={{ mr: 1 }} /> Agregar
          </Button>
        </Grid>
      )}
      <TitleForm variant="h6" sx={{ mt: 5, mb: 4 }}>
        Principales Accionistas
      </TitleForm>
      <Typography variant="body2" sx={{ mt: 5, mb: 3 }}>
        * Que tengan 5% o más de las acciones
      </Typography>
      {information_shareholdersFields.map((field, index) => {
        return (
          <div key={index}>
            {index !== 0 && (
              <Divider
                sx={{
                  pt: 3,
                  mb: 3,
                  borderColor: "#efe5df",
                  width: "50%",
                  ml: "auto",
                  mr: "auto",
                }}
              />
            )}
            <Grid container spacing={4} key={field.id} sx={{ mb: 2 }}>
              <Grid item sm={6} xs={12}>
                <Controller
                  name={`information_shareholders.${index}.full_name`}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      fullWidth
                      size="small"
                      label="Nombre completo"
                      type="text"
                      variant="standard"
                      disabled={completeData}
                      {...register(
                        `information_shareholders.${index}.full_name` as const
                      )}
                      error={
                        errors?.information_shareholders?.[index]?.full_name &&
                        true
                      }
                      helperText={
                        errors?.information_shareholders?.[index]?.full_name
                          ?.message
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item sm={6} xs={12} sx={{ display: "flex" }}>
                <Controller
                  name={`information_shareholders.${index}.identification_number`}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      fullWidth
                      size="small"
                      label="Documento de identidad"
                      type="text"
                      variant="standard"
                      disabled={completeData}
                      {...register(
                        `information_shareholders.${index}.identification_number` as const
                      )}
                      error={
                        errors?.information_shareholders?.[index]
                          ?.identification_number && true
                      }
                      helperText={
                        errors?.information_shareholders?.[index]
                          ?.identification_number?.message
                      }
                    />
                  )}
                />
                {index !== 0 && !completeData && (
                  <IconButton
                    aria-label="delete"
                    color="primary"
                    onClick={() => {
                      information_shareholdersRemove(index);
                    }}
                  >
                    <RemoveCircleIcon fontSize="medium" />
                  </IconButton>
                )}
              </Grid>
            </Grid>
          </div>
        );
      })}
      {information_shareholdersFields.length < 5 && !completeData && (
        <Grid
          container
          justifyContent="flex-end"
          alignItems="center"
          sx={{ mb: 2 }}
        >
          <Button
            type="button"
            variant="outlined"
            onClick={() => {
              information_shareholdersAppend({
                full_name: "",
                identification_number: "",
              });
            }}
          >
            <AddCircleIcon fontSize="small" sx={{ mr: 1 }} /> Agregar
          </Button>
        </Grid>
      )}
      <TitleForm variant="h6" sx={{ mt: 5, mb: 4 }}>
        Empresas afiliadas, subsidiarias y compañias relacionadas
      </TitleForm>
      {affiliated_companiesFields.map((field, index) => {
        return (
          <div key={index}>
            {index !== 0 && (
              <Divider
                sx={{
                  pt: 3,
                  mb: 3,
                  borderColor: "#efe5df",
                  width: "50%",
                  ml: "auto",
                  mr: "auto",
                }}
              />
            )}
            <Grid container spacing={4} key={field.id} sx={{ mb: 2 }}>
              <Grid item sm={6} xs={12}>
                <Controller
                  name={`affiliated_companies.${index}.name_or_social_name`}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      fullWidth
                      size="small"
                      label="Nombre / razón social"
                      type="text"
                      variant="standard"
                      disabled={completeData}
                      {...register(
                        `affiliated_companies.${index}.name_or_social_name` as const
                      )}
                      error={
                        errors?.affiliated_companies?.[index]
                          ?.name_or_social_name && true
                      }
                      helperText={
                        errors?.affiliated_companies?.[index]
                          ?.name_or_social_name?.message
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item sm={6} xs={12} sx={{ display: "flex" }}>
                <Controller
                  name={`affiliated_companies.${index}.rnc_or_equivalent`}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      fullWidth
                      size="small"
                      label="RNC o equivalente"
                      type="text"
                      variant="standard"
                      disabled={completeData}
                      {...register(
                        `affiliated_companies.${index}.rnc_or_equivalent` as const
                      )}
                      error={
                        errors?.affiliated_companies?.[index]
                          ?.rnc_or_equivalent && true
                      }
                      helperText={
                        errors?.affiliated_companies?.[index]?.rnc_or_equivalent
                          ?.message
                      }
                    />
                  )}
                />
                {index !== 0 && !completeData && (
                  <IconButton
                    aria-label="delete"
                    color="primary"
                    onClick={() => {
                      affiliated_companiesRemove(index);
                    }}
                  >
                    <RemoveCircleIcon fontSize="medium" />
                  </IconButton>
                )}
              </Grid>
            </Grid>
          </div>
        );
      })}
      {affiliated_companiesFields.length < 5 && !completeData && (
        <Grid
          container
          justifyContent="flex-end"
          alignItems="center"
          sx={{ mb: 2 }}
        >
          <Button
            type="button"
            variant="outlined"
            onClick={() => {
              affiliated_companiesAppend({
                name_or_social_name: "",
                rnc_or_equivalent: "",
              });
            }}
          >
            <AddCircleIcon fontSize="small" sx={{ mr: 1 }} /> Agregar
          </Button>
        </Grid>
      )}
      <TitleForm variant="h6" sx={{ mt: 5, mb: 4 }}>
        Referencias comerciales
      </TitleForm>
      {commercial_referencesFields.map((field, index) => {
        return (
          <div key={index}>
            {index !== 0 && (
              <Divider
                sx={{
                  pt: 3,
                  mb: 3,
                  borderColor: "#efe5df",
                  width: "50%",
                  ml: "auto",
                  mr: "auto",
                }}
              />
            )}
            <Grid container spacing={4} key={field.id} sx={{ mb: 2 }}>
              <Grid item sm={6} xs={12}>
                <Controller
                  name={`commercial_references.${index}.commercial_phone`}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      fullWidth
                      size="small"
                      label="Teléfono"
                      type="text"
                      variant="standard"
                      disabled={completeData}
                      {...register(
                        `commercial_references.${index}.commercial_phone` as const
                      )}
                      error={
                        errors?.commercial_references?.[index]
                          ?.commercial_phone && true
                      }
                      helperText={
                        errors?.commercial_references?.[index]?.commercial_phone
                          ?.message
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item sm={6} xs={12} sx={{ display: "flex" }}>
                <Controller
                  name={`commercial_references.${index}.commercial_name`}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      fullWidth
                      size="small"
                      label="Nombre"
                      type="text"
                      variant="standard"
                      disabled={completeData}
                      {...register(
                        `commercial_references.${index}.commercial_name` as const
                      )}
                      error={
                        errors?.commercial_references?.[index]
                          ?.commercial_name && true
                      }
                      helperText={
                        errors?.commercial_references?.[index]?.commercial_name
                          ?.message
                      }
                    />
                  )}
                />
                {index !== 0 && !completeData && (
                  <IconButton
                    aria-label="delete"
                    color="primary"
                    onClick={() => {
                      commercial_referencesRemove(index);
                    }}
                  >
                    <RemoveCircleIcon fontSize="medium" />
                  </IconButton>
                )}
              </Grid>
            </Grid>
          </div>
        );
      })}
      {commercial_referencesFields.length < 5 && !completeData && (
        <Grid
          container
          justifyContent="flex-end"
          alignItems="center"
          sx={{ mb: 2 }}
        >
          <Button
            type="button"
            variant="outlined"
            onClick={() => {
              commercial_referencesAppend({
                commercial_phone: "",
                commercial_name: "",
              });
            }}
          >
            <AddCircleIcon fontSize="small" sx={{ mr: 1 }} /> Agregar
          </Button>
        </Grid>
      )}

      {loadingSendData && (
        <Stack spacing={2} direction="row" sx={{ mt: 1 }}>
          <CircularProgress
            size={24}
            sx={{ margin: "5px auto", color: `${theme.palette.primary.main}` }}
          />
        </Stack>
      )}
      <Stack
        justifyContent="flex-end"
        alignItems="flex-end"
        direction="row"
        spacing={2}
        sx={{ mt: 2 }}
      >
        {completeData ? (
          <>
            <Button
              type="button"
              variant="outlined"
              onClick={handleBack}
              sx={{ width: { xs: "100%", sm: "auto" } }}
            >
              Regresar
            </Button>
            <ButtonGradient
              type="button"
              onClick={handleNext}
              sx={{ width: { xs: "100%", sm: "auto" } }}
            >
              Continuar
            </ButtonGradient>
          </>
        ) : (
          <>
            <Tooltip
              disableFocusListener
              disableTouchListener
              title={
                step2Answered === false
                  ? "Perderas la información no guardada"
                  : ""
              }
              placement="top"
            >
              <Button
                type="button"
                variant="outlined"
                onClick={handleBack}
                sx={{ width: { xs: "100%", sm: "auto" } }}
              >
                Regresar
              </Button>
            </Tooltip>
            <ButtonGradient
              type="submit"
              sx={{ width: { xs: "100%", sm: "auto" } }}
            >
              Continuar
            </ButtonGradient>
          </>
        )}
      </Stack>
    </form>
  );
};

export default AdditionalLegalInformation;
