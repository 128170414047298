import localforage from "localforage";
import { jwtDecode } from "jwt-decode";
import { SVAPPloginRequest } from "../services/SecurityService";

export const setAuthData = async (keyName:string, authData:any) => {
  try {
    await setItem(keyName, authData)
    const value:any = await getItem(keyName)
    return JSON.parse(value)
  } catch (err) {
    return authData
  }
}

export const getAuthData = async () => {
  let authData:any = null
  try {
    authData = await getItem("authProfileInfo")
    //validate authData
    if (authData) {
      const decodeToken = jwtDecode(authData?.access_token)
      const expiresAt:any = decodeToken?.exp
      let valid = new Date().getTime() / 1000 < expiresAt
      if (valid) {
        return authData
      }
    }
    //obtener token
    let tokenData = await SVAPPloginRequest()
    //set token in localstorage
    authData = await setAuthData("authProfileInfo", tokenData)
  } catch (error) {
    console.log(error)
  }
  return authData
}

export const setItem = async (key:string, value:any) => {
  await localforage.setItem(key, value).catch((error) => {
    throw new Error("Error en almacenamiento de informacion")
  })
}

export const getItem = async (key:string) => {
  return await localforage.getItem(key).catch((error) => {
    throw new Error("Error en recuperacion de informacion")
  })
}

export const removeItem = async (key:string) => {
  await localforage.removeItem(key).catch((error) => {
    throw new Error("Error en eliminar la informacion")
  })
}

