export const data:any = {
  pa_ta_investment_promoter: [
    {
      id: 1,
      description: "Naomi Di Coco"
    },
    {
      id: 2,
      description: "Yli Sosa"
    },
    {
      id: 3,
      description: "Nathalie Machado"
    }
  ],
  pa_ta_id_type: [
    { id: "1", description: "Cédula Identidad" },
    { id: "2", description: "Pasaporte" },
  ],
  pa_ta_relationship_with_parval: ["Vinculado", "Comercial", "Familiar"],
  pa_ta_sector_type: ["Público", "Privado", "Mixto"],
  pa_ta_business_activity: [
    "Administración pública",
    "Agrícola",
    "Comercial",
    "Construcción",
    "Educativa",
    "Energética",
    "Financiera",
    "Forestal",
    "Ganadera",
    "Industrial",
    "Minera",
    "Pesquera",
    "Recreación",
    "Salud",
    "Servicios",
    "Telecomunicaciones",
    "Transporte",
    "Turismo"
  ],
  pa_ta_annual_sales_volume: [
    "Menor a RD$ 5,000,000.00 ",
    "De RD$ 5,000,001.00 a RD$ 20,000,000.00",
    "De RD$ 20,000,001.00 a RD$ 50,000,000.00 ",
    "Mayor a RD$ 50,000,000.00 ",
  ],
  pa_ta_level_experience_and_knowledge_as_investor: [
    "Ninguna",
    "Limitada",
    "Buena",
    "Excelente"
  ],
  pa_ta_accounts_payable: [
    "Menor a RD$ 5,000,000.00",
    "De RD$ 5,000,001.00 a RD$ 20,000,000.00",
    "Mayor a RD$ 20,000,000.00 "
  ],
  pa_ta_emergency_fund_for_expenses: [
    "Sí, mayor a seis meses de los gastos.",
    "Sí, máximo seis meses de los gastos.",
    "No, no cubriría los gastos del próximo mes."
  ],
  pa_ta_investment_purpose: [
    "Conservar el valor de ahorro",
    "Obtener ingresos a corto/mediano plazo",
    "Invertir a largo plazo",
    "Ingresos por operaciones compra/venta",
    "Otro"
  ],
  pa_ta_main_sources_funds: [
    "Capital de trabajo",
    "Venta de activos",
    "Inversiones anteriores",
    "Operaciones de tesorería",
    "Actividades propias del negocio",
    "Vencimientos de inversión",
    "Ingresos extraordinarios",
    "Otros"
  ],

  pa_ta_main_sources_funds2: [
    {
        "id": 1,
        "description": "Capital de trabajo"
    },
    {
        "id": 2,
        "description": "Venta de activos"
    },
    {
        "id": 3,
        "description": "Inversiones anteriores"
    },
    {
        "id": 4,
        "description": "Operaciones de tesorería"
    },
    {
        "id": 5,
        "description": "Actividades propias del negocio"
    },
    {
        "id": 6,
        "description": "Vencimientos de inversión"
    },
    {
        "id": 7,
        "description": "Ingresos extraordinarios"
    },
    {
        "id": 8,
        "description": "Otros"
    }
],

  pa_ta_operations_values_finance: [
    "Bonos BCRD/Hacienda",
    "Fondos Abiertos y cerrados",
    "Operaciones a Plazo (Forwards)",
    "Préstamos de Títulos Valores (Mutuo)",
    "Acciones",
    "Contratos de Reportes (Repos)",
    "Bonos Corporativos /Papeles Comerciales",
    "Derivados de Cobertura (No especulativos)",
    "Préstamo de margen"
  ],
  pa_ta_yes_or_no: [
    "Sí", "No"
  ],
  pa_ta_marital_status: ["Soltero/a", "Casado/a", "Unión Libre", "Divorciado", "Viudo/a"
  ],
  pa_ta_securities_and_financial_operations: [
    "Bonos BCRD / Hacienda",
    "Fondos Abiertos y Cerrados",
    "Operaciones a Plazo (Forwards)",
    "Préstamos de Títulos Valores (Mutuo)",
    "Acciones",
    "Contratos de Reportos (Repos)",
    "Papeles Comerciales",
    "Derivados de Cobertura (No especulativos)",
    "Préstamos de Margen"
  ],
  pa_ta_account_type: ["Ahorro", "Corriente"],
  Age: [
    { id: "1", description: "Mayor de 55 años " },
    { id: "2", description: "Entre 35 y 55 años" },
    { id: "3", description: "Menor de 35 años" },
  ],


  company_sector: [
    { id: "1", description: "Construcción" },
    { id: "2", description: "Industrial" },
    { id: "3", description: "Transporte" },
    { id: "4", description: "Servicios financieros" },
    { id: "5", description: "Seguros" },
    { id: "6", description: "Comercial" },
    { id: "7", description: "Turismo" },
    { id: "8", description: "Agroindustria" },
    { id: "9", description: "Salud" },
    { id: "10", description: "Otro" },
  ],
  economic_sector: [
    "Sector comercial",
    "Sector industrial",
    "Sector servicios",
    "Sector productivo",
    "Aseguradora",
    "Fiduciaria",
    "Administradora de fondos de pensión",
    "Intermediación financiera",
    "Sociedad administradora de fondos de inversión",
    "Cooperativas",
    "Intermediación de valores"
  ],

  economic_activity: [
    { id: "1", description: "Agricultura" },
    { id: "2", description: "Combustible" },
    { id: "3", description: "Comercio" },
    { id: "4", description: "Construcción" },
    { id: "5", description: "Educación" },
    { id: "6", description: "Farmacéutico" },
    { id: "7", description: "Financiero" },
    { id: "8", description: "Industria" },
    { id: "9", description: "Legal" },
    { id: "10", description: "Transporte y Logística" },
    { id: "11", description: "Salud" },
    { id: "12", description: "Tecnología" },
    { id: "13", description: "Turismo" },
    { id: "14", description: "Zonas Francas" },
    { id: "15", description: "Otros ¿Cuáles?" },
  ],

  levelknowInstRisk: [
    { id: "1", description: "Básico" },
    { id: "2", description: "Intermedio" },
    { id: "3", description: "Avanzado" },
  ],
  fixedIncomeSecurities: [
    { id: "1", description: "Bonos Deuda Pública DOP" },
    { id: "2", description: "Bonos Deuda Pública Externa" },
    { id: "3", description: "Bonos Corporativos" },
    { id: "4", description: "Papeles Comerciales" },
    { id: "5", description: "Contrato Prestamo Titulos Valores" },
    { id: "6", description: "Operaciones a Plazo(Forward)" },
  ],
  investmentFunds: [
    { id: "7", description: "Fondos Mutuos o Abiertos" },
    { id: "8", description: "Fondos Cerrados" },
  ],
  liquidityOperations: [
    { id: "9", description: "Repos DOP" },
    { id: "10", description: "Repos USD" },
    { id: "11", description: "Financiamiento de Margen" },
    { id: "4", description: "Mutuo Estructurado" },
  ],
  variableIncomeSecurities: [
    { id: "1", description: "Acciones" },
    { id: "2", description: "Mutuo de Acciones" },
  ],
  otherServices: [
    { id: "1", description: "Servicio de administración de cartera" },
  ],
  entity: [
    { id: "1", description: "Banreservas" },
    { id: "2", description: "Banco Popular" },
    { id: "2", description: "Banco BHD" },
    { id: "4", description: "Scotiabank"},
    { id: "5", description: "Banco Santa Cruz" },
    { id: "6", description: "Banco Promerica" },
    { id: "7", description: "Banesco" },
    { id: "8", description: "Banco Caribe"},
    { id: "9", description: "Citibank" },
    { id: "10", description: "Banco BDI" },
    { id: "11", description: "Banco López de Haro" },
    { id: "12", description: "Banco Ademi"}
  ],
  selectBoolean: [
    { id: "1", description: "Sí" },
    { id: "2", description: "No" },
  ],
  selectBooleanString: [
    { id: "Sí", description: "Sí" },
    { id: "No", description: "No" },
  ],
  degrees_of_consanguinity: ["1° grado - (Padre, madre, hijos)", "2° grado - (Abuelos, nietos, hermanos)" , "3° grado - (Bisabuelos, tios, sobrinos)"],
  /* degrees_of_consanguinity: [
    { id: "1", description: "1° grado - (Padre, madre, hijos)" },
    { id: "2", description: "2° grado - (Abuelos, nietos, hermanos)" },
    { id: "3", description: "3° grado - (Bisabuelos, tios, sobrinos)" },
  ], */
  pa_ta_company_type: [
    "Nacional", "Extranjera"
  ],
  id_type_legal: [
    { id: "1", description: "RNC" },
    { id: "2", description: "Identificación fiscal extranjera" },
  ],
  type_of_legal_entity: [
    { id: "1", description: "S.R.L." },
    { id: "2", description: "S.A." },
    { id: "3", description: "S.A.S" },
    { id: "4", description: "E.I.R.L " },
    { id: "5", description: "Asociación sin fines de lucro" },
    { id: "6", description: "Otra" },
  ],
  non_financial_activities: [
    { id: "1", description: "Sector construcción" },
    { id: "2", description: "Sector industrial" },
    { id: "3", description: "Sector servicios" },
    { id: "4", description: "Sector comercial" },
    { id: "5", description: "Sector productivo" },
    { id: "6", description: "Otros" },
  ],
  financial_activities: [
    { id: "1", description: "Aseguradora" },
    { id: "2", description: "Fiduciaria" },
    { id: "3", description: "Administradora de fondos pensiones" },
    { id: "4", description: "Intermediación financiera" },
    { id: "5", description: "Intermediación de valores" },
    { id: "6", description: "Sociedad administradora de fondos de inversión" },
    { id: "7", description: "Cooperativas" },
    { id: "9", description: "Otros" },
  ],
  physically_address: [
    { id: "1", description: "Empresa" },
    { id: "2", description: "Otra, detallar" },
  ],
  /* total_employees: [
    { id: "1", description: "Entre 1 y 10" },
    { id: "2", description: "Entre 11 y 50" },
    { id: "3", description: "Entre 51 y 200" },
    { id: "4", description: "Más de 201" },
  ], */
  total_employees: [
    "Entre 1 y 10", "Entre 11 y 50", "Entre 51 y 200", "Más de 201"
  ],
  pa_ta_ownership_percentages: [
    { id: 2, description: "Menor a un 10%" },
    { id: 3, description: "Entre 10% y 30%" },
    { id: 4, description: "Entre 30% y 50%" },
    { id: 5, description: "Más de un 50%" },
  ],
  pa_ta_supplier_related_countries: [
    { id: 1, description: "Países No Cooperantes en Matería de Lavado" },
    { id: 2, description: "Países Cooperantes en Matería de Lavado" },
    { id: 3, description: "Países / Entidades sancionados o relacionados a Lavado de Activos; Corrupción; etc" },
    { id: 4, description: "No Aplica" },
  ],
  operations_foreing_currency: [
    "Importación",
    "Exportación",
    "Donaciones",
    "Transferencias",
    "Servicios",
    "Inversiones en el exterior",
    "Otra"
  ],
  foreing_currency: [
    {"id": 1, "description": "Dolar Estadounidense (USD)"},
    {"id": 2, "description": "Euro"},
    {"id": 3, "description": "Otra"}
  ],
  pa_ta_sources_funds: [
    { id: 1, description: "Ahorros Acumulados" },
    { id: 2, description: "Salario Actual" },
    { id: 3, description: "Venta de Activos" },
    { id: 4, description: "Herencia" },
    { id: 5, description: "Negocio Propio" },
    { id: 6, description: "Pensión o Retiro" },
    { id: 7, description: "Inversiones anteriores" },
    { id: 8, description: "Otros ¿Cuál?" },
  ],
  pa_ta_signature_card: [
    "Individual", "Conjunta"
  ],

}
