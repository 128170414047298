import { useNavigate } from "react-router-dom";
import { Box, Button, Typography, styled } from "@mui/material";
import Logo from "../resources/images/logopv-white.svg";
import useLegalForm from "../hooks/useLegalForm";
import useProfile from "../hooks/useProfile";

const WrappBox = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "stretch",
  width: "100%",
  maxWidth: "1440px",
  margin: "0 auto",
}));

const WrappContent = styled("div")(({ theme }) => ({
  borderLeft: `1px solid ${theme.palette.primary.main}`,
  paddingLeft: "15px",
  paddingTop: "5px",
  paddingBottom: "10px",
}));

const Content = styled("div")(({ theme }) => ({
  width: "50%",
  backgroundColor: "#326267",
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-start",
  alignItems: "center",
  position: "relative",
  overflow: "hidden",
  padding: `${theme.spacing(2)} ${theme.spacing(2)}`,
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}));

const Span = styled("span")(({ theme }) => ({
  color: `${theme.palette.primary.main}`,
}));

const BgImage = styled("div")(({ theme }) => ({
  position: "absolute",
  zIndex: "1",
  height: "100%",
  right: "0",
  backgroundSize: "cover",
  backgroundPosition: "center right",
  backgroundRepeat: "no-repeat",
  width: "50%",
  overflow: "hidden",
  "&:before": {
    content: "''",
    position: "absolute",
    width: "100%",
    height: "100%",
    backgroundColor: "#326267",
    opacity: "0.9",
  },
  "&:after": {
    content: "''",
    position: "absolute",
    width: "30vw",
    height: "25vw",
    background: "#fff",
    borderRadius: "100%",
    right: "-15%",
    top: "-100%",
    opacity: "0.2",
  },
  [theme.breakpoints.down("sm")]: {
    display: "none",
  },
}));

const Image = styled("img")(({ theme }) => ({
  zIndex: "1",
  width: "100px",
  cursor: "pointer",
}));

const CustomButton = styled(Button)(() => ({
  zIndex: "2",
  height: "38px",
  marginLeft: "auto",
  marginTop: "auto",
  marginBlock: "auto",
  borderRadius: "25rem",
  boxShadow: "none",
}));

interface typeBanner {
  title: string;
  subTitleWhite: string;
  subTitleYellow: string;
  image: string;
  paddingContent?: string;
  sizeTittle?: string;
  sizeSubTittle?: string;
}

const Banner = ({
  title,
  subTitleWhite,
  subTitleYellow,
  image,
  paddingContent,
  sizeTittle,
  sizeSubTittle,
}: typeBanner) => {
  const navigate = useNavigate();
  const { removeLegalData, setActiveStep } = useLegalForm();
  const { removeDataProfile } = useProfile();

  const hanldeReturnHome = async () => {
    removeDataProfile && removeDataProfile();
    removeLegalData && removeLegalData();
    navigate("/");
  };

  return (
    <WrappBox>
      <Content sx={{ py: `${paddingContent}` }}>
        <Image
          src={Logo}
          alt="Parval Puesto de Bolsa - Afiliado a la BVRD"
          onClick={hanldeReturnHome}
        />
        <WrappContent>
          <Typography
            variant="h1"
            color="primary.contrastText"
            sx={{ fontSize: `${sizeTittle}` }}
          >
            {title}
          </Typography>
          <Typography
            variant="h2"
            color="primary.contrastText"
            sx={{ fontSize: `${sizeSubTittle}` }}
          >
            {subTitleWhite} <Span>{subTitleYellow}</Span>
          </Typography>
        </WrappContent>
      </Content>
      <BgImage style={{ backgroundImage: `url(${image})` }} />
    </WrappBox>
  );
};

export default Banner;
