import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getBranch, getInvestmentPromoter } from "../services/Services";
import {
  Container,
  Stack,
  Stepper,
  Step,
  StepLabel,
  StepConnector,
  stepConnectorClasses,
  StepIconProps,
  MobileStepper,
  Box,
  Button,
  styled,
  useTheme,
  useMediaQuery,
  Tooltip,
  Card,
  Typography,
  AppBar,
} from "@mui/material";
import Banner from "../components/Banner";
import imageBanner from "../resources/images/customer-management.webp";
import PaperToForm from "../components/PaperToForm";
import useLegalForm from "../hooks/useLegalForm";
import Loading from "../components/Loading";
import TitleForm from "../components/TitleForm";

const Branchs = () => {
  const navigate = useNavigate();
  const { branch, setBranch, setPromoter } = useLegalForm();
  const [dataBranch, setDataBranch] = useState<any>(null);
  const [isLoading, setLoading] = useState<Boolean>(false);

  useEffect(() => {
    const getData = async () => {
      await handleFetch();
    };
    getData();
  }, []);

  const handleFetch = async () => {
    setLoading(true);
    try {
      let data = await getBranch();
      let promoter = await getInvestmentPromoter();
      if (data) {
        setDataBranch(data.body.content);
      }
      if (promoter) {
        setPromoter && setPromoter(promoter.body.content);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const handleBranch = async (data: any) => {
    const singleBranch = [
      { id: data.id, name: data.name, notaryId: data.notaryId },
    ];
    setBranch && setBranch(singleBranch);
    navigate("legalForm");
  };

  return (
    <>
      <AppBar position="relative" sx={{ backgroundColor: "#326267" }}>
        <Banner
          title={`Parval`}
          subTitleWhite={`Formulario`}
          subTitleYellow={`Conozca su Cliente Jurídico`}
          image={imageBanner}
          sizeTittle={`2.2125rem`}
          sizeSubTittle={`1.7125rem;`}
        />
      </AppBar>
      <Container maxWidth="sm" sx={{ pt: { xs: 4, sm: 4 }, pb: 8, mt: 4 }}>
        <PaperToForm sx={{ py: 4, px: 6 }}>
          <TitleForm>Elige tu sucursal de preferencia</TitleForm>
          {isLoading ? (
            <Loading />
          ) : (
            dataBranch &&
            dataBranch.map((b: any, i: number) => (
              <Card
                sx={{
                  mb: 2,
                  p: 4,
                  borderRadius: "15px",
                  boxShadow:
                    "0px 3px 5px -1px rgb(0 0 0 / 5%),0px 6px 10px 0px rgb(0 0 0 / 10%),0px 1px 18px 0px rgb(0 0 0 / 5%)",
                  "&:hover": {
                    cursor: "pointer",
                    boxShadow:
                      "0px 3px 5px -1px rgb(0 0 0 / 5%), 0px 6px 10px 0px rgb(0 0 0 / 30%),0px 1px 18px 0px rgb(0 0 0 / 5%)",
                  },
                }}
                key={i}
                onClick={() => handleBranch(b)}
              >
                <Typography variant="h5">{b.name}</Typography>
                <Typography variant="body1">{b.address}</Typography>
              </Card>
            ))
          )}
        </PaperToForm>
      </Container>
    </>
  );
};

export default Branchs;
