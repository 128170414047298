export const properties = {
  /** General */

  /** Errors */
  com_parval_label_client_save_error: "Error en proceso de guardar formulario de cliente",
  com_parval_label_client_update_error: "Error en proceso de editar formulario de cliente",
  com_parval_label_client_unexpected_error: "Error inesperado en proceso de añadir formulario de cliente",

  com_parval_label_button_ok_title: "Ok",
};
