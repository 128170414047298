import { FC, useEffect, useState } from "react";
import useLegalForm from "../../hooks/useLegalForm";
import useProfile from "../../hooks/useProfile";
import { useApp } from "../../hooks/useApp";
import {
  Stack,
  Grid,
  TextField,
  FormControl,
  Select,
  MenuItem,
  FormHelperText,
  Autocomplete,
  Typography,
  Divider,
  CircularProgress,
  Button,
  Link,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  styled,
  useTheme,
  Dialog,
} from "@mui/material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { FinalCalInvestorProfile } from "../../types/types";
import { formSchemaOptions } from "../../utils";
import TitleForm from "../../components/TitleForm";
import ButtonGradient from "../../components/ButtonGradient";
import InputLabelToForm from "../../components/InputLabelToForm";
import axios from "axios";
import { URL } from "../../utils/UrlConstant";
import {
  updateProfile,
  clientNotification,
  admintNotification,
} from "../../services/Services";
import MessageManager from "../../components/MessageManager";
import { CONSTANT } from "../../utils/Constants";
import FancyDialog from "../../components/FancyDialog";
import { privateFetch } from "../../utils/CustomFetch";

const ListText = styled(ListItemText)(() => ({
  marginTop: 0,
  marginBottom: 0,
  "& span": {
    fontSize: "1rem",
  },
}));

const InvestorTypeResults: FC = () => {
  const theme = useTheme();
  const { handleBack, step1, setActiveStep } = useLegalForm();
  const { modalData, setModalData } = useApp();
  const [openDialog, setOpenDialog] = useState(false);
  const {
    dataForm,
    investmentProfile,
    getInvestmentProfile,
    loadDataServer,
    steps_completed,
    data,
    dataJ,
  } = useProfile();

  let findData: any;
  if (dataForm !== null) {
    findData = dataForm;
  } else {
    findData = [];
  }

  useEffect(() => {
    if (localStorage.getItem("userId") && investmentProfile === null) {
      getInvestmentProfile &&
        getInvestmentProfile(localStorage.getItem("userId"));
    }
  }, []);

  let investorType =
    investmentProfile !== null //@ts-ignore
      ? investmentProfile.result?.INVESTOR_TYPE
      : "Inversionista no profesional";

  const validation = Yup.object().shape({
    //@ts-ignore
    agree_cat_investor_profile: Yup.string().required("Campo es requerido"),

    /* agree_cat_investor_profile: Yup.string().when(investorType, {
      //@ts-ignore
      is: (investorType) => {
        if (investorType === "Inversionista profesional") return true;
      },
      then: Yup.string().required("Campo es requerido"),
    }), */
  });

  const defaultValues: FinalCalInvestorProfile = {
    agree_cat_investor_profile: findData?.agree_cat_investor_profile
      ? findData.agree_cat_investor_profile
      : "",
    /* type_investor_considered: findData?.type_investor_considered
      ? dataJ &&
        dataJ.pa_ta_type_investor_considered.find(
          (f: any) => f.id === Number(findData.type_investor_considered)
        )
      : [],
    what_cat_investor_considered: findData?.what_cat_investor_considered
      ? data && //@ts-ignore
        data.pa_ta_what_cat_investor_considered.find(
          (f: any) => f.id === Number(findData.what_cat_investor_considered)
        )
      : [], */
  };

  const [loadedPDFKYC, setLoadedPDFKYC] = useState(false);
  const [loadingSendData, setLoadingSendData] = useState(false);
  const [message, setMessage] = useState("");
  const [message2, setMessage2] = useState("");
  const [msgErrorDownload, setMsgErrorDownload] = useState("");

  const {
    register,
    handleSubmit,
    resetField,
    formState,
    control,
    watch,
    reset,
  } = useForm<FinalCalInvestorProfile>({
    defaultValues,
    resolver: yupResolver(validation),
  });
  const { errors } = formState;
  const watchCatProfile: any = watch("agree_cat_investor_profile");
  const watchtype_investor_considered: any = watch("type_investor_considered");
  const miarray: any = [];
  const id = localStorage.getItem("userId");

  useEffect(() => {
    reset(defaultValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataForm]);

  useEffect(() => {
    if (watchtype_investor_considered?.description !== "No Profesional") {
      resetField("what_cat_investor_considered");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchtype_investor_considered]);

  useEffect(() => {
    if (watchCatProfile === "Si") {
      resetField("type_investor_considered");
      resetField("what_cat_investor_considered");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchCatProfile]);

  const handleDownloadKYC = async () => {
    setMsgErrorDownload("");
    setLoadedPDFKYC(true);
    await privateFetch
      .get(`${URL.investmentProfile.perfil}${id}`, { responseType: "blob" })
      .then((resp) => {
        const url = window.URL.createObjectURL(new Blob([resp.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          "Evaluacion-del-perfil-del-inversionista.pdf"
        );
        document.body.appendChild(link);
        link.click();
      })
      .catch((e: any) => {
        setLoadedPDFKYC(false);
        setMsgErrorDownload("Error al descargar archivo");
      });
    await privateFetch
      //.get(`${URL.investmentProfile.anexoi}${id}`, { responseType: "blob" })
      .get(`${URL.investmentProfile.anexoIDynamic}${id}`, {
        responseType: "blob",
      })
      .then((resp) => {
        const url = window.URL.createObjectURL(new Blob([resp.data]));
        const linkAnexoI = document.createElement("a");
        linkAnexoI.href = url;
        linkAnexoI.setAttribute(
          "download",
          "contrato-de-corretaje-parval-puesto-de-bolsa-anexoI.pdf"
        );
        document.body.appendChild(linkAnexoI);
        linkAnexoI.click();
      })
      .catch((e: any) => {
        setLoadedPDFKYC(false);
        setMsgErrorDownload("Error al descargar archivo");
      });
    await privateFetch
      .get(`${URL.investmentProfile.anexoii}${id}`, { responseType: "blob" })
      .then((resp) => {
        const url = window.URL.createObjectURL(new Blob([resp.data]));
        const linkAnexoII = document.createElement("a");
        linkAnexoII.href = url;
        linkAnexoII.setAttribute(
          "download",
          "contrato-de-corretaje-parval-puesto-de-bolsa-anexoII.pdf"
        );
        document.body.appendChild(linkAnexoII);
        linkAnexoII.click();
      })
      .catch((e: any) => {
        setLoadedPDFKYC(false);
        setMsgErrorDownload("Error al descargar archivo");
      });
    await privateFetch
      .get(`${URL.investmentProfile.contract}${id}`, { responseType: "blob" })
      .then((resp) => {
        const url = window.URL.createObjectURL(new Blob([resp.data]));
        const contract = document.createElement("a");
        contract.href = url;
        contract.setAttribute(
          "download",
          "contrato-de-corretaje-parval-puesto-de-bolsa.pdf"
        );
        document.body.appendChild(contract);
        contract.click();
      })
      .catch((e: any) => {
        setLoadedPDFKYC(false);
        setMsgErrorDownload("Error al descargar archivo");
      });
    setLoadedPDFKYC(false);
  };

  let userData;
  let userDataClient;
  const handleNotification = async () => {
    userData = localStorage.getItem("userId");
    /*  userData = {
      //@ts-ignore
      firstname: step1 && step1.name_social_denomination,
      //@ts-ignore
      username: step1 && step1.company_email,
    }; */
    userDataClient = {
      //@ts-ignore
      entryValue: step1 && step1.company_email,
      id: localStorage.getItem("userId"),
    };

    await clientNotification(userData);
    await admintNotification(userData);
  };

  const convertTo2Digits = (num: number) => {
    return num.toString().padStart(2, "0");
  };

  const formatDate = (date: Date) => {
    return [
      convertTo2Digits(date.getDate()),
      convertTo2Digits(date.getMonth() + 1),
      date.getFullYear(),
    ].join("/");
  };

  const onSubmit = async (data: any) => {
    setOpenDialog(false);
    setLoadingSendData(true);
    let key: string;
    let value: { id: string; description: string }[] | any = [];

    for ([key, value] of Object.entries(data)) {
      miarray.push({ [key]: value });
      if (!value) {
        delete data[key];
      } else if (typeof value == "object") {
        data[key] = value !== null && value.id ? value.id : `"${value}"`;
      }
    }
    data.all_steps_complete = "true";

    if (findData.all_steps_complete !== "true") {
      let fullData = {
        fields: {
          ...data,
          form_date: formatDate(new Date()),
        },
        formType: "J",
        userId: localStorage.getItem("userId"),
        stepForm: "Confirmación de Perfil",
      };
      localStorage.setItem("stepForm", "Confirmación de Perfil");
      const saveData = await updateProfile(fullData);
      if (saveData.status === 200) {
        loadDataServer && loadDataServer(localStorage.getItem("userId"));
        setTimeout(() => {
          setMessage2("");
        }, 600);
        await handleNotification();
        setLoadingSendData(false);
        setMessage2("Información guardada");
      } else {
        setLoadingSendData(false);
        setMessage("Ha ocurrido un error");
      }
    }
  };

  const handleBackInvestmentProfile = () => {
    setActiveStep && setActiveStep((prevActiveStep) => prevActiveStep - 2);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  return (
    <>
      {/* {investmentProfile !== null ? ( */}
      <div>
        <Grid
          container
          rowSpacing={{ xs: 2, sm: 4 }}
          columnSpacing={{ xs: 2, sm: 4 }}
          sx={{
            maxWidth: "950px",
            mx: {
              md: "auto!important",
            },
            pr: {
              md: "32px",
            },
          }}
        >
          <Grid item xs={12} sx={{ textAlign: "center" }}>
            <TitleForm sx={{ mt: 4, mb: 4 }}> Resultado Evaluación</TitleForm>
            <Typography variant="body1" align="left" sx={{ mb: 3 }}>
              Según la información suministrada por el cliente acerca de su
              nivel profesional, fuente de fondos, conocimiento de productos
              financieros, apetito de riesgo y rentabilidad esperada, la
              recomendación según su perfil de riesgo se ajusta a:
            </Typography>

            <>
              <Typography align="center" variant="h3" color="primary">
                {investmentProfile !== null &&
                  investmentProfile?.result?.INVESTOR_TYPE}
              </Typography>
              {
                //@ts-ignore
                investmentProfile?.result?.INVESTOR_CATEGORY && (
                  <>
                    <Divider
                      sx={{
                        pt: 3,
                        mb: 3,
                        borderColor: "secondary.main",
                        width: "50%",
                        maxWidth: "450px",
                        ml: "auto",
                        mr: "auto",
                      }}
                    />

                    <Typography
                      align="center"
                      variant="h5"
                      sx={{
                        width: "100%",
                        maxWidth: "300px",
                        backgroundColor: "primary.main",
                        color: "#fff",
                        pb: "4px",
                        margin: "0 auto",
                        borderRadius: "4px",
                        "&:hover": {
                          backgroundColor: "primary.main",
                          opacity: [0.9, 0.8, 0.9],
                        },
                      }}
                      color="light.contrastText"
                    >
                      {
                        //@ts-ignore
                        investmentProfile?.result?.INVESTOR_CATEGORY
                      }
                    </Typography>
                  </>
                )
              }
              <Divider
                sx={{
                  pt: 3,
                  mb: 0,
                  borderColor: "secondary.main",
                  width: "50%",
                  maxWidth: "450px",
                  ml: "auto",
                  mr: "auto",
                }}
              />
            </>
          </Grid>
          <Grid item xs={12}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <TitleForm sx={{ mt: 4, mb: 4 }}>
                Confirmación de Perfil
              </TitleForm>
              <Grid container spacing={4} sx={{ mb: 4 }}>
                <Grid item xs={12}>
                  <Controller
                    name={"agree_cat_investor_profile"}
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <FormControl
                        fullWidth
                        className={
                          findData.all_steps_complete ? "disabled-select" : ""
                        }
                      >
                        <InputLabelToForm
                          error={
                            errors.agree_cat_investor_profile &&
                            Boolean(errors.agree_cat_investor_profile)
                          }
                        >
                          ¿Cliente está de acuerdo con la categorización de su
                          perfil de inversionista?
                        </InputLabelToForm>
                        <Select
                          variant="standard"
                          value={value}
                          {...register("agree_cat_investor_profile")}
                          error={
                            errors.agree_cat_investor_profile &&
                            Boolean(errors.agree_cat_investor_profile)
                          }
                        >
                          <MenuItem value="Sí">Sí</MenuItem>
                          <MenuItem value="No">No</MenuItem>
                        </Select>
                        {errors.agree_cat_investor_profile && (
                          <FormHelperText>
                            {errors.agree_cat_investor_profile.message}
                          </FormHelperText>
                        )}
                      </FormControl>
                    )}
                  />
                  {watchCatProfile === "No" && (
                    <Typography variant="body1" color="error" sx={{ my: 3 }}>
                      Sí no está de acuerdo con su categorización de perfil,
                      debe regresar a la sección de{" "}
                      <b>Perfil de Inversionista</b> y cambiar las respuestas de
                      la sección de <b>Información financiera</b>. <br />
                      <br />
                      Haz click en el botón de <b>regresar</b>.
                    </Typography>
                  )}
                </Grid>
                {/* {watchCatProfile === "No" && (
                  <>
                    <Grid item xs={12}>
                      <Controller
                        control={control}
                        name="type_investor_considered"
                        rules={{ required: true }}
                        render={({ field: { onChange, value } }) => (
                          <Autocomplete
                            onChange={(event, newValue) => {
                              onChange(newValue);
                            }}
                            options={
                              dataJ && dataJ.pa_ta_type_investor_considered
                                ? dataJ.pa_ta_type_investor_considered
                                : []
                            }
                            getOptionLabel={(option: any) =>
                              option.description || ""
                            }
                            isOptionEqualToValue={(option: any, value: any) =>
                              option.id === value.id
                            }
                            value={value}
                            disabled={findData.all_steps_complete}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                fullWidth
                                label="Sino está de acuerdo con su perfil calculado, ¿Qué tipo de Inversionista se considera?"
                                variant="standard"
                                error={!!errors.type_investor_considered}
                                helperText={
                                  errors.type_investor_considered &&
                                  errors.type_investor_considered.message
                                }
                              />
                            )}
                          />
                        )}
                      />
                    </Grid>
                  </>
                )}
                {watchtype_investor_considered?.id === 2 && (
                  <Grid item xs={12}>
                    <Controller
                      control={control}
                      name="what_cat_investor_considered"
                      rules={{ required: true }}
                      render={({ field: { onChange, value } }) => (
                        <Autocomplete
                          onChange={(event, newValue) => {
                            onChange(newValue);
                          }}
                          options={
                            //@ts-ignore
                            data && data.pa_ta_what_cat_investor_considered
                              ? //@ts-ignore
                                data.pa_ta_what_cat_investor_considered
                              : []
                          }
                          getOptionLabel={(option: any) =>
                            option.description || ""
                          }
                          isOptionEqualToValue={(option: any, value: any) =>
                            option.id === value.id
                          }
                          value={value}
                          disabled={findData.all_steps_complete}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              fullWidth
                              label="Si el cliente se considera Inversionista No Profesional, ¿En que categoria de Inversionista se considera?"
                              variant="standard"
                              error={!!errors.what_cat_investor_considered}
                              helperText={
                                errors.what_cat_investor_considered &&
                                errors.what_cat_investor_considered.message
                              }
                            />
                          )}
                        />
                      )}
                    />
                  </Grid>
                )} */}
              </Grid>
              <Stack
                justifyContent="flex-end"
                alignItems="flex-end"
                direction={{ xs: "column", sm: "row" }}
                spacing={2}
                sx={{ mt: 6 }}
              >
                {watchCatProfile === "No" && !findData.all_steps_complete && (
                  <Button
                    type="button"
                    variant="outlined"
                    onClick={handleBackInvestmentProfile}
                    sx={{ width: { xs: "100%", sm: "auto" } }}
                  >
                    Regresar
                  </Button>
                )}

                {watchCatProfile === "Sí" && (
                  <ButtonGradient
                    type="button"
                    sx={{ width: { xs: "100%", sm: "auto" } }}
                    disabled={findData.all_steps_complete === "true"}
                    data-name="confirmSaveForm"
                    name="confirmSaveForm"
                    onClick={() => setOpenDialog(true)}
                  >
                    Guardar y finalizar
                  </ButtonGradient>
                )}
              </Stack>
              <Divider
                sx={{
                  pt: 3,
                  mb: 0,
                  borderColor: "secondary.main",
                  width: "50%",
                  ml: "auto",
                  mr: "auto",
                }}
              />
              <TitleForm sx={{ mt: 4, mb: 4, fontWeight: 500 }}>
                ¡Primer paso completado!
              </TitleForm>
              <Divider
                sx={{
                  pt: 1,
                  mb: 3,
                  borderColor: "secondary.main",
                  width: "100%",
                  ml: "auto",
                  mr: "auto",
                }}
              />
              <Typography
                variant="h6"
                color="secondary.main"
                sx={{ fontWeight: 500, mb: 3 }}
              >
                Próximos pasos:
              </Typography>

              <Stack
                direction={{ xs: "column", md: "row" }}
                justifyContent={{ xs: "center", sm: "flex-start" }}
                alignItems="center"
                spacing={{ xs: 1, sm: 2 }}
              >
                <ButtonGradient
                  onClick={handleDownloadKYC}
                  disabled={findData?.length === 0}
                  sx={{
                    borderRadius: "0.75rem",
                    padding: "2px 15px",
                    mt: 0,
                  }}
                >
                  {loadedPDFKYC && (
                    <CircularProgress
                      size={24}
                      sx={{
                        marginRight: "5px",
                        color: `${theme.palette.primary.contrastText}`,
                      }}
                    />
                  )}
                  Descarga aquí
                </ButtonGradient>
                <Typography variant="body1">
                  los formularios que acabas de completar y firma cada uno de
                  ellos.
                </Typography>
              </Stack>
              {msgErrorDownload !== "" && (
                <Typography
                  variant="body1"
                  color="error"
                  align="center"
                  sx={{ my: 2 }}
                >
                  {msgErrorDownload}
                </Typography>
              )}
              <Typography variant="body1" sx={{ mt: 2, mb: 1 }}>
                Envía los documentos escaneados a{" "}
                <Link
                  href="mailto:info@parval.com.do"
                  target="_blank"
                  sx={{ fontWeight: 700 }}
                >
                  info@parval.com.do
                </Link>
                , además adjunta:
              </Typography>
              <List dense={true} disablePadding>
                <ListItem sx={{ alignItems: "flex-start" }}>
                  <ListItemIcon sx={{ minWidth: "15px", marginTop: "8px" }}>
                    <FiberManualRecordIcon sx={{ fontSize: "0.5rem" }} />
                  </ListItemIcon>
                  <ListText primary="Cédula de identidad (nacionales/residentes) o pasaportes (extranjeros) o Registro Mercantil o equivalente." />
                </ListItem>
                <ListItem sx={{ alignItems: "flex-start" }}>
                  <ListItemIcon sx={{ minWidth: "15px", marginTop: "8px" }}>
                    <FiberManualRecordIcon sx={{ fontSize: "0.5rem" }} />
                  </ListItemIcon>
                  <ListText primary="Estados de cuenta bancaria de la empresa ó Referencia bancaria (de la empresa, del banco de la cuenta de donde procederán los fondos)." />
                </ListItem>
                <ListItem sx={{ alignItems: "flex-start" }}>
                  <ListItemIcon sx={{ minWidth: "15px", marginTop: "8px" }}>
                    <FiberManualRecordIcon sx={{ fontSize: "0.5rem" }} />
                  </ListItemIcon>
                  <ListText primary="Estados Financieros Auditados Del Último Año De Ejercicio Fiscal. En caso de empresas con un año o más de operaciones." />
                </ListItem>
                <ListItem sx={{ alignItems: "flex-start" }}>
                  <ListItemIcon sx={{ minWidth: "15px", marginTop: "8px" }}>
                    <FiberManualRecordIcon sx={{ fontSize: "0.5rem" }} />
                  </ListItemIcon>
                  <ListText primary="Registro Mercantil vigente." />
                </ListItem>
                <ListItem sx={{ alignItems: "flex-start" }}>
                  <ListItemIcon sx={{ minWidth: "15px", marginTop: "8px" }}>
                    <FiberManualRecordIcon sx={{ fontSize: "0.5rem" }} />
                  </ListItemIcon>
                  <ListText primary="Acta RNC o certificación vigente descargada de la página virtual de la DGII." />
                </ListItem>
                <ListItem sx={{ alignItems: "flex-start" }}>
                  <ListItemIcon sx={{ minWidth: "15px", marginTop: "8px" }}>
                    <FiberManualRecordIcon sx={{ fontSize: "0.5rem" }} />
                  </ListItemIcon>
                  <ListText primary="Último IR2 presentado." />
                </ListItem>
                <ListItem sx={{ alignItems: "flex-start" }}>
                  <ListItemIcon sx={{ minWidth: "15px", marginTop: "8px" }}>
                    <FiberManualRecordIcon sx={{ fontSize: "0.5rem" }} />
                  </ListItemIcon>
                  <ListText primary="Acta política de firmas para apertura y manejo de cuenta corretaje." />
                </ListItem>
                <ListItem sx={{ alignItems: "flex-start" }}>
                  <ListItemIcon sx={{ minWidth: "15px", marginTop: "8px" }}>
                    <FiberManualRecordIcon sx={{ fontSize: "0.5rem" }} />
                  </ListItemIcon>
                  <ListText primary="En caso de no poseer acta de firmas: estatutos sociales donde se visualice persona/s autorizada/s a suscribir contratos conjuntamente con acta donde se designen estas personas." />
                </ListItem>
              </List>
              {loadingSendData && (
                <Stack spacing={2} direction="row">
                  <CircularProgress
                    size={24}
                    sx={{
                      margin: "5px auto",
                      color: `${theme.palette.primary.main}`,
                    }}
                  />
                </Stack>
              )}
              {message !== "" && (
                <Typography sx={{ mt: 4 }} color="success.main" align="center">
                  {message}
                </Typography>
              )}
              {message2 !== "" && (
                <Typography sx={{ mt: 4 }} color="primary" align="center">
                  {message2}
                </Typography>
              )}
            </form>
          </Grid>
        </Grid>
      </div>
      {/* ) : (
        <>
          <Stack spacing={2} direction="row" justifyContent="center">
            <CircularProgress color="primary" />
          </Stack>
        </>
      )} */}
      <Dialog open={openDialog} onClose={handleClose}>
        <FancyDialog
          textTitle={`Finalizar proceso`}
          textChildren={
            <>
              <Typography variant="body1" color="initial" sx={{ mb: 2 }}>
                Declaro que todas las informaciones que he puesto en este
                formulario son veraces y a la vez autorizo a Parval, a efectuar
                cualquier tipo de investigación respecto de mi situación
                financiera y crediticia y del origen lícito de fondos, ante los
                organismos y fuentes que esta última considere oportunos a los
                fines de evaluarme.
              </Typography>
              <Typography variant="body1" color="initial" sx={{ mb: 2 }}>
                Al seleccionar la opción acepto confirmo haber completado los
                formularios relacionados a la solicitud de apertura de cuenta en
                Parval.
              </Typography>
              <Typography variant="body1" color="initial" sx={{ mb: 2 }}>
                Toda información y documentos suministrados a Parval quedan
                sujetos a revisión, reservándose éste el derecho de autorizar o
                no la apertura de la cuenta de Parval.
              </Typography>
              <Typography variant="body1" color="initial">
                En caso de no haber enviado por correo electrónico o cargado en
                la página web los documentos requeridos, deberás completar los
                mismos a los fines de que la solicitud pueda ser procesada.
                Hacemos la salvedad de que además de la información y documentos
                requeridos, pudieran requerirse adicionales.
              </Typography>
            </>
          }
          actionButton={
            <>
              <Button
                type="button"
                variant="outlined"
                onClick={() => setOpenDialog(false)}
                sx={{ mt: 2, mr: 2 }}
                autoFocus
              >
                No acepto
              </Button>
              <ButtonGradient type="submit" onClick={handleSubmit(onSubmit)}>
                Acepto
              </ButtonGradient>{" "}
            </>
          }
        />
      </Dialog>
    </>
  );
};

export default InvestorTypeResults;

{
  /* <MessageManager
          type={CONSTANT.WARNING_MSG}
          msg={"Finalizar proceso"}
          details={`
          Declaro que todas las informaciones que he puesto en este formulario son veraces y a la vez autorizo a Parval, a efectuar cualquier tipo de investigación respecto de mi situación financiera y crediticia y del origen lícito de fondos, ante los organismos y fuentes que esta última considere oportunos a los fines de evaluarme.
          Al seleccionar la opción acepto confirmo haber completado los formularios relacionados a la solicitud de apertura de cuenta en Parval.
          Toda información y documentos suministrados a Parval quedan sujetos a revisión, reservándose éste el derecho de autorizar o no la apertura de la cuenta de Parval.
          En caso de no haber enviado por correo electrónico o cargado en la página web los documentos requeridos, deberás completar los mismos a los fines de que la solicitud pueda ser procesada. Hacemos la salvedad de que además de la información y documentos requeridos, pudieran requerirse adicionales.
          `}
          callback={handleSubmit(onSubmit)}
        /> */
}
