import Urls from "../url/Urls";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import { getItem } from "./LocalStorageManager";

const publicFetch = axios.create({
  baseURL: Urls.base_url + Urls.api_context,
});

const privateFetch = axios.create({
  baseURL: Urls.base_url + Urls.api_context,
});

privateFetch.interceptors.request.use(async (config) => {
  //validate uri to send token
  const { origin } = new URL(config.baseURL + config.url);
  const allowedOrigins = [Urls.base_url];
  const authInfo = await getItem("authProfileInfo");
  const decodeToken = jwtDecode(authInfo?.access_token);
  const expiresAt = decodeToken.exp;
  let valid = new Date().getTime() / 1000 < expiresAt;
  if (allowedOrigins.includes(origin) && valid) {
    config.headers.Authorization = `Bearer ${authInfo?.access_token}`;
  }
  return config;
});

export { publicFetch, privateFetch };
