import * as Yup from "yup";

export const phoneRegExp =
  /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{2,6}$/;
/* export const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/; */

export const numberRegExp = /^[0-9]*$/;

export const urlRegExp = /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/;

export const formSchemaOptions = {
  id: Yup.string().required("Required"),
  description: Yup.string().required("Required"),
};
export const formSchemaOptionsName = {
  id: Yup.string().required("Required"),
  name: Yup.string().required("Required"),
};

export const formSchemaCountry = {
  id: Yup.string().required("Required"),
  description: Yup.string().required("Required"),
};
