import { useState, createContext, useLayoutEffect } from "react";
import {
  CalculateProfile,
  InvestmentProfileResults,
  legalDataTables,
} from "../types/types";
import {
  getProfile,
  getCalculateProfile,
  getAllData,
  getAddressData,
  getBankEntity,
} from "../services/Services";

const initialState = {
  investmentProfile: null,
  dataForm: null,
  data: [],
};

const ProfileContext = createContext<CalculateProfile>(initialState);

const ProfileProvider = ({ children }: any) => {
  /* const [investmentProfile, setInvestmentProfile] = useState<
    InvestmentProfileResults[]
  >(
    localStorage.getItem("calculateProfile") &&
      //@ts-ignore
      JSON.parse(localStorage.getItem("calculateProfile"))
  ); */
  const [investmentProfile, setInvestmentProfile] =
    useState<InvestmentProfileResults | null>(initialState.investmentProfile);

  const [dataForm, setDataForm] = useState(initialState.dataForm);
  const [exists, setExists] = useState<boolean>(false);
  const [completeData, setCompleteData] = useState<boolean>(false);
  const [loader, setLoader] = useState<boolean>(false);
  const [steps_completed, setSteps_completed] = useState<any>([]);
  const [loaderAllData, setLoaderAllData] = useState<boolean>(false);
  /* const [data, setAllData] = useState<any>(
    localStorage.getItem("allDataTablesF")
      ? //@ts-ignore
        JSON.parse(localStorage.getItem("allDataTablesF"))
      : null
  ); */
  const [data, setAllData] = useState<legalDataTables[]>(
    localStorage.getItem("allLegalDataParval")
      ? JSON.parse(localStorage.getItem("allLegalDataParval") || "{}")
      : initialState.data
  );
  const [dataJ, setAllDataJ] = useState<any>(
    localStorage.getItem("allLegalDataParval") &&
      //@ts-ignore
      JSON.parse(localStorage.getItem("allLegalDataParval"))
  );
  const [address, setAddres] = useState(
    localStorage.getItem("addressData")
      ? //@ts-ignore
        JSON.parse(localStorage.getItem("addressData"))
      : {
          provinces: {},
          sector: {},
        }
  );

  const getAddress = async () => {
    const response = await getAddressData();
    if (response?.statusP === 200 && response?.statusS === 200) {
      setAddres({
        ...address,
        provinces: response.provinces.content,
        sector: response.sector.content,
      });
      localStorage.setItem(
        "addressData",
        JSON.stringify({
          provinces: response.provinces.content,
          sector: response.sector.content,
        })
      );
    }
  };

  const getAllDataTables = async () => {
    /* const responseF = await getAllData("F");
    if (responseF?.status === 200) {
      //setAllData(responseF.body);
      localStorage.setItem("allDataTablesF", JSON.stringify(responseF.body));
    } */

    const responseJ = await getAllData("J");
    const responseBankEntity = await getBankEntity();
    //console.log("responseJ: ", responseJ);
    if (responseJ?.status === 200) {
      if (responseBankEntity?.status === 200) {
        responseJ.body.pa_ta_bank_entity = responseBankEntity.body.content;
      }
      setAllData(responseJ.body);
      setAllDataJ(responseJ.body);
      localStorage.setItem(
        "allLegalDataParval",
        JSON.stringify(responseJ.body)
      );
    } /* else {
      localStorage.setItem("allLegalDataParval", JSON.stringify(""));
    } */
  };

  const loadDataServer = async (value: string | null) => {
    //console.log("value: ", value);
    const responseProfile = await getProfile(value);
    if (responseProfile?.status === 200) {
      localStorage.setItem("formType", responseProfile.body.formType);
      localStorage.setItem(
        "data_form",
        JSON.stringify(responseProfile.body.fields)
      );
      localStorage.setItem("stepForm", responseProfile.body.stepForm);
      setDataForm(responseProfile.body.fields);
      setSteps_completed([
        !!responseProfile.body.fields.step_one_complete,
        !!responseProfile.body.fields.step_two_complete,
        !!responseProfile.body.fields.step_three_complete,
        !!responseProfile.body.fields.step_four_complete,
        !!responseProfile.body.fields.all_steps_complete,
      ]);
      setExists(true);
      if (!!responseProfile.body.fields.step_four_complete) {
        setCompleteData(true);
      } else {
        setCompleteData(false);
      }

      return { status: responseProfile.status, content: responseProfile };
    } else {
      setSteps_completed([]);
      console.log(responseProfile);
    }
  };

  useLayoutEffect(() => {
    setTimeout(() => {
      setLoader(false);
    }, 100);
  }, [dataForm]);

  const getInvestmentProfile = async (value: string | null) => {
    const responseCalculate = await getCalculateProfile(value);
    //console.log(responseCalculate);
    if (responseCalculate?.status === 200) {
      setInvestmentProfile(responseCalculate.body);
      localStorage.setItem(
        "calculateProfile",
        JSON.stringify(responseCalculate.body)
      );
    } else {
      console.log(responseCalculate);
    }
  };

  useLayoutEffect(() => {
    const cargaGET = async () => {
      localStorage.removeItem("userId");
      localStorage.removeItem("userId");
      await getAllDataTables();
      await getAddress();
      setLoaderAllData(true);
    };
    cargaGET();
  }, []);

  const removeDataProfile = async () => {
    setCompleteData(false);
    setSteps_completed([]);
    setDataForm(null);
    setInvestmentProfile(null);
    localStorage.removeItem("stepForm");
    localStorage.removeItem("data_form");
    localStorage.removeItem("formType");
    localStorage.removeItem("stepOne");
    localStorage.removeItem("stepTwo");
    localStorage.removeItem("stepThree");
    localStorage.removeItem("stepFour");
    localStorage.removeItem("calculateProfile");
  };

  /* useLayoutEffect(() => {
    localStorage.getItem("user_id") &&
      loadDataServer(localStorage.getItem("user_id"));
    localStorage.getItem("user_id") &&
      getInvestmentProfile(localStorage.getItem("user_id"));

    const cargaGET = async () => {
      await getAllDataTables();
      await getAddress();
      setLoaderAllData(true);
    };
    cargaGET();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); */

  const formContextValues: CalculateProfile = {
    investmentProfile,
    setInvestmentProfile,
    getInvestmentProfile,
    dataForm,
    setDataForm,
    loader,
    //loadDataFormStorage,
    loadDataServer,
    exists,
    setExists,
    steps_completed,
    completeData,
    setCompleteData,
    data,
    dataJ,
    address,
    loaderAllData,
    removeDataProfile,
  };

  return (
    <ProfileContext.Provider value={formContextValues}>
      {children}
    </ProfileContext.Provider>
  );
};

export { ProfileProvider };

export default ProfileContext;
