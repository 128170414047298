import { FC, useState, useEffect } from "react";
import useLegalForm from "../../hooks/useLegalForm";
import useProfile from "../../hooks/useProfile";
import { useApp } from "../../hooks/useApp";
import {
  Grid,
  Typography,
  TextField,
  Autocomplete,
  Stack,
  Divider,
  IconButton,
  Button,
  Tooltip,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  FormControl,
  Select,
  MenuItem,
  Box,
  Chip,
  ListItemText,
  InputLabel,
  styled,
  Dialog,
  useTheme,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import TitleForm from "../../components/TitleForm";
import ButtonGradient from "../../components/ButtonGradient";
import FancyDialog from "../../components/FancyDialog";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { validationBeneficiariesAndMarketKnowledge } from "./validation";
import {
  BeneficiariesAndMarketKnowledgeType,
  liquid_assets_distributionType,
} from "../../types/types";
import { data as datos } from "../../data/data";
import { saveProfile, updateProfile } from "../../services/Services";

const LabelForm = styled(InputLabel)(({ theme }) => ({
  left: "-14px",
}));

const BeneficiariesAndMarketKnowledge: FC = () => {
  const theme = useTheme();
  const [loadingSendData, setLoadingSendData] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const { dataForm, completeData, getInvestmentProfile, data, dataJ } =
    useProfile();
  const [errorBelowOneHundred, setErrorBelowOneHundred] = useState("");
  let localData4 = localStorage.getItem("stepFour");
  const {
    setActiveStep,
    step1,
    step2,
    step3,
    step4,
    setStep4,
    step4Answered,
    handleBack,
    handleNext,
  } = useLegalForm();
  const { setErrorMsg } = useApp();

  let findData: any;
  if (dataForm !== null) {
    findData = dataForm;
  } else if (typeof localData4 === "string") {
    findData = JSON.parse(localData4);
  } else {
    findData = step4;
  }

  const defaultValues: BeneficiariesAndMarketKnowledgeType = {
    company_have_a_TIN_or_EIN: findData.company_have_a_TIN_or_EIN
      ? findData.company_have_a_TIN_or_EIN
      : "",
    company_have_a_GIN: findData.company_have_a_GIN
      ? findData.company_have_a_GIN
      : "",
    company_have_a_GIN_data: findData.company_have_a_GIN_data
      ? findData.company_have_a_GIN_data
      : "",
    tin_or_ein: findData.tin_or_ein ? findData.tin_or_ein : "",
    direct_and_indirect_beneficiaries:
      (findData.direct_and_indirect_beneficiaries &&
        JSON.parse(findData.direct_and_indirect_beneficiaries)) ?? [
        {
          full_name: "",
          document_type: [],
          identification_number: "",
          occupation: "",
          phone: "",
          address: "",
        },
      ],
    authorized_signatures: (findData.authorized_signatures &&
      JSON.parse(findData.authorized_signatures)) ?? [
      {
        full_name: "",
        document_number: "",
      },
    ],
    mode_of_signatures_and_reception_of_instructions:
      findData?.mode_of_signatures_and_reception_of_instructions ?? "",
    liquid_assets_distribution_form:
      (findData.liquid_assets_distribution_form &&
        JSON.parse(findData.liquid_assets_distribution_form)) ?? [
        {
          liquid_assets_distribution_id: [],
          liquid_assets_percentage: 0,
        },
      ],
    information_media: findData.information_media
      ? dataJ.pa_ta_information_media.find(
          (f: any) => f.id === Number(findData.information_media)
        )
      : null,
    financialInstrumentsYear: (findData.financialInstrumentsYear &&
      JSON.parse(findData.financialInstrumentsYear)) ?? [
      {
        financialInstruments: null,
        experienceTime: null,
        numTransactionsYear: null,
        levelknowInstRisk: null,
      },
    ],
    operations_in_national_or_foreign_stock_market:
      findData.operations_in_national_or_foreign_stock_market
        ? findData.operations_in_national_or_foreign_stock_market
        : "",
    traded_volume_greater_than_3000: findData.traded_volume_greater_than_3000
      ? findData.traded_volume_greater_than_3000
      : "",
    average_transaction_frequency: findData.average_transaction_frequency
      ? findData.average_transaction_frequency
      : "",
    has_cash_or_investment_assets: findData.has_cash_or_investment_assets
      ? findData.has_cash_or_investment_assets
      : "",
    positions_in_management_of_financial_institution:
      findData.positions_in_management_of_financial_institution
        ? findData.positions_in_management_of_financial_institution
        : "",
    member_of_the_management_board: findData.member_of_the_management_board
      ? findData.member_of_the_management_board
      : "",
    stock_broker_certification: findData.stock_broker_certification
      ? findData.stock_broker_certification
      : "",
  };

  const {
    register,
    handleSubmit,
    resetField,
    formState,
    control,
    watch,
    getValues,
    reset,
    trigger,
    setError,
  } = useForm<BeneficiariesAndMarketKnowledgeType>({
    defaultValues,
    resolver: yupResolver(validationBeneficiariesAndMarketKnowledge),
    mode: "onChange",
    reValidateMode: "onChange",
  });
  const { errors, dirtyFields, isValid } = formState;
  const miarray: any = [];
  let totalPercentages: number = 0;

  const {
    fields: direct_and_indirect_beneficiariesFields,
    append: direct_and_indirect_beneficiariesAppend,
    remove: direct_and_indirect_beneficiariesRemove,
  } = useFieldArray({
    control,
    name: "direct_and_indirect_beneficiaries",
    rules: {
      minLength: 1,
    },
  });

  const {
    fields: authorized_signaturesFields,
    append: authorized_signaturesAppend,
    remove: authorized_signaturesRemove,
  } = useFieldArray({
    control,
    name: "authorized_signatures",
    rules: {
      minLength: 1,
    },
  });

  const {
    fields: financialInstrumentsYearField,
    append: financialInstrumentsYearAppend,
    remove: financialInstrumentsYearRemove,
  } = useFieldArray({
    control,
    name: "financialInstrumentsYear",
    rules: {
      minLength: 3,
    },
  });
  const {
    fields: liquid_assets_distributionField,
    append: liquid_assets_distributionAppend,
    remove: liquid_assets_distributionRemove,
  } = useFieldArray({
    control,
    name: "liquid_assets_distribution_form",
    rules: {
      minLength: 3,
    },
  });
  const watch_company_have_a_TIN_or_EIN: any = watch(
    "company_have_a_TIN_or_EIN"
  );
  const watch_company_have_a_GIN: any = watch("company_have_a_GIN");
  const watch_operations_in_national_or_foreign_stock_market: any = watch(
    "operations_in_national_or_foreign_stock_market"
  );
  const watch_liquid_assets_distribution: liquid_assets_distributionType[] =
    watch("liquid_assets_distribution_form");

  useEffect(() => {
    reset(defaultValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataForm]);

  const handleClose = () => {
    setOpenDialog(false);
  };

  const validateForm = async () => {
    await trigger();
    const totalPercentage = watch_liquid_assets_distribution.reduce<any>(
      (sum, { liquid_assets_percentage }) => {
        // Convierte el string 'liquid_assets_percentage' a number
        const percentage = Number(liquid_assets_percentage);
        return sum + percentage;
      },
      0
    );

    if (
      watch_liquid_assets_distribution &&
      Number(watch_liquid_assets_distribution[0].liquid_assets_percentage) > 0
    ) {
      if (totalPercentage < 100) {
        setErrorMsg &&
          setErrorMsg(
            "La sumatoria de la distribución de activos líquidos debe ser de 100%"
          );
        setErrorBelowOneHundred(
          `La sumatoria de la distribución de activos líquidos debe ser de 100%, falta ${
            100 - totalPercentage
          }%`
        );
      } else if (totalPercentage > 100) {
        setErrorMsg &&
          setErrorMsg(
            "La sumatoria de la distribución de activos líquidos debe ser de 100%"
          );
        setErrorBelowOneHundred(
          `La sumatoria de la distribucción de activos líquidos debe ser de 100%, tiene un excedente de ${
            totalPercentage - 100
          }%`
        );
      } else {
        setErrorMsg && setErrorMsg("");
        setErrorBelowOneHundred("");
        if (isValid) {
          setOpenDialog(true);
        }
      }
    }
  };

  /* useEffect(() => {
    if (watch_liquid_assets_distribution) {
      watch_liquid_assets_distribution.forEach(
        (item: any) =>
          //console.log(item.liquid_assets_percentage),
          (totalPercentages += Number(item.liquid_assets_percentage))
      );
    }
    console.log(watch_liquid_assets_distribution);
  }, [watch_liquid_assets_distribution]); */

  const onSubmit = async (data: any) => {
    let localForm: any = localStorage.getItem("formData");
    setLoadingSendData(true);
    let key: string | any;
    let value: { id: string; description: string }[] | any = [];

    /* const asingnumber = {
      fixedIncomeSecurities: 1,
      investmentFunds: 2,
      liquidityOperations: 3,
      variableIncomeSecurities: 4,
      otherServices: 5,
    }; */
    //const op_values_finance_array = [];

    const instruments: any[] = [];
    const time_experience_instrument: any[] = [];
    const number_operations_year: any[] = [];
    const knowledge_level: any[] = [];
    const liquid_assets_distribution: any[] = [];

    for ([key, value] of Object.entries(data)) {
      miarray.push({ [key]: value });
      if (!value) {
        delete data[key];
      } else if (typeof value == "object") {
        data[key] =
          value !== null && value.id
            ? typeof value.id !== "string"
              ? JSON.stringify(value.id)
              : value.id
            : JSON.stringify(value);
      }
    }
    JSON.parse(data.financialInstrumentsYear).forEach((e: any) => {
      instruments.push(Number(e.financialInstruments.id));
      time_experience_instrument.push(Number(e.experienceTime.id));
      number_operations_year.push(Number(e.numTransactionsYear.id));
      knowledge_level.push(Number(e.levelknowInstRisk.id));
    });

    const bonos_BCRD_hacienda: any[] = [];
    const fondos_abiertos_y_cerrados: any[] = [];
    const operaciones_a_plazo_forwards: any[] = [];
    const prestamos_a_titulos_de_valores_mutuo: any[] = [];
    const accciones: any[] = [];
    const contratos_de_reportes_repos: any[] = [];
    const bonos_corporativos_papeles_comerciales: any[] = [];
    const derivados_de_cobertura_no_especulativos: any[] = [];
    const prestamos_de_margen: any[] = [];

    JSON.parse(data.financialInstrumentsYear).forEach((e: any) => {
      if (Number(e.financialInstruments.id) === 23) {
        bonos_BCRD_hacienda.push({
          financialInstruments: e.financialInstruments,
          experienceTime: e.experienceTime,
          numTransactionsYear: e.numTransactionsYear,
          levelknowInstRisk: e.levelknowInstRisk,
        });
      }
      if (Number(e.financialInstruments.id) === 27) {
        fondos_abiertos_y_cerrados.push({
          financialInstruments: e.financialInstruments,
          experienceTime: e.experienceTime,
          numTransactionsYear: e.numTransactionsYear,
          levelknowInstRisk: e.levelknowInstRisk,
        });
      }
      if (Number(e.financialInstruments.id) === 28) {
        operaciones_a_plazo_forwards.push({
          financialInstruments: e.financialInstruments,
          experienceTime: e.experienceTime,
          numTransactionsYear: e.numTransactionsYear,
          levelknowInstRisk: e.levelknowInstRisk,
        });
      }
      if (Number(e.financialInstruments.id) === 29) {
        prestamos_a_titulos_de_valores_mutuo.push({
          financialInstruments: e.financialInstruments,
          experienceTime: e.experienceTime,
          numTransactionsYear: e.numTransactionsYear,
          levelknowInstRisk: e.levelknowInstRisk,
        });
      }
      if (Number(e.financialInstruments.id) === 30) {
        accciones.push({
          financialInstruments: e.financialInstruments,
          experienceTime: e.experienceTime,
          numTransactionsYear: e.numTransactionsYear,
          levelknowInstRisk: e.levelknowInstRisk,
        });
      }
      if (Number(e.financialInstruments.id) === 31) {
        contratos_de_reportes_repos.push({
          financialInstruments: e.financialInstruments,
          experienceTime: e.experienceTime,
          numTransactionsYear: e.numTransactionsYear,
          levelknowInstRisk: e.levelknowInstRisk,
        });
      }
      if (Number(e.financialInstruments.id) === 32) {
        bonos_corporativos_papeles_comerciales.push({
          financialInstruments: e.financialInstruments,
          experienceTime: e.experienceTime,
          numTransactionsYear: e.numTransactionsYear,
          levelknowInstRisk: e.levelknowInstRisk,
        });
      }
      if (Number(e.financialInstruments.id) === 33) {
        derivados_de_cobertura_no_especulativos.push({
          financialInstruments: e.financialInstruments,
          experienceTime: e.experienceTime,
          numTransactionsYear: e.numTransactionsYear,
          levelknowInstRisk: e.levelknowInstRisk,
        });
      }
      if (Number(e.financialInstruments.id) === 34) {
        prestamos_de_margen.push({
          financialInstruments: e.financialInstruments,
          experienceTime: e.experienceTime,
          numTransactionsYear: e.numTransactionsYear,
          levelknowInstRisk: e.levelknowInstRisk,
        });
      }
    });

    data.bonos_BCRD_hacienda = JSON.stringify(bonos_BCRD_hacienda);
    data.fondos_abiertos_y_cerrados = JSON.stringify(
      fondos_abiertos_y_cerrados
    );
    data.operaciones_a_plazo_forwards = JSON.stringify(
      operaciones_a_plazo_forwards
    );
    data.prestamos_a_titulos_de_valores_mutuo = JSON.stringify(
      prestamos_a_titulos_de_valores_mutuo
    );
    data.accciones = JSON.stringify(accciones);
    data.contratos_de_reportes_repos = JSON.stringify(
      contratos_de_reportes_repos
    );
    data.bonos_corporativos_papeles_comerciales = JSON.stringify(
      bonos_corporativos_papeles_comerciales
    );
    data.derivados_de_cobertura_no_especulativos = JSON.stringify(
      derivados_de_cobertura_no_especulativos
    );
    data.prestamos_de_margen = JSON.stringify(prestamos_de_margen);

    JSON.parse(data.liquid_assets_distribution_form).forEach((e: any) => {
      liquid_assets_distribution.push(
        Number(e.liquid_assets_distribution_id.id)
      );
    });

    //data.financialInstrumentsYear = JSON.stringify(op_values_finance_array);
    data.instruments = JSON.stringify(instruments);
    data.time_experience_instrument = JSON.stringify(
      time_experience_instrument
    );
    data.number_operations_year = JSON.stringify(number_operations_year);
    data.knowledge_level = JSON.stringify(knowledge_level);
    data.liquid_assets_distribution = JSON.stringify(
      liquid_assets_distribution
    );
    data.step_four_complete = "true";

    let update = {};
    for ([key, value] of Object.entries(dirtyFields)) {
      value = getValues(key);
      if (typeof value == "object") {
        Object.assign(update, {
          [key]:
            value !== null && value.id
              ? typeof value.id !== "string"
                ? JSON.stringify(value.id)
                : value.id
              : JSON.stringify(value),
        });
      } else {
        Object.assign(update, { [key]: value });
      }
    }

    const handleResponse = () => {
      setStep4 && setStep4(data);
      localStorage.setItem("stepFour", JSON.stringify(data));
      setLoadingSendData(false);
      setActiveStep && setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    let userID = localStorage.getItem("userId");

    setOpenDialog(false);
    let fullData = {
      fields: {
        ...step1,
        ...step2,
        ...step3,
        ...data,
      },
      formType: "J",
      userId: typeof userID === "string" ? userID : 0,
      stepForm: "Beneficiarios y Conocimiento del Mercado",
    };
    localStorage.setItem(
      "stepForm",
      "Beneficiarios y Conocimiento del Mercado"
    );

    //const saveData = await updateProfile(fullData);
    if (findData.step_four_complete !== "true") {
      const saveData = await saveProfile(fullData);
      if (saveData.status === 200) {
        localStorage.setItem("userId", JSON.stringify(saveData.userId));
        getInvestmentProfile && getInvestmentProfile(saveData.userId);
        setLoadingSendData(false);
        handleResponse();
      } else {
        setLoadingSendData(false);
        setErrorMsg && setErrorMsg("Error al almacenar información");
      }
    } else {
      const updateData = await updateProfile(fullData);
      if (updateData.status === 200) {
        getInvestmentProfile &&
          getInvestmentProfile(localStorage.getItem("userId"));
        setLoadingSendData(false);
        handleResponse();
      }
    }
  };

  return (
    <form>
      <TitleForm variant="h6" sx={{ mt: 5, mb: 4 }}>
        Relativo a FATCA (Foreign Account Tax Compliance Act)
      </TitleForm>
      <Grid container spacing={4} sx={{ mb: 2 }}>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="company_have_a_TIN_or_EIN"
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                onChange={(event, newValue) => {
                  onChange(newValue);
                }}
                options={datos.pa_ta_yes_or_no}
                getOptionLabel={(option: any) => option || ""}
                isOptionEqualToValue={(option: any, value: any) =>
                  option === value
                }
                value={value}
                disabled={completeData}
                renderInput={(params) => {
                  const inputProps = params.inputProps;
                  inputProps.autoComplete = "new-password3";
                  return (
                    <TextField
                      {...params}
                      fullWidth
                      label="¿Posee la empresa un Tax Identification Number (TIN) o Employer Identification Number (EIN)?"
                      variant="standard"
                      error={!!errors.company_have_a_TIN_or_EIN}
                      helperText={
                        errors.company_have_a_TIN_or_EIN &&
                        errors.company_have_a_TIN_or_EIN.message
                      }
                    />
                  );
                }}
              />
            )}
          />
        </Grid>
        {watch_company_have_a_TIN_or_EIN === "Sí" && (
          <Grid item xs={12}>
            <Controller
              name={"tin_or_ein"}
              control={control}
              render={({ field: { value } }) => (
                <TextField
                  fullWidth
                  size="small"
                  label="	TIN o EIN"
                  type="text"
                  variant="standard"
                  value={value}
                  disabled={completeData}
                  autoComplete="tin_or_ein"
                  {...register("tin_or_ein")}
                  error={errors.tin_or_ein && Boolean(errors.tin_or_ein)}
                  helperText={errors.tin_or_ein && errors.tin_or_ein.message}
                />
              )}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <Controller
            control={control}
            name="company_have_a_GIN"
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                onChange={(event, newValue) => {
                  onChange(newValue);
                }}
                options={datos.pa_ta_yes_or_no}
                getOptionLabel={(option: any) => option || ""}
                isOptionEqualToValue={(option: any, value: any) =>
                  option === value
                }
                value={value}
                disabled={completeData}
                renderInput={(params) => {
                  const inputProps = params.inputProps;
                  inputProps.autoComplete = "new-password3";
                  return (
                    <TextField
                      {...params}
                      fullWidth
                      label="¿Posee la empresa un GIIN (Global Intermediary Identification Number)?"
                      variant="standard"
                      error={!!errors.company_have_a_GIN}
                      helperText={
                        errors.company_have_a_GIN &&
                        errors.company_have_a_GIN.message
                      }
                    />
                  );
                }}
              />
            )}
          />
        </Grid>
        {watch_company_have_a_GIN === "Sí" && (
          <Grid item xs={12}>
            <Controller
              name={"company_have_a_GIN_data"}
              control={control}
              render={({ field: { value } }) => (
                <TextField
                  fullWidth
                  size="small"
                  label="GIIN (Global Intermediary Identification Number)"
                  type="text"
                  variant="standard"
                  value={value}
                  disabled={completeData}
                  autoComplete="company_have_a_GIN_data"
                  {...register("company_have_a_GIN_data")}
                  error={
                    errors.company_have_a_GIN_data &&
                    Boolean(errors.company_have_a_GIN_data)
                  }
                  helperText={
                    errors.company_have_a_GIN_data &&
                    errors.company_have_a_GIN_data.message
                  }
                />
              )}
            />
          </Grid>
        )}
      </Grid>
      <TitleForm variant="h6" sx={{ mt: 5, mb: 4 }}>
        Declaración de beneficiarios directos / indirectos / relacionados
      </TitleForm>

      {direct_and_indirect_beneficiariesFields.map((field, index) => {
        return (
          <div key={index}>
            {index !== 0 && (
              <Divider
                sx={{
                  pt: 3,
                  mb: 3,
                  borderColor: "#efe5df",
                  width: "50%",
                  ml: "auto",
                  mr: "auto",
                }}
              />
            )}
            <Grid container spacing={4} key={field.id} sx={{ mb: 2 }}>
              <Grid item md={4} sm={6} xs={12}>
                <Controller
                  name={`direct_and_indirect_beneficiaries.${index}.full_name`}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      fullWidth
                      size="small"
                      label="Nombre(s) y apellido(s)"
                      type="text"
                      variant="standard"
                      disabled={completeData}
                      {...register(
                        `direct_and_indirect_beneficiaries.${index}.full_name` as const
                      )}
                      error={
                        errors?.direct_and_indirect_beneficiaries?.[index]
                          ?.full_name && true
                      }
                      helperText={
                        errors?.direct_and_indirect_beneficiaries?.[index]
                          ?.full_name?.message
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item md={4} sm={6} xs={12}>
                <Controller
                  control={control}
                  name={`direct_and_indirect_beneficiaries.${index}.document_type`}
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      fullWidth
                      onChange={(event, newValue) => {
                        onChange(newValue);
                      }}
                      options={datos.pa_ta_id_type}
                      getOptionLabel={(option: any) => option.description || ""}
                      isOptionEqualToValue={(option: any, value: any) =>
                        option.id === value.id
                      }
                      value={value}
                      disabled={completeData}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          label="Tipo de documento"
                          variant="standard"
                          error={
                            errors?.direct_and_indirect_beneficiaries?.[index]
                              ?.document_type && true
                          }
                          helperText={
                            errors?.direct_and_indirect_beneficiaries?.[index]
                              ?.document_type?.message
                          }
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item md={4} sm={6} xs={12}>
                <Controller
                  name={`direct_and_indirect_beneficiaries.${index}.identification_number`}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      fullWidth
                      size="small"
                      label="Nro. de documento"
                      type="text"
                      variant="standard"
                      disabled={completeData}
                      {...register(
                        `direct_and_indirect_beneficiaries.${index}.identification_number` as const
                      )}
                      error={
                        errors?.direct_and_indirect_beneficiaries?.[index]
                          ?.identification_number && true
                      }
                      helperText={
                        errors?.direct_and_indirect_beneficiaries?.[index]
                          ?.identification_number?.message
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item md={4} sm={6} xs={12}>
                <Controller
                  name={`direct_and_indirect_beneficiaries.${index}.occupation`}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      fullWidth
                      size="small"
                      label="Ocupación"
                      type="text"
                      variant="standard"
                      disabled={completeData}
                      {...register(
                        `direct_and_indirect_beneficiaries.${index}.occupation` as const
                      )}
                      error={
                        errors?.direct_and_indirect_beneficiaries?.[index]
                          ?.occupation && true
                      }
                      helperText={
                        errors?.direct_and_indirect_beneficiaries?.[index]
                          ?.occupation?.message
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item md={4} sm={6} xs={12}>
                <Controller
                  name={`direct_and_indirect_beneficiaries.${index}.phone`}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      fullWidth
                      size="small"
                      label="Teléfono"
                      type="text"
                      variant="standard"
                      disabled={completeData}
                      {...register(
                        `direct_and_indirect_beneficiaries.${index}.phone` as const
                      )}
                      error={
                        errors?.direct_and_indirect_beneficiaries?.[index]
                          ?.phone && true
                      }
                      helperText={
                        errors?.direct_and_indirect_beneficiaries?.[index]
                          ?.phone?.message
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item md={4} sm={6} xs={12} sx={{ display: "flex" }}>
                <Controller
                  name={`direct_and_indirect_beneficiaries.${index}.address`}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      fullWidth
                      size="small"
                      label="Dirección"
                      type="text"
                      variant="standard"
                      disabled={completeData}
                      {...register(
                        `direct_and_indirect_beneficiaries.${index}.address` as const
                      )}
                      error={
                        errors?.direct_and_indirect_beneficiaries?.[index]
                          ?.address && true
                      }
                      helperText={
                        errors?.direct_and_indirect_beneficiaries?.[index]
                          ?.address?.message
                      }
                    />
                  )}
                />
                {index !== 0 && (
                  <IconButton
                    aria-label="delete"
                    color="primary"
                    onClick={() => {
                      direct_and_indirect_beneficiariesRemove(index);
                    }}
                  >
                    <RemoveCircleIcon fontSize="medium" />
                  </IconButton>
                )}
              </Grid>
            </Grid>
          </div>
        );
      })}
      {direct_and_indirect_beneficiariesFields.length < 5 && !completeData && (
        <Grid
          container
          justifyContent="flex-end"
          alignItems="center"
          sx={{ mb: 2 }}
        >
          <Button
            type="button"
            variant="outlined"
            onClick={() => {
              direct_and_indirect_beneficiariesAppend({
                full_name: "",
                document_type: [],
                identification_number: "",
                occupation: "",
                phone: "",
                address: "",
              });
            }}
          >
            <AddCircleIcon fontSize="small" sx={{ mr: 1 }} /> Agregar
          </Button>
        </Grid>
      )}
      <TitleForm variant="h6" sx={{ mt: 5, mb: 4 }}>
        Tarjeta de Firmas (Firmantes autorizados)
      </TitleForm>
      {authorized_signaturesFields.map((field, index) => {
        return (
          <div key={index}>
            {index !== 0 && (
              <Divider
                sx={{
                  pt: 3,
                  mb: 3,
                  borderColor: "#efe5df",
                  width: "50%",
                  ml: "auto",
                  mr: "auto",
                }}
              />
            )}
            <Grid container spacing={4} key={field.id} sx={{ mb: 2 }}>
              <Grid item sm={6} xs={12}>
                <Controller
                  name={`authorized_signatures.${index}.full_name`}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      fullWidth
                      size="small"
                      label="Nombre completo"
                      type="text"
                      variant="standard"
                      disabled={completeData}
                      {...register(
                        `authorized_signatures.${index}.full_name` as const
                      )}
                      error={
                        errors?.authorized_signatures?.[index]?.full_name &&
                        true
                      }
                      helperText={
                        errors?.authorized_signatures?.[index]?.full_name
                          ?.message
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item sm={6} xs={12} sx={{ display: "flex" }}>
                <Controller
                  name={`authorized_signatures.${index}.document_number`}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      fullWidth
                      size="small"
                      label="Documento de identidad"
                      type="text"
                      variant="standard"
                      disabled={completeData}
                      {...register(
                        `authorized_signatures.${index}.document_number` as const
                      )}
                      error={
                        errors?.authorized_signatures?.[index]
                          ?.document_number && true
                      }
                      helperText={
                        errors?.authorized_signatures?.[index]?.document_number
                          ?.message
                      }
                    />
                  )}
                />
                {index !== 0 && (
                  <IconButton
                    aria-label="delete"
                    color="primary"
                    onClick={() => {
                      authorized_signaturesRemove(index);
                    }}
                  >
                    <RemoveCircleIcon fontSize="medium" />
                  </IconButton>
                )}
              </Grid>
            </Grid>
          </div>
        );
      })}
      {authorized_signaturesFields.length < 4 && !completeData && (
        <Grid
          container
          justifyContent="flex-end"
          alignItems="center"
          sx={{ mb: 2 }}
        >
          <Button
            type="button"
            variant="outlined"
            onClick={() => {
              authorized_signaturesAppend({
                full_name: "",
                document_number: "",
              });
            }}
          >
            <AddCircleIcon fontSize="small" sx={{ mr: 1 }} /> Agregar
          </Button>
        </Grid>
      )}
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="mode_of_signatures_and_reception_of_instructions"
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                onChange={(event, newValue: any) => {
                  onChange(newValue);
                }}
                options={datos.pa_ta_signature_card}
                getOptionLabel={(option: any) => option || ""}
                isOptionEqualToValue={(option: any, value: any) =>
                  option === value
                }
                //@ts-ignore
                value={value}
                disabled={completeData}
                renderInput={(params) => {
                  const inputProps = params.inputProps;
                  inputProps.autoComplete =
                    "mode_of_signatures_and_reception_of_instructions";
                  return (
                    <TextField
                      {...params}
                      fullWidth
                      label="Modalidad de Firmas y Recepción de Instrucciones"
                      variant="standard"
                      error={
                        !!errors.mode_of_signatures_and_reception_of_instructions
                      }
                      helperText={
                        errors.mode_of_signatures_and_reception_of_instructions &&
                        errors.mode_of_signatures_and_reception_of_instructions
                          .message
                      }
                    />
                  );
                }}
              />
            )}
          />
        </Grid>
      </Grid>
      <TitleForm variant="h6" sx={{ mt: 5, mb: 4 }}>
        Distribución de activos líquidos
      </TitleForm>
      {liquid_assets_distributionField.map((field, index) => {
        return (
          <div key={index}>
            {index !== 0 && (
              <Divider
                sx={{
                  pt: 3,
                  mb: 3,
                  borderColor: "#efe5df",
                  width: "50%",
                  ml: "auto",
                  mr: "auto",
                }}
              />
            )}
            <Grid container spacing={4} key={field.id} sx={{ mb: 2 }}>
              <Grid item sm={6} xs={12}>
                <Controller
                  control={control}
                  name={`liquid_assets_distribution_form.${index}.liquid_assets_distribution_id`}
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      onChange={(event, newValue) => {
                        onChange(newValue);
                      }}
                      options={dataJ.pa_ta_liquid_assets_distribution}
                      getOptionLabel={(option: any) => option.description || ""}
                      isOptionEqualToValue={(option: any, value: any) =>
                        option.id === value.id
                      }
                      value={value}
                      disabled={completeData}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          label="Seleccione Activo Líquido"
                          variant="standard"
                          error={
                            errors?.liquid_assets_distribution_form?.[index]
                              ?.liquid_assets_distribution_id && true
                          }
                          helperText={
                            errors?.liquid_assets_distribution_form?.[index]
                              ?.liquid_assets_distribution_id?.message
                          }
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid
                item
                sm={6}
                xs={12}
                sx={{ display: "flex", alignItems: "flex-end" }}
              >
                <Controller
                  name={`liquid_assets_distribution_form.${index}.liquid_assets_percentage`}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      fullWidth
                      size="small"
                      label="Porcentaje"
                      type="number"
                      variant="standard"
                      disabled={completeData}
                      {...register(
                        `liquid_assets_distribution_form.${index}.liquid_assets_percentage` as const
                      )}
                      error={
                        errors?.liquid_assets_distribution_form?.[index]
                          ?.liquid_assets_percentage && true
                      }
                      helperText={
                        errors?.liquid_assets_distribution_form?.[index]
                          ?.liquid_assets_percentage?.message
                      }
                      inputProps={{ inputMode: "numeric", min: 0, max: 100 }}
                    />
                  )}
                />
                <Typography variant="body1" color="initial">
                  %
                </Typography>
                {index !== 0 && !completeData && (
                  <IconButton
                    aria-label="delete"
                    color="primary"
                    onClick={() => {
                      liquid_assets_distributionRemove(index);
                    }}
                  >
                    <RemoveCircleIcon fontSize="medium" />
                  </IconButton>
                )}
              </Grid>
            </Grid>
          </div>
        );
      })}
      {liquid_assets_distributionField.length < 5 && !completeData && (
        <Grid
          container
          justifyContent="flex-end"
          alignItems="center"
          sx={{ mb: 2 }}
        >
          <Button
            type="button"
            variant="outlined"
            onClick={() => {
              liquid_assets_distributionAppend({
                liquid_assets_distribution_id: [],
                liquid_assets_percentage: 0,
              });
            }}
          >
            <AddCircleIcon fontSize="small" sx={{ mr: 1 }} /> Agregar
          </Button>
        </Grid>
      )}
      {errorBelowOneHundred !== "" && (
        <Grid container spacing={4} sx={{ mb: 2 }}>
          <Grid item xs={12}>
            <FormHelperText error>{errorBelowOneHundred}</FormHelperText>
          </Grid>
        </Grid>
      )}

      <TitleForm variant="h6" sx={{ mt: 5, mb: 4 }}>
        Conocimiento General del Mercado
      </TitleForm>
      <Grid container spacing={4} sx={{ mb: 2 }}>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="information_media"
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                onChange={(event, newValue) => {
                  onChange(newValue);
                }}
                options={dataJ.pa_ta_information_media}
                getOptionLabel={(option: any) => option.description || ""}
                isOptionEqualToValue={(option: any, value: any) =>
                  option.id === value.id
                }
                value={value}
                disabled={completeData}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    label="Medios de Información utilizados sobre los mercados Financieros"
                    variant="standard"
                    error={!!errors.information_media}
                    helperText={
                      errors.information_media &&
                      errors.information_media.message
                    }
                  />
                )}
              />
            )}
          />
        </Grid>
      </Grid>
      <TitleForm variant="h6" sx={{ mt: 7, mb: 4 }}>
        Instrumentos financieros negociados previamente
      </TitleForm>
      {financialInstrumentsYearField.map((field, index) => {
        return (
          <div key={index}>
            {index !== 0 && (
              <Divider
                sx={{
                  pt: 3,
                  mb: 3,
                  borderColor: "#efe5df",
                  width: "50%",
                  ml: "auto",
                  mr: "auto",
                }}
              />
            )}
            <Grid container spacing={4} key={field.id} sx={{ mb: 2 }}>
              <Grid item sm={6} xs={12}>
                <Controller
                  control={control}
                  name={`financialInstrumentsYear.${index}.financialInstruments`}
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      onChange={(event, newValue) => {
                        onChange(newValue);
                      }}
                      options={dataJ.pa_ta_instrument}
                      getOptionLabel={(option: any) => option.description || ""}
                      isOptionEqualToValue={(option: any, value: any) =>
                        option.id === value.id
                      }
                      value={value}
                      disabled={completeData}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          label="Instrumentos"
                          variant="standard"
                          error={
                            errors?.financialInstrumentsYear?.[index]
                              ?.financialInstruments && true
                          }
                          helperText={
                            errors?.financialInstrumentsYear?.[index]
                              ?.financialInstruments?.message
                          }
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <Controller
                  control={control}
                  name={`financialInstrumentsYear.${index}.experienceTime`}
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      onChange={(event, newValue) => {
                        onChange(newValue);
                      }}
                      options={dataJ.pa_ta_time_experience_instrument}
                      getOptionLabel={(option: any) => option.description || ""}
                      isOptionEqualToValue={(option: any, value: any) =>
                        option.id === value.id
                      }
                      value={value}
                      disabled={completeData}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          label="Tiempo de experiencia"
                          variant="standard"
                          error={
                            errors?.financialInstrumentsYear?.[index]
                              ?.experienceTime && true
                          }
                          helperText={
                            errors?.financialInstrumentsYear?.[index]
                              ?.experienceTime?.message
                          }
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <Controller
                  control={control}
                  name={`financialInstrumentsYear.${index}.numTransactionsYear`}
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      onChange={(event, newValue) => {
                        onChange(newValue);
                      }}
                      options={dataJ.pa_ta_number_operations_year}
                      getOptionLabel={(option: any) => option.description || ""}
                      isOptionEqualToValue={(option: any, value: any) =>
                        option.id === value.id
                      }
                      value={value}
                      disabled={completeData}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          label="Cantidad de Operaciones al año"
                          variant="standard"
                          error={
                            errors?.financialInstrumentsYear?.[index]
                              ?.numTransactionsYear && true
                          }
                          helperText={
                            errors?.financialInstrumentsYear?.[index]
                              ?.numTransactionsYear?.message
                          }
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item sm={6} xs={12} sx={{ display: "flex" }}>
                <Controller
                  control={control}
                  name={`financialInstrumentsYear.${index}.levelknowInstRisk`}
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      fullWidth
                      onChange={(event, newValue) => {
                        onChange(newValue);
                      }}
                      options={dataJ.pa_ta_knowledge_level}
                      getOptionLabel={(option: any) => option.description || ""}
                      isOptionEqualToValue={(option: any, value: any) =>
                        option.id === value.id
                      }
                      value={value}
                      disabled={completeData}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          label="Nivel de conocimiento instrumento y riesgos asociados"
                          variant="standard"
                          error={
                            errors?.financialInstrumentsYear?.[index]
                              ?.levelknowInstRisk && true
                          }
                          helperText={
                            errors?.financialInstrumentsYear?.[index]
                              ?.levelknowInstRisk?.message
                          }
                        />
                      )}
                    />
                  )}
                />
                {index !== 0 && !dataForm && (
                  <IconButton
                    aria-label="delete"
                    color="primary"
                    onClick={() => {
                      financialInstrumentsYearRemove(index);
                    }}
                  >
                    <RemoveCircleIcon fontSize="medium" />
                  </IconButton>
                )}
              </Grid>
            </Grid>
          </div>
        );
      })}
      {financialInstrumentsYearField.length < 3 && !completeData && (
        <Grid
          container
          justifyContent="flex-end"
          alignItems="center"
          sx={{ mb: 2 }}
        >
          <Button
            type="button"
            variant="outlined"
            onClick={() => {
              financialInstrumentsYearAppend({
                financialInstruments: [],
                experienceTime: [],
                numTransactionsYear: [],
                levelknowInstRisk: [],
              });
            }}
          >
            <AddCircleIcon fontSize="small" sx={{ mr: 1 }} /> Agregar
          </Button>
        </Grid>
      )}
      <Typography variant="body2">
        * Mínimo 1 y máximo 3 instrumentos financieros{" "}
      </Typography>
      <TitleForm sx={{ mt: 5, mb: 4 }}>
        Clasificación del inversionista
      </TitleForm>
      <TitleForm variant="h6" sx={{ mt: 5, mb: 4, textAlign: "left" }}>
        A. Experiencia
      </TitleForm>
      <Grid container spacing={4} sx={{ mb: 2 }}>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="operations_in_national_or_foreign_stock_market"
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                onChange={(event, newValue: any) => {
                  onChange(newValue);
                }}
                options={datos.pa_ta_yes_or_no}
                getOptionLabel={(option: any) => option || ""}
                isOptionEqualToValue={(option: any, value: any) =>
                  option === value
                }
                //@ts-ignore
                value={value}
                disabled={completeData}
                renderInput={(params) => {
                  const inputProps = params.inputProps;
                  inputProps.autoComplete =
                    "operations_in_national_or_foreign_stock_market";
                  return (
                    <TextField
                      {...params}
                      fullWidth
                      label="¿Ha realizado operaciones en el mercado valores nacional o extranjero?"
                      variant="standard"
                      error={
                        !!errors.operations_in_national_or_foreign_stock_market
                      }
                      helperText={
                        errors.operations_in_national_or_foreign_stock_market &&
                        errors.operations_in_national_or_foreign_stock_market
                          .message
                      }
                    />
                  );
                }}
              />
            )}
          />
        </Grid>
        {watch_operations_in_national_or_foreign_stock_market === "Sí" && (
          <Grid item xs={12}>
            <Controller
              control={control}
              name="traded_volume_greater_than_3000"
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <Autocomplete
                  onChange={(event, newValue: any) => {
                    onChange(newValue);
                  }}
                  options={datos.pa_ta_yes_or_no}
                  getOptionLabel={(option: any) => option || ""}
                  isOptionEqualToValue={(option: any, value: any) =>
                    option === value
                  }
                  //@ts-ignore
                  value={value}
                  disabled={completeData}
                  renderInput={(params) => {
                    const inputProps = params.inputProps;
                    inputProps.autoComplete = "traded_volume_greater_than_3000";
                    return (
                      <TextField
                        {...params}
                        fullWidth
                        label="De ser afirmativa la respuesta anterior, ¿el volumen transado en dichas operaciones ha sido igual o superior a DOP 3,000,000.00 o su equivalente en cualquier otra moneda por año?"
                        variant="standard"
                        error={!!errors.traded_volume_greater_than_3000}
                        helperText={
                          errors.traded_volume_greater_than_3000 &&
                          errors.traded_volume_greater_than_3000.message
                        }
                      />
                    );
                  }}
                />
              )}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <Controller
            control={control}
            name="average_transaction_frequency"
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                onChange={(event, newValue: any) => {
                  onChange(newValue);
                }}
                options={datos.pa_ta_yes_or_no}
                getOptionLabel={(option: any) => option || ""}
                isOptionEqualToValue={(option: any, value: any) =>
                  option === value
                }
                //@ts-ignore
                value={value}
                disabled={completeData}
                renderInput={(params) => {
                  const inputProps = params.inputProps;
                  inputProps.autoComplete = "average_transaction_frequency";
                  return (
                    <TextField
                      {...params}
                      fullWidth
                      label="¿Las transacciones realizadas han tenido una frecuencia media de tres (3) operaciones por trimestre en el año?"
                      variant="standard"
                      error={!!errors.average_transaction_frequency}
                      helperText={
                        errors.average_transaction_frequency &&
                        errors.average_transaction_frequency.message
                      }
                    />
                  );
                }}
              />
            )}
          />
        </Grid>
      </Grid>
      <TitleForm variant="h6" sx={{ mt: 5, mb: 4, textAlign: "left" }}>
        B. Capacidad patrimonial
      </TitleForm>
      <Grid container spacing={4} sx={{ mb: 2 }}>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="has_cash_or_investment_assets"
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                onChange={(event, newValue: any) => {
                  onChange(newValue);
                }}
                options={datos.pa_ta_yes_or_no}
                getOptionLabel={(option: any) => option || ""}
                isOptionEqualToValue={(option: any, value: any) =>
                  option === value
                }
                //@ts-ignore
                value={value}
                disabled={completeData}
                renderInput={(params) => {
                  const inputProps = params.inputProps;
                  inputProps.autoComplete = "has_cash_or_investment_assets";
                  return (
                    <TextField
                      {...params}
                      fullWidth
                      label="¿Posee activos en efectivo o inversiones en instrumentos financieros igual o mayor a DOP 25,000,000.00 o su equivalente a cualquier otra moneda, depositados en una entidad de intermediación financiera o inversiones
                      locales o extranjeras?            "
                      variant="standard"
                      error={!!errors.has_cash_or_investment_assets}
                      helperText={
                        errors.has_cash_or_investment_assets &&
                        errors.has_cash_or_investment_assets.message
                      }
                    />
                  );
                }}
              />
            )}
          />
        </Grid>
      </Grid>
      <TitleForm variant="h6" sx={{ mt: 5, mb: 4, textAlign: "left" }}>
        C. Conocimiento
      </TitleForm>
      <Grid container spacing={4} sx={{ mb: 2 }}>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="positions_in_management_of_financial_institution"
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                onChange={(event, newValue: any) => {
                  onChange(newValue);
                }}
                options={datos.pa_ta_yes_or_no}
                getOptionLabel={(option: any) => option || ""}
                isOptionEqualToValue={(option: any, value: any) =>
                  option === value
                }
                //@ts-ignore
                value={value}
                disabled={completeData}
                renderInput={(params) => {
                  const inputProps = params.inputProps;
                  inputProps.autoComplete =
                    "positions_in_management_of_financial_institution";
                  return (
                    <TextField
                      {...params}
                      fullWidth
                      label="¿Ha laborado en cargos de dirección en el área de negocios o área afines de una entidad que opere en el sistema financiero por un período de al menos dos (2) años?"
                      variant="standard"
                      error={
                        !!errors.positions_in_management_of_financial_institution
                      }
                      helperText={
                        errors.positions_in_management_of_financial_institution &&
                        errors.positions_in_management_of_financial_institution
                          .message
                      }
                    />
                  );
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="member_of_the_management_board"
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                onChange={(event, newValue: any) => {
                  onChange(newValue);
                }}
                options={datos.pa_ta_yes_or_no}
                getOptionLabel={(option: any) => option || ""}
                isOptionEqualToValue={(option: any, value: any) =>
                  option === value
                }
                //@ts-ignore
                value={value}
                disabled={completeData}
                renderInput={(params) => {
                  const inputProps = params.inputProps;
                  inputProps.autoComplete = "member_of_the_management_board";
                  return (
                    <TextField
                      {...params}
                      fullWidth
                      label="¿Ha sido miembro del consejo de administración de una entidad que opere en el sistema financiero por un
                      período de al menos dos (2) años?"
                      variant="standard"
                      error={!!errors.member_of_the_management_board}
                      helperText={
                        errors.member_of_the_management_board &&
                        errors.member_of_the_management_board.message
                      }
                    />
                  );
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="stock_broker_certification"
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                onChange={(event, newValue: any) => {
                  onChange(newValue);
                }}
                options={datos.pa_ta_yes_or_no}
                getOptionLabel={(option: any) => option || ""}
                isOptionEqualToValue={(option: any, value: any) =>
                  option === value
                }
                //@ts-ignore
                value={value}
                disabled={completeData}
                renderInput={(params) => {
                  const inputProps = params.inputProps;
                  inputProps.autoComplete = "stock_broker_certification";
                  return (
                    <TextField
                      {...params}
                      fullWidth
                      label="¿Tiene una certificación de corredor de valores o su equivalente de otro país?"
                      variant="standard"
                      error={!!errors.stock_broker_certification}
                      helperText={
                        errors.stock_broker_certification &&
                        errors.stock_broker_certification.message
                      }
                    />
                  );
                }}
              />
            )}
          />
        </Grid>
      </Grid>
      <Stack
        justifyContent="center"
        alignItems="center"
        direction="row"
        spacing={2}
      >
        {loadingSendData && (
          <CircularProgress
            size={24}
            sx={{ margin: "5px auto", color: `${theme.palette.primary.main}` }}
          />
        )}
      </Stack>
      <Stack
        justifyContent="flex-end"
        alignItems="flex-end"
        direction="row"
        spacing={2}
        sx={{ mt: 2 }}
      >
        {completeData ? (
          <>
            <Button
              type="button"
              variant="outlined"
              onClick={handleBack}
              sx={{ width: { xs: "100%", sm: "auto" } }}
            >
              Regresar
            </Button>
            <ButtonGradient
              type="button"
              onClick={handleNext}
              sx={{ width: { xs: "100%", sm: "auto" } }}
            >
              Continuar
            </ButtonGradient>
          </>
        ) : (
          <>
            <Tooltip
              disableFocusListener
              disableTouchListener
              title={
                step4Answered === false
                  ? "Perderas la información no guardada"
                  : ""
              }
              placement="top"
            >
              <Button
                type="button"
                variant="outlined"
                onClick={handleBack}
                sx={{ width: { xs: "100%", sm: "auto" } }}
              >
                Regresar
              </Button>
            </Tooltip>
            <ButtonGradient
              type="button"
              //onClick={() => setOpenDialog(true)}
              onClick={validateForm}
              sx={{ width: { xs: "100%", sm: "auto" } }}
            >
              Continuar
            </ButtonGradient>
          </>
        )}
      </Stack>
      <Dialog open={openDialog} onClose={handleClose}>
        <FancyDialog
          textTitle={`¿Estas seguro de guardar todos los datos?`}
          actionButton={
            <>
              <Button
                type="button"
                variant="outlined"
                onClick={handleClose}
                sx={{ mt: 2, mr: 2 }}
                autoFocus
              >
                Cancelar
              </Button>
              <ButtonGradient type="submit" onClick={handleSubmit(onSubmit)}>
                Aceptar
              </ButtonGradient>{" "}
            </>
          }
        />
      </Dialog>
    </form>
  );
};

export default BeneficiariesAndMarketKnowledge;
