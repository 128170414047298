import axios from "axios";
import { URL } from "../utils/UrlConstant";
import { privateFetch } from "../utils/CustomFetch";
import { CONSTANT } from "../utils/Constants";

const headers = {
  Accept: "application/json",
  "Content-Type": "application/json",
};

enum Method {
  POST = "POST",
  GET = "GET",
  DELETE = "DELETE",
  PUT = "PUT",
  PATCH = "PATCH",
}

/* export const getProfileOld = async (userId: any) => {
  try {
    const responseProfile = await axios.get(
      `${URL.investmentProfile.CRUD}${userId}`
    );
    return {
      status: responseProfile.status,
      message: responseProfile.data.message,
      body: responseProfile.data,
    };
  } catch (e: any) {
    return {
      messageError: e.response.data.message,
    };
  }
}; */

export const getProfile = async (userId: any) => {
  let responseData: any = {};
  await privateFetch
    .get(`${URL.investmentProfile.CRUD}${userId}`)
    .then(async (response) => {
      responseData = await response;
    })
    .catch((error) => {
      throw new Error(error.response.data.message);
      /*  return {
        messageError: error.response.data.message,
      }; */
    });
  return {
    status: responseData.status,
    message: responseData.data.message,
    body: responseData.data,
  };
};

/* export const getCalculateProfileOOld = async (userId: any) => {
  try {
    const responseProfile = await axios.get(
      `${URL.investmentProfile.calculate}${userId}`
    );
    return {
      status: responseProfile.status,
      message: responseProfile.data.message,
      body: responseProfile.data,
    };
  } catch (e: any) {
    return {
      messageError: e.response.data.message,
    };
  }
}; */

export const getCalculateProfile = async (userId: any) => {
  let responseData: any = {};
  await privateFetch
    .get(URL.investmentProfile.calculate + userId)
    .then(async (response) => {
      responseData = await response;
    })
    .catch((error) => {
      throw new Error(error.response.data.message);
    });
  return {
    status: responseData.status,
    message: responseData.data.message,
    body: responseData.data,
  };
};

/* export const saveProfileOld = async (userData: any) => {
  try {
    const response = await axios.post(URL.investmentProfile.CRUD, userData, {
      headers,
    });

    return {
      status: response.status,
      message: response.data.message,
      body: response.data,
      userId: response.data.userId,
    };
  } catch (e: any) {
    return {
      messageError: e.response.data.message,
    };
  }
}; */

export const saveProfile = async (userData: any) => {
  let responseData: any = {};
  await privateFetch
    .post(URL.investmentProfile.CRUD, userData, {
      headers: {
        ...CONSTANT.HEADER_TYPE,
      },
    })
    .then(async (response) => {
      responseData = await response;
    })
    .catch(async (error) => {
      throw new Error(error.response.data.message);
    });
  return {
    status: responseData.status,
    message: responseData.data.message,
    body: responseData.data,
    userId: responseData.data.userId,
  };
};

/* export const updateProfileOld = async (userData: any) => {
  try {
    const response = await axios.post(URL.investmentProfile.update, userData, {
      headers,
    });

    return {
      status: response.status,
      message: response.data.message,
      body: response.data,
    };
  } catch (e: any) {
    return {
      messageError: e.response.data.message,
    };
  }
}; */

export const updateProfile = async (userData: any) => {
  let responseData: any = {};
  await privateFetch
    .post(URL.investmentProfile.update, userData, {
      headers: {
        ...CONSTANT.HEADER_TYPE,
      },
    })
    .then(async (response) => {
      responseData = await response;
    })
    .catch(async (error) => {
      throw new Error(error.response.data.message);
    });
  return {
    status: responseData.status,
    message: responseData.data.message,
    body: responseData.data,
  };
};

/* export const clientNotificationOld = async (data: any) => {
  const params = new URLSearchParams([["userId", data]]);
  try {
    const response = await axios.post(
      `${URL.investmentProfile.notification}form-completed`,
      {
        headers,
      },
      { params }
    );

    return {
      status: response.status,
      message: response.data.message,
      body: response.data,
    };
  } catch (e: any) {
    return {
      messageError: e.response.data.message,
    };
  }
}; */

export const clientNotification = async (data: any) => {
  let responseData: any = {};
  const params = new URLSearchParams([["userId", data]]);

  await privateFetch
    .post(
      `${URL.investmentProfile.notification}form-completed`,
      {
        headers: {
          ...CONSTANT.HEADER_TYPE,
        },
      },
      { params }
    )
    .then(async (response) => {
      responseData = await response;
    })
    .catch(async (error) => {
      throw new Error(error.response.data.message);
    });
  return {
    status: responseData.status,
    message: responseData.data.message,
    body: responseData.data,
  };
};

/* export const admintNotificationOld = async (data: any) => {
  const params = new URLSearchParams([["userId", data]]);
  try {
    const response = await axios.post(
      `${URL.investmentProfile.notification}form-completed-parval`,
      {
        headers,
      },
      { params }
    );

    return {
      status: response.status,
      message: response.data.message,
      body: response.data,
    };
  } catch (e: any) {
    return {
      messageError: e.response.data.message,
    };
  }
}; */

export const admintNotification = async (data: any) => {
  let responseData: any = {};
  const params = new URLSearchParams([["userId", data]]);

  await privateFetch
    .post(
      `${URL.investmentProfile.notification}form-completed-parval`,
      {
        headers: {
          ...CONSTANT.HEADER_TYPE,
        },
      },
      { params }
    )
    .then(async (response) => {
      responseData = await response;
    })
    .catch(async (error) => {
      throw new Error(error.response.data.message);
    });
  return {
    status: responseData.status,
    message: responseData.data.message,
    body: responseData.data,
  };
};

/* export const getAllData = async (type: string) => {
  try {
    const getApi = await fetch(`${URL.maintenance.allData}?type=${type}`, {
      method: Method.GET,
      headers: headers,
    });
    const response = await getApi.json();
    return {
      status: getApi.status,
      statusText: getApi.statusText,
      body: response,
    };
  } catch (error) {
    console.log(error);
    return { body: error };
  }
};
 */
export const getAllData = async (type: string) => {
  let responseData: any = {};
  await privateFetch
    .get(`${URL.maintenance.allData}?type=${type}`)
    .then(async (response) => {
      responseData = await response;
    })
    .catch((error) => {
      throw new Error(error);
    });
  return {
    status: responseData.status,
    statusText: responseData.statusText,
    body: responseData.data,
  };
};

export const getAddressData = async () => {
  let responseDataP: any = {};
  let responseDataS: any = {};
  await privateFetch
    .get(URL.maintenance.sector)
    .then(async (response) => {
      responseDataP = await response;
    })
    .catch((error) => {
      throw new Error(error);
    });

  await privateFetch
    .get(URL.maintenance.provinces)
    .then(async (response) => {
      responseDataS = await response;
    })
    .catch((error) => {
      throw new Error(error);
    });
  return {
    statusP: responseDataP.status,
    statusTextP: responseDataP.statusText,
    provinces: responseDataP.data,

    statusS: responseDataS.status,
    statusTextS: responseDataS.statusText,
    sector: responseDataS.data,
  };
};

export const getBranch = async () => {
  let responseData: any = {};
  await privateFetch
    .get(URL.maintenance.branch)
    .then(async (response) => {
      responseData = await response;
    })
    .catch((error) => {
      throw new Error(error);
    });
  return {
    status: responseData.status,
    statusText: responseData.statusText,
    body: responseData.data,
  };
};

export const getInvestmentPromoter = async () => {
  let responseData: any = {};
  await privateFetch
    .get(URL.maintenance.investmentPromoter)
    .then(async (response) => {
      responseData = await response;
    })
    .catch((error) => {
      throw new Error(error);
    });
  return {
    status: responseData.status,
    statusText: responseData.statusText,
    body: responseData.data,
  };
};

export const getBankEntity = async () => {
  let responseData: any = {};
  await privateFetch
    .get(URL.maintenance.bankEntity)
    .then(async (response) => {
      responseData = await response;
    })
    .catch((error) => {
      throw new Error(error);
    });
  return {
    status: responseData.status,
    statusText: responseData.statusText,
    body: responseData.data,
  };
};
