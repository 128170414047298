import { useState, createContext, useMemo } from "react";
import { requestTypes } from "../types/types";

const initialState = {
  isLoading: false,
  errorMsg: "",
  successMsg: "",
};

const AppContext = createContext<requestTypes>(initialState);
const AppProvider = ({ children }: any) => {
  const [isLoading, setLoading] = useState(initialState.isLoading);
  const [errorMsg, setErrorMsg] = useState(initialState.errorMsg);
  const [successMsg, setSuccessMsg] = useState(initialState.successMsg);
  const [modalData, setModalData] = useState({
    modalOpen: false,
    modalType: "",
    modalObject: null,
  });

  /**
   * resetErrorMsg
   */
  const resetErrorMsg = () => {
    setErrorMsg("");
  };

  /**
   * resetSuccessMsg
   */
  const resetSuccessMsg = () => {
    setSuccessMsg("");
  };

  const formContextValues: requestTypes = useMemo(
    () => ({
      isLoading,
      setLoading,
      errorMsg,
      setErrorMsg,
      successMsg,
      setSuccessMsg,
      resetErrorMsg,
      resetSuccessMsg,
      modalData,
      setModalData,
    }),
    // eslint-disable-next-line
    [isLoading, errorMsg, successMsg, modalData]
  );

  return (
    <AppContext.Provider value={formContextValues}>
      {children}
    </AppContext.Provider>
  );
};

export { AppProvider };

export default AppContext;
