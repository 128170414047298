import { Container, AppBar, Button, Stack } from "@mui/material";
import { Outlet } from "react-router-dom";
import SocialNetworks from "../components/footer/SocialNetworks";
import Banner from "../components/Banner";
import imageBanner from "../resources/images/customer-management.webp";
import PaperToForm from "../components/PaperToForm";
import useLegalForm from "../hooks/useLegalForm";
import useProfile from "../hooks/useProfile";

const GeneralFormLayout = () => {
  const { removeLegalData, activeStep } = useLegalForm();
  const { removeDataProfile } = useProfile();

  const handleRemoveData = async () => {
    removeDataProfile && removeDataProfile();
    removeLegalData && removeLegalData();
  };
  //console.log(activeStep);
  return (
    <main>
      <AppBar position="relative" sx={{ backgroundColor: "#326267" }}>
        <Banner
          title={`Parval`}
          subTitleWhite={`Formulario`}
          subTitleYellow={`Conozca su Cliente Jurídico`}
          image={imageBanner}
          sizeTittle={`2.2125rem`}
          sizeSubTittle={`1.7125rem;`}
        />
      </AppBar>
      <Container maxWidth="lg" sx={{ pt: { xs: 4, sm: 6 }, pb: 8 }}>
        {activeStep === 4 && ( // Only render the button if activeStep is 4
          <Stack alignItems="flex-end" sx={{ mb: 2 }}>
            <Button
              onClick={handleRemoveData}
              variant="contained"
              sx={{ borderRadius: "25rem" }}
            >
              Limpiar formulario
            </Button>
          </Stack>
        )}
        <PaperToForm sx={{ mb: 2, pb: 6 }}>
          <Outlet />
        </PaperToForm>
        <SocialNetworks />
      </Container>
    </main>
  );
};

export default GeneralFormLayout;
